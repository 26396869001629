import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { makeAbortable } from "../../abortablePromises/MakeAbortable.function";
import { noOpAbortable } from "../../abortablePromises/NoOpAbortable.const";
import "../services/searchService";
import "../../multiLanguageService/multiLanguageService";
import "../../loadingSpinner/loadingSpinner";
import template from "../templates/extend-search.html";

class ExtendSearchController extends NgComponent {
    constructor($scope, $attrs, searchService, multiLanguageService) {
        super();
        this._$scope = $scope;
        this._searchService = searchService;
        this.multiLanguageService = multiLanguageService;
        this._activeSearchRequest = noOpAbortable;
        this._resetSearch();
        $attrs.$observe("emailOnly", (emailOnly) => (this.emailOnlySearch = emailOnly));
    }

    $onChanges() {
        this._activeSearchRequest.abort();
        this._resetSearch();
        this.extendSearchData = { ...this.initialData };
    }

    $onDestroy() {
        this._activeSearchRequest.abort();
    }

    searchUsers() {
        this.searchSpinner = true;
        this.searchResults = null;

        this._activeSearchRequest.abort();
        this._activeSearchRequest = makeAbortable(this._searchService.searchUsers(this.extendSearchData)).then((response) => {
            this.searchResults = response.data;
            this.searchSpinner = false;
        });
    }

    createContactFromSearchResult(user) {
        const requestObj = {
            FirstName: user.FullName.split(" ")[0],
            SecondName: user.FullName.split(" ")[1],
            Email: user.Email,
            Username: user.Username
        };

        this._searchService.createContactFromSearchResult(requestObj).then((response) => {
            response.data.UserId = user.UserId;
            response.data.OrganisationId = user.OrganisationId;
            response.data.OrganisationName = user.OrganisationName;
            response.data.RoleName = user.RoleName || "";
            response.data.SubTitle = user.Email || "";
            response.data.Email = user.Email || "";
            response.data.Username = user.Username;

            this._$scope.$emit("newContactAdded", response.data);
            this.closeForm();
        });
    }

    closeForm() {
        this._resetSearch();
        this._$scope.$emit("closeExtendSearchForm");
    }

    _resetSearch() {
        this.extendSearchData = {
            firstName: "",
            secondName: "",
            email: ""
        };

        this.searchResults = null;
        this.emailOnlySearch = false;
        this.searchSpinner = false;
    }
}

ExtendSearchController.$inject = ["$scope", "$attrs", "searchService", "multiLanguageService"];

angularAMD.component("extendSearch", {
    bindings: {
        initialData: "<?"
    },
    template: template,
    controller: ExtendSearchController
});
