import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./standards-navigational-buttons.html";

// tslint:disable-next-line:no-empty
class StandardsNavigationalButtons extends NgComponent {
    constructor() {
        super();
    }
}

export const standardsNavigationalButtons = {
    bindings: {
        tabButtons: "<",
        isDisabled: "<"
    },
    template: template,
    controller: StandardsNavigationalButtons
};

StandardsNavigationalButtons.$inject = [];
angularAMD.component("standardsNavigationalButtons", standardsNavigationalButtons);
