import "../helpers";
import "../../copyPaste/paste.service";
import "../../userAgent/userAgent.service";
import { HTML_INPUT } from "../../constants/htmlInput.constants";
import { applyIosCaretNotAppearingOnTapFix } from "../ApplyIosCaretNotAppearingOnTapFix.function";
import { noop } from "@pjs/utilities";

export const textEditableDirectiveFactory = (attributeName, attributeSetup = noop) => {
    return [
        "$sce",
        "$rootScope",
        "$window",
        "helpers",
        "PasteService",
        "UserAgentService",
        function ($sce, $rootScope, $window, helpers, PasteService, UserAgentService) {
            return {
                restrict: "A",
                require: "?ngModel",
                link: function (scope, element, attrs, ngModel) {
                    attributeSetup(attrs, element[0]);

                    const currentClass = element[0].getAttribute("class");
                    element[0].setAttribute("class", `legacy-content-editable ${currentClass}`);

                    let sanitiseConfig = null;
                    if (attrs.singleLine === "true") {
                        sanitiseConfig = { ALLOWED_TAGS: HTML_INPUT.INLINE_TAGS_WHITELIST };
                    }

                    const pasteObject = new PasteService({
                        onPasteComplete: onPaste,
                        sanitizeConfig: sanitiseConfig,
                        target: element[0]
                    });

                    const isNative = attrs.nativeContenteditable !== undefined;
                    const updateEvents = isNative ? "blur" : "blur keydown keyup texteditorchange input";
                    const saving_events = ["keyup", "paste", "texteditorchange", "input"];

                    let dataUpdateTimer = 0;
                    let old_model_value = undefined;

                    if (!UserAgentService.safari) {
                        // don't update localstorage on keydown
                        // to avoid fake highlight saving, when user enters into editor toolbar
                        saving_events.push("keydown");
                    }

                    if (!ngModel) {
                        return;
                    } // do nothing if no ng-model

                    if (UserAgentService.ios) {
                        applyIosCaretNotAppearingOnTapFix(element[0]);
                    }

                    ngModel.$render = function () {
                        const value = $sce.trustAsHtml(ngModel.$viewValue || "");
                        // tslint:disable-next-line:no-unsafe-jq-lite
                        element.html(value.toString());
                    };

                    function setA11yAttributes(isEditable) {
                        if (!isEditable) {
                            element[0].removeAttribute("aria-multiline");
                            if (element[0].attributes.role === "textbox") {
                                element[0].removeAttribute("textbox");
                            }
                            return;
                        }

                        if (element[0].attributes.role === undefined) {
                            element[0].setAttribute("role", "textbox");
                        }

                        element[0].setAttribute("aria-multiline", attrs.singleLine !== "true");
                    }

                    setA11yAttributes(attrs[attributeName] !== "false");

                    function preventLinkAction(e) {
                        if (e.target.tagName === "A") {
                            e.preventDefault();
                        }
                    }

                    function onEventHappened(e) {
                        if (attrs.singleLine && attrs.singleLine.toLowerCase() === "true" && e.keyCode === 13) {
                            e.preventDefault();
                        }

                        if (e.type === "blur") {
                            unbindAll();
                        }

                        if (isNative) {
                            return;
                        }

                        if (saving_events.indexOf(e.type) > -1) {
                            update();
                        }
                    }

                    function update() {
                        scope.$evalAsync(read);
                        clearTimeout(dataUpdateTimer);
                        dataUpdateTimer = setTimeout(function () {
                            if (old_model_value !== ngModel.$viewValue) {
                                triggerUpdateCallbacks(ngModel.$viewValue);
                            }

                            old_model_value = ngModel.$viewValue;
                        }, 100);
                    }

                    function triggerUpdateCallbacks(value) {
                        if (scope.updateModel) {
                            scope.updateModel(attrs.saveTarget ? attrs.saveTarget : attrs.ngModel, value);
                        }

                        if (attrs.onUpdate) {
                            const callback = helpers.getCallbackFromPath(scope, attrs.onUpdate);
                            const callbackKey = attrs.onUpdateKey || "";
                            if (callback) {
                                scope.$apply(function () {
                                    callback(value, callbackKey);
                                });
                            }
                        }
                    }

                    function onPaste() {
                        read();
                        triggerUpdateCallbacks(ngModel.$viewValue);
                    }

                    function bindAll() {
                        old_model_value = ngModel.$viewValue;

                        element.bind("click", preventLinkAction);
                        element.bind(updateEvents, onEventHappened);

                        pasteObject.pastePlain = true;

                        pasteObject.disable(); //Remove and previous event listeners, just in case of users not removing focus in a standard way (Console, or alt+tab);
                        pasteObject.enable();
                    }

                    function unbindAll() {
                        if (document.activeElement === element[0]) {
                            return;
                        }

                        element.unbind("click", preventLinkAction);
                        element.unbind(updateEvents, onEventHappened);
                        pasteObject.disable();
                        $window.getSelection().removeAllRanges();
                    }

                    var clearEditableAttributeObserver = attrs.$observe(attributeName, function () {
                        setA11yAttributes(attrs[attributeName] !== "false");
                        if (attrs[attributeName] !== "false") {
                            element.bind("focus", bindAll); // start listening for event only when element is in focus
                            // tslint:disable-next-line:triple-equals
                            if (scope.contenteditable_deferred != undefined) {
                                scope.contenteditable_deferred.resolve();
                            }
                            clearEditableAttributeObserver();
                        }
                    });

                    // write data to the model
                    function read() {
                        let html = element.html();
                        // when we clear the content editable the browser leaves a <br> behind
                        // if strip-br attribute is provided then we strip this out
                        if (html && (html.replace(/<br>/g, "").length === 0 || html === "<br>")) {
                            element.empty();
                            html = element.html();
                        }
                        ngModel.$setViewValue(html);
                    }
                }
            };
        }
    ];
};
