import { EffectCallback, useEffect, useRef } from "react";
import { DependencyArray } from "./types/DependencyArray";

/**
 * When ANY of the provided dependencies change, the callback is invoked after the current React render cycle fully
 * completes and a browser repaint has occurred.
 *
 * By default this hook will not fire during the initial component mount, this can be changed by setting the runOnMount flag to true
 */
export const useOnValueChange = (callback: EffectCallback, dependencies: DependencyArray, runOnMount: boolean = false): void => {
    if (runOnMount) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(callback, dependencies);
        return;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hasMounted = useRef(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (hasMounted.current === false) {
            hasMounted.current = true;
            return;
        }

        callback();
    }, dependencies);
};
