import { FunctionComponent, useId } from "react";
import { Select } from "../select/Select.component";
import { TextOption } from "../select/options/text-option/TextOption.component";
import { Search } from "./Search.component";
import { IScopedSearchProps } from "./interfaces/IScopedSearchProps";
import "../../public-utilities.css";
import "./styles/scoped-search.css";

export const ScopedSearch: FunctionComponent<IScopedSearchProps> = (props) => {
    const id = useId();

    const onTextFieldSearch = (searchString: string): void => {
        props.onSearch(searchString, props.filterOptions[props.filterModel.activeItemIndex]);
    };

    return (
        <div className={`${props.className !== undefined ? props.className : ""} cui-scoped-search`} data-hook="scoped-search">
            <span className="cui-util-hide-element" id={`cui-scoped-search-list-label-${id}`}>
                {props.scopedSearchLabel}
            </span>
            <span className="cui-util-hide-element" id={"cui-scoped-search-label-" + id}>
                {props.filterOptions[props.filterModel.activeItemIndex].fullLabel} {props.scopedSearchLabel}
            </span>
            <Select
                autoFocus={props.autoFocus}
                model={props.filterModel}
                onModelChange={props.onFilterChange}
                dataSet={props.filterOptions}
                menuTriggerLabelId={`cui-scoped-search-label-${id}`}
                labelId={`cui-scoped-search-list-label-${id}`}
                renderOption={TextOption}
                dataHook={props.dataHooks?.select ?? "cui-scoped-search-select"}
            />
            <Search
                onSearch={onTextFieldSearch}
                onChange={props.onChange}
                searchString={props.searchString}
                clearSearchLabel={props.clearSearchLabel}
                searchButtonLabel={props.searchButtonLabel}
                searchInputLabel={props.searchInputLabel}
            />
        </div>
    );
};
