import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./sidebar-elements-view-wrapper.html";
import "../sidebarElementsView.component";

class SidebarElementsViewWrapper extends NgComponent {
    constructor($scope, multiLanguageService) {
        super();

        this.multiLanguageService = multiLanguageService;
        this.$scope = $scope;
    }

    closeSidebarElementsView() {
        this.$scope.$emit("closeRightHandSidePanel");
    }
}

export const sidebarElementsViewWrapper = {
    bindings: {
        data: "<",
        assetId: "<"
    },
    template: template,
    controller: SidebarElementsViewWrapper
};

SidebarElementsViewWrapper.$inject = ["$scope", "multiLanguageService"];
angularAMD.component("sidebarElementsViewWrapper", sidebarElementsViewWrapper);
