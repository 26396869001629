import { useClassRef, useOnMount, useOnValueChange } from "@pjs/react-utilities";
import { JSX, RefObject, useId, useRef, useState } from "react";
import { Immutable, noop } from "@pjs/utilities";
import { flushSync } from "react-dom";
import { filter, from, KillSignal, takeUntil } from "@pjs/observables";
import { focusTrapper } from "../../utils/focus-trapper/FocusTrapper.const";
import { Keys } from "../../enums/Keys";
import { Button } from "../button/Button.component";
import { crossIcon } from "../icon/icons/Cross.icon";
import { animateElement } from "../../utils/animate-element/AnimateElement";
import { coreUiI18n } from "../i18n/CoreUiI18n.const";
import { IDialogStep } from "../dialog-instance/interfaces/IDialogStep";
import { IMultiStepDialogInstance } from "../dialog-instance/interfaces/IMultiStepDialogInstance";
import { dialogDirector } from "../dialog-director/DialogDirector.const";
import { DialogState } from "../dialog-director/enums/DialogState";
import { BoundaryContext } from "../boundary/Boundary.context";
import { IconButton } from "../icon-button/IconButton.component";
import { IconButtonClass } from "../icon-button/enums/IconButtonClass";
import { handleInitialDialogFocus } from "./utils/HandleInitialDialogFocus.function";
import { IDialogProps } from "./interfaces/IDialogProps";
import { isBottomPositioned } from "./utils/IsBottomPositioned";
import { animateDialogIn } from "./utils/AnimateDialogIn";
import { focusFirstElementInDialog } from "./functions/FocusFirstElementInDialog";
import { animateStepChange } from "./functions/AnimateStepChange";
import { dialogAnimationKeyFrames } from "./consts/DialogAnimationKeyFrames.const";
import "./styles/dialog.css";

const animateToNextStep = async (newStep: IDialogStep<any, any>, setStep: (step: IDialogStep<any, any>) => void, stepElement: RefObject<HTMLElement>): Promise<void> => {
    await animateStepChange(stepElement, () => {
        flushSync(() => {
            setStep(newStep);
        });
    });
};

const animateOutOnCloseState = (instance: IMultiStepDialogInstance<any>, dialogRef: RefObject<HTMLDialogElement>, shouldAnimateFromBottom: boolean, killSignal: KillSignal): void => {
    dialogDirector.applicationState
        .pipe(
            takeUntil(killSignal),
            filter((state) => state === DialogState.CLOSING)
        )
        .subscribe(async () => {
            if (dialogRef.current !== null) {
                await animateElement(dialogRef.current, {
                    duration: 150,
                    easing: "ease-out",
                    keyframes: shouldAnimateFromBottom ? dialogAnimationKeyFrames.fadeOutDown : dialogAnimationKeyFrames.fadeOutUp
                });
                instance.destroy();
            }
        });
};

/**
 * @desc Known limitations
 * - Any autofocusing on a rich content editor or text area within a dialog, when it first opens, could encounter issues with positioning.
 *   This can result in the toolbar in the case of a rich content editor or the cursor in a text area being off the screen.
 *   This issue is present on mobile only and related to the keyboard being visible.
 */
export function Dialog<T>({ instance: dialogInstance }: IDialogProps<T>): JSX.Element {
    const [step, setStep] = useState(dialogInstance.steps.items[0]);
    const [model, setModel] = useState<Immutable<T>>(dialogInstance.model);

    const dialogRef = useRef<HTMLDialogElement>(null);
    const stepRef = useRef<HTMLDivElement>(null);
    const mainContainerRef = useRef<HTMLDivElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const dialogKillSignal = useClassRef(KillSignal);

    const labelledById = useId();
    const stepContentDescribedBy = useId();
    const screenReaderElementId = useId();

    const characterUnannouncedByScreenReader = String.fromCharCode(8202);
    const dialogDescribedBy = step === dialogInstance.steps.items[0] ? `${screenReaderElementId} ${stepContentDescribedBy}` : screenReaderElementId;

    useOnMount(() => {
        let removeTrap = noop;
        if (dialogRef.current === null || mainContainerRef.current === null) {
            return;
        }

        const shouldAnimateFromBottom = isBottomPositioned(mainContainerRef.current);

        from(animateDialogIn(dialogRef.current, shouldAnimateFromBottom))
            .pipe(takeUntil(dialogKillSignal))
            .subscribe(() => {
                removeTrap = focusTrapper.trap(dialogRef.current as HTMLDialogElement);
                handleInitialDialogFocus(dialogRef, mainContainerRef, stepRef, closeButtonRef, labelledById);
            });

        dialogInstance.modelChanges.pipe(takeUntil(dialogKillSignal)).subscribe(setModel);
        dialogInstance.steps.changeStream.pipe(takeUntil(dialogKillSignal)).subscribe((newStep) => animateToNextStep(newStep, setStep, stepRef));

        animateOutOnCloseState(dialogInstance, dialogRef, shouldAnimateFromBottom, dialogKillSignal);

        return () => {
            removeTrap();
            dialogKillSignal.send();
        };
    });

    useOnValueChange(() => {
        focusFirstElementInDialog(dialogRef, mainContainerRef, stepRef, [closeButtonRef.current]);
    }, [step]);

    const handleDefaultCancel = (): void => {
        if (dialogInstance.dismissAction !== null) {
            dialogInstance.dismissAction(model, dialogInstance);
        }
    };

    return (
        <dialog
            role="dialog"
            ref={dialogRef}
            className="cui-dialog"
            aria-labelledby={labelledById}
            aria-describedby={dialogDescribedBy}
            onKeyDown={(e) => {
                if (e.key === Keys.Escape) {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDefaultCancel();
                }
            }}>
            <div className="cui-dialog__position-container">
                <div ref={mainContainerRef} className={`cui-dialog__main-container cui-dialog__main-container--${dialogInstance.size}`} data-hook="dialog-main-container">
                    <div className={`cui-dialog__header-container ${dialogInstance.dismissAction === null ? "cui-dialog__header-container--extra-padding-right" : ""}`}>
                        <IconButton
                            ariaLabel={coreUiI18n.getString("dialog.close")}
                            hidden={dialogInstance.dismissAction === null}
                            className={IconButtonClass.Circular}
                            source={crossIcon}
                            dataHook="dialog-close-button"
                            onClick={handleDefaultCancel}
                            ref={closeButtonRef}
                        />

                        <div aria-live="polite" className="cui-dialog__heading">
                            <h1 tabIndex={-1} id={labelledById}>
                                {step.title}
                            </h1>
                        </div>
                    </div>
                    <div ref={stepRef} className="cui-dialog__content-container">
                        <div className="cui-dialog__content-inner">
                            <BoundaryContext.Provider value={{ element: dialogRef }}>
                                <step.component instance={dialogInstance} describedById={stepContentDescribedBy} />
                            </BoundaryContext.Provider>
                        </div>
                    </div>
                    <div data-hook="dialog-action-container" className="cui-dialog__action-container">
                        <div className="cui-dialog__prevent-ios-flex-issue">
                            <div className="cui-dialog__action-group-container">
                                {step.actions.left.length > 0 && (
                                    <div className="cui-dialog__action-group cui-dialog__action-group--left">
                                        {step.actions.left.map((action): JSX.Element => {
                                            return (
                                                <Button
                                                    key={`${action.label}${step.title}`}
                                                    autoFocus={action.autoFocus}
                                                    ariaLabel={action.ariaLabel}
                                                    ariaDescribedBy={action.ariaDescribedBy}
                                                    className={action.buttonClass}
                                                    dataHook={action.dataHook}
                                                    onClick={() => action.action(model, dialogInstance)}
                                                    disabled={action.disabled(model)}>
                                                    {action.label}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                )}
                                <div className="cui-dialog__action-group cui-dialog__action-group--right">
                                    {step.actions.right.map((action): JSX.Element => {
                                        return (
                                            <Button
                                                key={`${action.label}${step.title}`}
                                                autoFocus={action.autoFocus}
                                                ariaLabel={action.ariaLabel}
                                                ariaDescribedBy={action.ariaDescribedBy}
                                                className={action.buttonClass}
                                                dataHook={action.dataHook}
                                                onClick={() => action.action(model, dialogInstance)}
                                                disabled={action.disabled(model)}>
                                                {action.label}
                                            </Button>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id={screenReaderElementId} className="cui-dialog__prevent-jaws-ios-full-content-readout" tabIndex={-1}>
                {characterUnannouncedByScreenReader}
            </div>
        </dialog>
    );
}
