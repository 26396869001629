import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { warningNotice, successNotice } from "@pjs/core-ui";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import "./formElementInfoService.service";
import "./answerHistory/answerHistory.component";
import "./evidence/evidenceJustificationEditor.component";
import "../../react2angular/dismissableHtmlNotice";
import "../../react2angular/htmlNotice";
import "../../react2angular/answerEvidence";
import "../../utilities/pebbleDate";
import "../../multiLanguageService/multiLanguageService";
import "../../utilities/urlService";
import "../../user/user.service";
import "../../modal/services/modal";
import "../../messageAnnouncer/messageAnnouncer.directive";
import "../../capabilityCriteriaService/capabilityCriteriaService";
import "../../history/services/historyService";
import "../../history/services/routeChangeModifier.service";
import template from "./form-element-info.html";
import { FOCUS_CONSTANTS } from "../../constants/focus.constants";

class FormElementInfoComponent extends NgComponent {
    constructor(
        $scope,
        $element,
        $routeParams,
        $timeout,
        $filter,
        $location,
        $window,
        $sce,
        formElementInfoService,
        multiLanguageService,
        urlService,
        User,
        modal,
        capabilityCriteriaService,
        historyService,
        routeChangeModifier
    ) {
        super();

        this._$scope = $scope;
        this._$element = $element;
        this._$timeout = $timeout;
        this._$filter = $filter;
        this._$location = $location;
        this._$window = $window;
        this._$sce = $sce;
        this._formElementInfoService = formElementInfoService;
        this._modal = modal;
        this._urlService = urlService;
        this._historyService = historyService;
        this._routeChangeModifier = routeChangeModifier;
        this._routeParam = $routeParams.assetId;

        this.multiLanguageService = multiLanguageService;

        this.customSuccessNotice = {
            colour: successNotice.colour,
            icon: ""
        };

        this.evidenceNoticeType = this.customSuccessNotice;
        this._evidenceLabelType = this.multiLanguageService.getString("accessibility.notice.type.evidence");
        this.evidenceNoticeLabel = this.multiLanguageService.getString("accessibility.notice.labels.success", { type: this._evidenceLabelType });
        this.evidenceNoticeMessage = "";
        this.elementInfo = null;
        this._elementData = null;
        this.formElementInfo = null;
        this.evidenceData = null;
        this.showAnswerHistory = false;
        this.announcerMessage = "";
        this._evidenceTitleFocusTimeout = null;
        this._evidenceMenuFocusTimeout = null;
        this._userExists = User.getDto() !== null;
        this._timeslice = urlService.getTimesliceFromRoute();

        this.historyTitleId = `history-title-${this.elementId}`;
        this.evidenceTitleId = `evidence-title-${this.elementId}`;

        this.loadingErrorMessage = multiLanguageService.getString("labels.form_element_info.get_error");
        this.addEvidenceButtonText = multiLanguageService.getString("labels.form_element_info.add_evidence_button");
        this.loading = true;
        this.isEditingEvidenceJustification = false;
        this.evidenceSectionTitle = `${this.multiLanguageService.getString("labels.form_element_info.evidence_info.title")} (0)`;
        this.onUpdateAnswerHistoryJustification = this._updateAnswerHistoryJustification.bind(this);
        this.onBeginUpdateEvidenceJustification = this._beginUpdateEvidenceJustification.bind(this);
        this.onCancelUpdateEvidenceJustification = this._cancelUpdateEvidenceJustification.bind(this);
        this.onCompleteUpdateEvidenceJustification = this._completeUpdateEvidenceJustification.bind(this);
        this.onEvidenceNoticeDismiss = this.onEvidenceNoticeDismiss.bind(this);
        this.onDeleteEvidence = this.onDeleteEvidence.bind(this);
        this.onSelectEvidence = this.onSelectEvidence.bind(this);

        this.editingEvidenceItem = {
            mappedEvidence: null,
            originalEvidence: null
        };

        this._capabilityCriteriaService = capabilityCriteriaService;
        this.evidenceCriteriaScreenReaderMessage = "";
    }

    $onInit() {
        this._loadElementData();
    }

    $onChanges(changes) {
        if (changes.elementId !== undefined && !changes.elementId.isFirstChange()) {
            this._loadElementData();
        }
    }

    $onDestroy() {
        this._$timeout.cancel(this._evidenceTitleFocusTimeout);
        this._$timeout.cancel(this._evidenceMenuFocusTimeout);
    }

    async _loadElementData() {
        this.loading = true;
        this.historyTitleId = `history-title-${this.elementId}`;
        this.evidenceTitleId = `evidence-title-${this.elementId}`;

        this._setLoadingMessage();
        // This await is used to wait for the digest triggered by this.loading = false
        // to be completed so elements hidden by an ng-if can be focused
        await this._setCurrentElement().then(() => (this.loading = false));
        // This must be called outside of the digest triggered by this.loading = false
        // Calling this within the .then() above will cause focusing to fail as the .then
        // is in the middle of a digest and the elements are not yet visible
        this._onElementLoadComplete();
    }

    /**
     * !!! Must be called outside of a digest !!!
     * Used to focus titles during the loading process and
     * calls the onLoadComplete callback if set
     * @private
     */
    _onElementLoadComplete() {
        this.highlightEvidenceId = "";

        if (this.evidenceAnchorId !== "") {
            this.highlightEvidenceId = `evidence-item-${this.evidenceAnchorId}`;
            const anchorEvidenceTitle = document.getElementById(`evidence-title-${this.evidenceAnchorId}`);
            anchorEvidenceTitle.focus({ preventScroll: true });
        } else {
            let focusTitleElement = null;
            if (this.focusTitleType === FOCUS_CONSTANTS.SIDEBAR.HISTORY_TITLE) {
                focusTitleElement = document.getElementById(this.historyTitleId);
            } else if (this.focusTitleType === FOCUS_CONSTANTS.SIDEBAR.EVIDENCE_TITLE) {
                focusTitleElement = document.getElementById(this.evidenceTitleId);
            }

            if (focusTitleElement !== null) {
                focusTitleElement.focus();
            }
        }

        if (typeof this.onLoadComplete === "function") {
            this.onLoadComplete();
        }
    }

    _setLoadingMessage() {
        if (this.isCapability) {
            this.announcerMessage = this.multiLanguageService.getString("labels.form_element_toolbar.a11y.capability_sidebar_opened");
        } else {
            this.announcerMessage = this.multiLanguageService.getString("labels.form_element_toolbar.a11y.evidence_sidebar_opened");
        }
    }

    _resetEvidenceNotice() {
        this.evidenceNoticeType = null;
        this.evidenceNoticeMessage = "";
        this.evidenceNoticeLabel = "";
    }

    addEvidence() {
        this.evidenceCriteriaScreenReaderMessage = "";

        const callbacks = {
            onEvidenceAdded: (evidence) => this._onEvidenceAdded(evidence)
        };

        this.announcerMessage = "";
        this._resetEvidenceNotice();

        if (this.onAddEvidence !== undefined) {
            this.onAddEvidence();
        }

        const evidenceCount = this.elementInfo.Evidence.length;
        const evidenceMinimum = this.formElementInfo.originalElement.Evidence.MinimumRequiredCount;

        const data = {
            info: this._elementData.elementInfo,
            callbacks: callbacks,
            assetId: this.assetId,
            topLevelAssetId: this.topLevelAssetId,
            evidenceCount: evidenceCount,
            evidenceMinimum: evidenceMinimum
        };

        this._formElementInfoService.openAddEvidenceSidebar(data);
    }

    _setCurrentElement() {
        this.evidenceCriteriaScreenReaderMessage = "";

        return this._formElementInfoService
            .getElementDetails(this.elementId, this.assetId, this._timeslice)
            .then((response) => {
                this.elementInfo = response.data;
                this._elementData = this._formElementInfoService.getElementData(this.elementId);
                this.formElementInfo = this._elementData.elementInfo;

                this._setEvidenceData();
                this.evidenceSectionTitle = `${this.multiLanguageService.getString("labels.form_element_info.evidence_info.title")} (${this.evidenceData.evidenceCount})`;
                this.showAnswerHistory = response.data.AnswerHistory !== null;

                if (this.elementInfo.IsCapability) {
                    this.announcerMessage = this.multiLanguageService.getString("labels.form_element_info.a11y.capability_loaded");
                } else {
                    this.announcerMessage = this.multiLanguageService.getString("labels.form_element_info.a11y.evidence_loaded");
                }
            })
            .catch(() => {
                this.announcerMessage = this.loadingErrorMessage;
            });
    }

    _beginUpdateEvidenceJustification(evidenceItem) {
        this.evidenceNoticeMessage = "";
        this.editingEvidenceItem = {
            mappedEvidence: evidenceItem,
            originalEvidence: this.elementInfo.Evidence.find((evidence) => evidence.AggregateId === evidenceItem.aggregateId)
        };

        this.isEditingEvidenceJustification = true;

        if (evidenceItem.justification !== "") {
            this.evidenceSectionTitle = this.multiLanguageService.getString("labels.form_element_info.edit_evidence_justification.title");
        } else {
            this.evidenceSectionTitle = this.multiLanguageService.getString("labels.form_element_info.add_evidence_justification_title");
        }

        if (this.onEditStateChange !== undefined) {
            this.onEditStateChange(true);
        }

        this._$scope.$applyAsync();
    }

    _completeUpdateEvidenceJustification() {
        const editedElementId = this.editingEvidenceItem.mappedEvidence.aggregateId;
        this._clearEditingEvidenceState();
        this.evidenceNoticeType = this.customSuccessNotice;
        this.evidenceNoticeMessage = this.multiLanguageService.getString("labels.form_element_info.edit_evidence_justification_success");
        this.evidenceNoticeLabel = this.multiLanguageService.getString("accessibility.notice.labels.success", { type: this._evidenceLabelType });

        this._setCurrentElement().then(() => {
            this._focusEvidenceTitleById(editedElementId);
        });
        this.announcerMessage = this.evidenceNoticeMessage;
    }

    _clearEditingEvidenceState() {
        this.isEditingEvidenceJustification = false;
        this.evidenceSectionTitle = `${this.multiLanguageService.getString("labels.form_element_info.evidence_info.title")} (${this.evidenceData.evidenceCount})`;
        this.evidenceNoticeMessage = "";
        this.editingEvidenceItem = {
            mappedEvidence: null,
            originalEvidence: null
        };
        this.preventRedirectResolver.cleanUp();

        if (this.onEditStateChange !== undefined) {
            this.onEditStateChange(false);
        }
    }

    _cancelUpdateEvidenceJustification() {
        const editedElementId = this.editingEvidenceItem.mappedEvidence.aggregateId;
        this._clearEditingEvidenceState();
        this._focusEvidenceTitleById(editedElementId);
    }

    _focusEvidenceTitleById(aggregateId) {
        this._evidenceTitleFocusTimeout = this._$timeout(() => {
            const element = document.getElementById(`evidence-title-${aggregateId}`);
            if (element !== null) {
                element.focus();
            }
        });
    }

    _focusEvidenceActionMenuById(aggregateId) {
        this._evidenceMenuFocusTimeout = this._$timeout(() => {
            const element = document.getElementById(`evidence-action-menu-${aggregateId}`);
            if (element !== null) {
                element.focus();
            }
        });
    }

    _updateAnswerHistoryJustification(justification) {
        this._formElementInfoService.updateAnswerHistoryJustification(this.elementId, justification);
    }

    _removeEvidenceItem(evidence) {
        this.evidenceData.disabled = true;

        this._formElementInfoService
            .removeEvidence(this.assetId, this.elementInfo.ElementId, evidence.aggregateId)
            .then((elementInfo) => {
                this.evidenceData.disabled = false;
                const updatedEvidence = this.elementInfo.Evidence.filter((item) => item.AggregateId !== evidence.aggregateId);
                if (updatedEvidence.length === 0) {
                    const addEvidenceElement = document.getElementById("add-evidence-button");
                    if (addEvidenceElement !== null) {
                        addEvidenceElement.focus();
                    }
                } else {
                    const currentIndex = this.elementInfo.Evidence.findIndex((item) => item.AggregateId === evidence.aggregateId);
                    if (currentIndex + 1 > updatedEvidence.length) {
                        const lastEvidenceId = updatedEvidence[updatedEvidence.length - 1].AggregateId;
                        this._focusEvidenceTitleById(lastEvidenceId);
                    } else {
                        const nextEvidenceId = updatedEvidence[currentIndex].AggregateId;
                        this._focusEvidenceTitleById(nextEvidenceId);
                    }
                }

                this.elementInfo.Evidence = updatedEvidence;
                if (elementInfo.status !== "") {
                    this.elementInfo.EvidenceStatus = elementInfo.status;
                    this.formElementInfo.evidenceStatus = elementInfo.status;
                }

                this.evidenceNoticeType = this.customSuccessNotice;
                this.evidenceNoticeLabel = this.multiLanguageService.getString("accessibility.notice.labels.success", { type: this._evidenceLabelType });
                this.evidenceNoticeMessage = this.multiLanguageService.getString("labels.form_element_info.delete_evidence.success_message");
                this.announcerMessage = this.multiLanguageService.getString("labels.form_element_info.a11y.evidence_deleted");
            })
            .catch(() => {
                this.evidenceNoticeType = warningNotice;
                this.evidenceNoticeLabel = this.multiLanguageService.getString("accessibility.notice.labels.warning", { type: this._evidenceLabelType });
                this.evidenceNoticeMessage = this.multiLanguageService.getString("labels.form_element_info.delete_evidence.error_message");
                this.announcerMessage = this.multiLanguageService.getString("labels.form_element_info.a11y.evidence_delete_failed");
                this._focusEvidenceActionMenuById(evidence.aggregateId);
            })
            .finally(() => {
                this._setEvidenceData();
                this.evidenceSectionTitle = `${this.multiLanguageService.getString("labels.form_element_info.evidence_info.title")} (${this.evidenceData.evidenceCount})`;
            });
    }

    onSelectEvidence(evidence, newTab, event) {
        if (event !== undefined && (event.ctrlKey || event.shiftKey)) {
            return;
        }

        if (!newTab) {
            const targetUrl = this._urlService.convertRouteToFullUrl(evidence.assetLink);
            const historyModifierInfo = {
                target: targetUrl,
                modifier: () => this._historyService.updateHistory(this._routeParam, { anchorInfo: { elementId: this.elementInfo.ElementId, evidenceId: evidence.aggregateId } })
            };

            this._routeChangeModifier.add(historyModifierInfo);
        }

        if (event === undefined) {
            if (newTab) {
                const windowReference = this._$window.open();
                windowReference.sessionStorage.clear();
                windowReference.location = evidence.assetLink;
            } else {
                this._$location.url(this._urlService.removeHash(evidence.assetLink));
                this._$scope.$applyAsync();
            }
        }
    }

    onDeleteEvidence(evidence) {
        this._resetEvidenceNotice();
        this.evidenceCriteriaScreenReaderMessage = "";
        const removeEvidenceModalScope = {
            message: this.multiLanguageService.getString("labels.form_element_info.delete_evidence.modal_content"),
            title: this.multiLanguageService.getString("labels.form_element_info.delete_evidence.modal_title"),
            okay_button_label: this.multiLanguageService.getString("buttons.confirm"),
            cancel_button_label: this.multiLanguageService.getString("buttons.cancel")
        };

        removeEvidenceModalScope.on_okay_fn = () => {
            this.announcerMessage = this.multiLanguageService.getString("labels.form_element_info.a11y.evidence_being_deleted");
            this._removeEvidenceItem(evidence);
        };

        this._modal.launch(this._modal.genericTemplates.popupWithButtons, removeEvidenceModalScope);
        this._$scope.$applyAsync();
    }

    onEvidenceNoticeDismiss() {
        this._resetEvidenceNotice();
        this._$scope.$applyAsync();
    }

    _setEvidenceData() {
        const evidenceCount = this.elementInfo.Evidence.length;
        const evidenceMinimum = this.formElementInfo.originalElement.Evidence.MinimumRequiredCount;

        const evidenceCriteriaData = this._capabilityCriteriaService.getRenderData(evidenceCount, evidenceMinimum);

        const evidenceIsComplete = this._capabilityCriteriaService.getBlockIsComplete(
            this.formElementInfo.evidenceStatus === ASSET_CONSTANTS.EVIDENCE_STATES.FULLY_EVIDENCED,
            evidenceCount,
            evidenceMinimum
        );

        const criteriaIsAvailable = this._capabilityCriteriaService.isCriteriaAvailable(evidenceMinimum);
        const disabled = this.viewMode;

        this.evidenceData = {
            showMarkAsComplete: !criteriaIsAvailable,
            isEvidenceComplete: evidenceIsComplete,
            disabled: disabled,
            evidence: this.elementInfo.Evidence.map((evidence) => this._mapEvidence(evidence)),
            evidenceCount: evidenceCount,
            onToggleEvidenceIsComplete: this._toggleEvidenceStatus.bind(this),
            evidenceMessage: evidenceCriteriaData.message,
            evidenceCriteriaAriaLabel: evidenceCriteriaData.ariaLabel,
            evidenceNoticeType: evidenceCriteriaData.notice
        };

        this.evidenceCriteriaScreenReaderMessage = evidenceCriteriaData.message;
    }

    _onEvidenceAdded(newEvidence) {
        this.announcerMessage = this.multiLanguageService.getString("labels.form_element_info.a11y.evidence_added", { count: newEvidence.length });
        this.evidenceNoticeType = this.customSuccessNotice;
        this.evidenceNoticeLabel = this.multiLanguageService.getString("accessibility.notice.labels.success", { type: this._evidenceLabelType });
        this.evidenceNoticeMessage = this.multiLanguageService.getString("labels.form_element_info.add_evidence.success_message");

        this.elementInfo.Evidence = [...newEvidence, ...this.elementInfo.Evidence];
        this._setEvidenceData();

        this.evidenceSectionTitle = `${this.multiLanguageService.getString("labels.form_element_info.evidence_info.title")} (${this.evidenceData.evidenceCount})`;
        this._focusEvidenceTitleById(this.elementInfo.Evidence[0].AggregateId);
        this.preventRedirectResolver.cleanUp();
    }

    _mapEvidence(evidence) {
        let assetLink = "";
        let assetTitle = evidence.Title;
        let evidenceId = evidence.Id;

        if (evidenceId !== null) {
            assetLink = this._urlService.createHashUrl(evidence.Id, null, this.viewMode, !this._userExists, this._timeslice, "", true);
        } else {
            evidenceId = "";
            assetTitle = this.multiLanguageService.getString("labels.form_element_info.justification_only");
        }

        return {
            aggregateId: evidence.AggregateId,
            assetId: evidenceId,
            assetTitle: assetTitle,
            justification: evidence.Justification !== null ? this._$sce.trustAsHtml(evidence.Justification).toString() : "",
            assetLink: assetLink,
            mainType: evidence.MainType,
            subType: evidence.SubType,
            evidenceTitleId: `evidence-title-${evidence.AggregateId}`,
            evidenceMenuId: `evidence-action-menu-${evidence.AggregateId}`,
            formattedDates: this.multiLanguageService.getString("labels.form_element_info.evidence_info.date", {
                createdTime: this._$filter("pebbleDate")(evidence.Created, "timeOnly"),
                createdDate: this._$filter("pebbleDate")(evidence.Created, "dateOnly"),
                modifiedDate: this._$filter("pebbleDate")(evidence.Modified, "dateOnly")
            })
        };
    }

    _toggleEvidenceStatus() {
        this.evidenceData.isEvidenceComplete = this._formElementInfoService.updateEvidenceStatus(this.elementId, !this.evidenceData.isEvidenceComplete);
        this._$scope.$applyAsync();
    }
}

export const FormElementInfoComponentDefinition = {
    bindings: {
        elementId: "<",
        isCapability: "<",
        assetId: "<",
        topLevelAssetId: "<",
        viewMode: "<",
        evidenceAnchorId: "<",
        focusTitleType: "<?",
        onLoadComplete: "<?",
        onAddEvidence: "<?",
        onEditStateChange: "<?",
        isActive: "<",
        preventRedirectResolver: "<?"
    },
    template: template,
    controller: FormElementInfoComponent
};

FormElementInfoComponent.$inject = [
    "$scope",
    "$element",
    "$routeParams",
    "$timeout",
    "$filter",
    "$location",
    "$window",
    "$sce",
    "formElementInfoService",
    "multiLanguageService",
    "urlService",
    "User",
    "modal",
    "capabilityCriteriaService",
    "historyService",
    "routeChangeModifier"
];
angularAMD.component("formElementInfo", FormElementInfoComponentDefinition);
