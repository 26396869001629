import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import "../../multiLanguageService/multiLanguageService";
import "../../assetFiltering/assetFilteringContent/assetFilteringContent";
import template from "./asset-filtering-panel.html";

class AssetFilteringPanel extends NgComponent {
    constructor(multiLanguageService) {
        super();

        this.multiLanguageService = multiLanguageService;

        this.onClickClose = () => {
            this.closePanel();
        };
    }

    closePanel() {
        this.closeInfo.action();
    }
}

export const assetFilteringPanelDefinition = {
    bindings: {
        closeInfo: "<",
        assetId: "<"
    },
    template: template,
    controller: AssetFilteringPanel
};

assetFilteringPanelDefinition.$inject = ["multiLanguageService"];
angularAMD.component("assetFilteringPanel", assetFilteringPanelDefinition);
