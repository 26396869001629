import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { approvalNegative, approvalNeutral, approvalPositive, ApprovalStatus } from "@pjs/feedback";
import { ASSESSMENT_CONSTANTS } from "../../../constants/assessment.constants";
import { ACTION_CONSTANTS } from "../../../constants/action.constants";
import "../../../react2angular/approvalView";
import "./approvalEdit/approvalEdit.component";
import "../../../approvalOptions/approvalOptions.service";
import "../../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import "../../../loadingSpinner/loadingSpinner";
import "../../../multiLanguageService/multiLanguageService";
import template from "./approval.html";

class ApprovalComponent extends NgComponent {
    constructor(feedbackHelper, multilanguageService, approvalOptionsService) {
        super();

        this.ACTION_CONSTANTS = ACTION_CONSTANTS;
        this._feedbackHelper = feedbackHelper;
        this._approvalOptionsService = approvalOptionsService;
        this._saveData = null;
        this.loading = true;
        this.approvalOptions = null;
        this.approvalNotice = null;
        if (this.feedback.LevelText !== undefined) {
            this.labelText = this.feedback.LevelText.toUpperCase();
        } else {
            this.labelText = multilanguageService.getString("atlas_sidebar.feedback_types.approval.approval_label");
        }
        this.onUpdateFeedback = this._updateFeedback.bind(this);
    }

    $onChanges(changes) {
        if (changes.mode !== undefined) {
            if (changes.feedback !== undefined && changes.mode.currentValue === ACTION_CONSTANTS.VIEW) {
                this.approvalNotice = this._createStatusNotice();
            }

            if (changes.mode.currentValue === ACTION_CONSTANTS.EDIT) {
                this.loading = true;
                this._saveData = {
                    status: this.feedback.Status,
                    text: this.feedback.Comment
                };

                let level = ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_ONE;
                if (this.feedback.Level === "2") {
                    level = ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_TWO;
                } else if (this.feedback.Level === "3") {
                    level = ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_THREE;
                }
                this._approvalOptionsService
                    .getApprovalOptions(level)
                    .then((approvalOptions) => {
                        this.approvalOptions = approvalOptions;
                    })
                    .catch(() => {
                        this.approvalOptions = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    }

    _createStatusNotice() {
        return {
            statusText: this.feedback.StatusText,
            noticeType: this._getApprovalStatusNotice(),
            status: ApprovalStatus[this.feedback.Status]
        };
    }

    _getApprovalStatusNotice() {
        switch (this.feedback.Status) {
            case ASSESSMENT_CONSTANTS.APPROVAL_STATES.POSITIVE:
                return approvalPositive;
            case ASSESSMENT_CONSTANTS.APPROVAL_STATES.NEGATIVE:
                return approvalNegative;
            case ASSESSMENT_CONSTANTS.APPROVAL_STATES.NEUTRAL:
            default:
                return approvalNeutral;
        }
    }

    _updateFeedback(changes) {
        Object.assign(this._saveData, changes);
        this._validation = this._feedbackHelper.getApprovalValidation(this._saveData.status);

        if (this.onEdit !== undefined) {
            this.onEdit({
                validation: this._validation,
                changes: { Comment: this._saveData.text, Status: this._saveData.status }
            });
        }
    }
}

var approvalDefinition = {
    bindings: {
        feedback: "<",
        mode: "<",
        onEdit: "&?",
        feedbackStatements: "<"
    },
    template: template,
    controller: ApprovalComponent
};

ApprovalComponent.$inject = ["feedbackHelper", "multiLanguageService", "approvalOptionsService"];
angularAMD.component("approval", approvalDefinition);
export { approvalDefinition as approval };
