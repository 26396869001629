import { NgComponent } from "@pebblepad/angular/ngComponent";
import "./ActiveToolbarCoordinator.service";
import "../pebbleEditorPlugins/linkerPlugin/LinkerService";

export class FloatingToolbarReactEditorAdapterController extends NgComponent {
    constructor(activeToolbarCoordinator, $scope, linkerService) {
        super();
        this.onToolbarDisplayChange = activeToolbarCoordinator.onToolbarToggleWithFloating.bind(activeToolbarCoordinator);
        this.onEditorChange = (value) => {
            this.onChange(value);
            $scope.$applyAsync();
        };

        this.onLink = (result, focusTrackerProxy) => linkerService.createModal(result, focusTrackerProxy);
    }

    $onChanges() {
        this.editorContent = this.content === undefined || this.content === null ? "" : this.content;
        this.editorPlaceholder = this.placeholder === undefined ? "" : this.placeholder;
    }
}

FloatingToolbarReactEditorAdapterController.$inject = ["ActiveToolbarCoordinator", "$scope", "linkerService"];
