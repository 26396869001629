import { Fragment, FunctionComponent, PropsWithChildren, useId } from "react";
import { Icon } from "../icon/Icon.component";
import { tickIcon } from "../icon/icons/Tick.icon";
import { ICheckboxProps } from "./interfaces/ICheckboxProps";
import "../../public-utilities.css";
import "./styles/checkbox.css";

export const Checkbox: FunctionComponent<PropsWithChildren<ICheckboxProps>> = (props) => {
    const id = useId();

    return (
        <Fragment>
            <input
                className="cui-checkbox__input cui-util-hide-element"
                type="checkbox"
                id={id}
                name={props.name}
                value={props.value}
                data-hook="checkbox-input"
                checked={props.isChecked}
                disabled={props.isDisabled}
                required={props.isRequired}
                onChange={(event) => props.onChange(event.target.checked)}
            />

            <label className="cui-checkbox__label" htmlFor={id} data-hook="checkbox-label">
                <span className="cui-checkbox__input-styled">
                    <Icon source={tickIcon} className="cui-checkbox__icon" fill="rgb(255, 255, 255)" />
                </span>

                <span className="cui-checkbox__label-text" data-hook="checkbox-label-text">
                    {props.children}
                </span>
            </label>
        </Fragment>
    );
};
