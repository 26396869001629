import { RefObject } from "react";
import { InteractiveElementsList } from "../../../utils/interactive-elements-list/InteractiveElementsList";

export const focusFirstElementInDialog = (
    dialogRef: RefObject<HTMLDialogElement>,
    dialogViewContainer: RefObject<HTMLElement>,
    stepContainer: RefObject<HTMLElement>,
    ignoreElements: ReadonlyArray<Element | null>
): void => {
    if (dialogRef.current === null || dialogViewContainer.current === null || stepContainer.current === null) {
        return;
    }

    const hasConsumerAppliedFocus = dialogViewContainer.current.contains(document.activeElement) && !ignoreElements.includes(document.activeElement);

    if (!stepContainer.current.contains(document.activeElement)) {
        stepContainer.current.scrollTop = 0;
    }

    if (!hasConsumerAppliedFocus) {
        const interactiveElements = new InteractiveElementsList(dialogViewContainer.current);

        for (const element of interactiveElements) {
            if (!ignoreElements.includes(element)) {
                element.focus();
                return;
            }
        }
    }
};
