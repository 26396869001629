import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { historyIcon } from "@pjs/core-ui";
import "../feedbackText/feedbackText.component";
import "../../../react2angular/StaticLabel";
import "../../../react2angular/icon";
import template from "./assessor-field.html";

class AssessorFieldComponent extends NgComponent {
    constructor(multiLanguageService) {
        super();

        this.newerExistsText = multiLanguageService.getString("labels.canvas.assessor_feedback_newer_exists");
        this.labelText = multiLanguageService.getString("labels.canvas.assessor_feedback");
        this.historyIcon = historyIcon.toString();
        this.labelDataHook = "assessor-field-type-label";
    }
}

const assessorFieldDefinition = {
    bindings: {
        feedback: "<"
    },
    template: template,
    controller: AssessorFieldComponent
};

AssessorFieldComponent.$inject = ["multiLanguageService"];
angularAMD.component("assessorField", assessorFieldDefinition);
export { assessorFieldDefinition as assessorField };
