"use strict";
import Flow from "@flowjs/flow.js";
window.Flow = Flow;

import "@pebblepad/angular";
import { angularAMD, PebbleAmdRouteFactory } from "@pebblepad/amd";
import { authenticated401Reasons } from "@pjs/components/constants/authenticated401Reasons";
import { StandardsOverviewService } from "./services/standardsOverview.service";
import { FeedbackSidebarService } from "./services/feedbackSidebar.service";
import { FormElementInfoSidebarService } from "./services/formElementInfoSidebar.service";
import { AbortableHttp } from "@pjs/components/abortablePromises/AbortableHttp";
import { MigratorRunner } from "@pjs/components/utilities/migrators/migratorRunner";
import { i18nManager } from "@pjs/i18n";
import { EvidenceMigrator } from "@pjs/components/utilities/migrators/evidenceMigrator";
import { EWC_STATUSES_CONSTANTS } from "./constants/ewcStatuses.constants";
import { ASSET_CONSTANTS } from "@pjs/components/constants/asset.constants";
import "angular-recaptcha/release/angular-recaptcha";
import "@pebblepad/angular-sanitize";
import "@pebblepad/angular-route";
import "@pebblepad/angular-aria";
import "tocca";
import "@flowjs/ng-flow";
import "./links/assetLink.service";
import "@pjs/components/utilities/exceptionReporter.factory";
import "@pjs/components/spaMenu/spaMenu";
import "@pjs/components/multiLanguageService/multiLanguageService";
import "@pjs/components/utilities/cssLoader";
import "@pjs/components/pebbleRoutingModule/pebbleRouting.module";
import "@pjs/components/pebbleHttpModule/pebbleHttp.module";
import "@pjs/components/security/pebbleSecurity.module";
import "@pjs/components/links/pebbleLinks.module";
import "@pjs/components/conditionalAttributeModule/conditionalAttribute.module";
import "@pjs/components/pebbleHtml/pebbleHtml.module";
import "@pjs/components/spaMenu/spaMenuServices/spaDisplayHelper";
import "@pjs/components/uberDropdown/uberDropdown";
import "@pjs/components/builder/dataManager/dataManagerService";
import "@pjs/components/utilities/i18nHelper.service";
import "@pjs/components/utilities/eventHandler";
import "@pjs/components/utilities/assetBuilderHelper.service";
import "@pjs/components/utilities/urlService";
import "@pjs/components/user/user.service";
import "@pjs/components/statusToolbar/services/statusToolbar.service";
import "@pjs/components/uberAriaModule/uberAria.module";
import "@pjs/components/assetEndpointService/assetEndpoint.service";
import "@pjs/components/userAgent/userAgent.service";
import "@pjs/components/skipToContentLink/skipToContentLink.directive";
import "@pjs/components/polyfills/polyfills";
import "@pjs/components/angular-inview/require-angular-inview";
import "@pjs/components/formComponents/formElementInfo/formElementInfoSidebar.provider";
import "@pjs/components/feedback/feedbackSidebarServiceProvider/feedbackSidebarService.provider";
import "./features/builder/userStatus/userStatus.service";
import "@pjs/components/workbook/standardsOverview/standardsOverview.provider";
import "@pjs/components/utilities/migrators/migratorRunner.provider";
import "@pjs/components/feedback/feedbackFilterer/feedbackFiltererCollection.service";
import "@pjs/components/assetFiltering/assetFilteringConfig.provider";
import "@pjs/components/analytics/TrackFraming.effect";
import "@pjs/components/react2angular/dialogView";
import "@pjs/components/programWorkspace/programWorkspaceStatusLink.provider";
import "./configs/api/AssetBlock";
import "./configs/api/Files";
import "./configs/api/UserDomain";
import { featureSettingsResolver } from "@pjs/components/featureSettings/FeatureSettingsResolver.const";
import { userResolver } from "@pjs/components/user/UserResolver.const";
import { RollbackInterceptorProvider } from "@pjs/components/rollback/RollbackInterceptorProvider";
import { CustomDimension, tracker } from "@pjs/analytics";
import { richTextConfig } from "@pjs/rich-text";
import { captchaConfig } from "@pjs/captcha";

const versionMeta = document.querySelector('meta[name="version"]');
const version = versionMeta !== null ? versionMeta.content : Date.now();
window.addEventListener("DOMContentLoaded", () => {
    /**
     * @ngdoc overview
     * @name plusApp
     * @description
     * # plusApp
     *
     * Main module of the application.
     */
    var app = angular.module("plusApp", [
        "ngRoute",
        "ngSanitize",
        "ngAria",
        "pebbleRouting",
        "pebbleHttp",
        "pebbleSecurity",
        "flow",
        "vcRecaptcha",
        "uberAria",
        "multiLangModule",
        "conditionalAttribute",
        "pebbleLinks",
        "pebbleHtml",
        "pebbleExceptionReporter"
    ]);

    var dictionaries = {
        main: null,
        fallback: null
    };
    app.version = version;
    //Block $onInit https://code.angularjs.org/1.6.1/docs/guide/migration#commit-bcd0d4
    app.config([
        "$compileProvider",
        function ($compileProvider) {
            $compileProvider.preAssignBindingsEnabled(true);
        }
    ]);

    //Routing
    //=============================================================================================================
    app.config([
        "$routeProvider",
        "PebbleRoutingValidationProvider",
        function ($routeProvider, PebbleRoutingValidation) {
            /**
             * Default
             * -------
             */
            $routeProvider.otherwise({
                redirectTo: "/home",
                resolve: { whenInternalUser: PebbleRoutingValidation.whenInternalUser, withFeatureSettings: featureSettingsResolver }
            });

            /**
             * Authenticated Pages
             * -------------------
             */
            $routeProvider
                .when(
                    "/test",
                    PebbleAmdRouteFactory.create({
                        title: "Test",
                        controller: "homeCtrl",
                        import: () => import("./controllers/homeCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/views/main.lazy.html",
                        trackerKey: "/test",
                        resolve: { whenAuthenticated: PebbleRoutingValidation.whenAuthenticated }
                    })
                )
                .when(
                    "/viewer/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.view_asset",
                        controller: "viewerCtrl",
                        import: () => import("./features/viewer/viewerCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/viewer/templates/base.lazy.html",
                        trackerKey: "/view/asset",
                        reloadOnSearch: false,
                        resolve: { whenAuthenticated: PebbleRoutingValidation.whenAuthenticated, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/builder/viewer/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.builder_viewer",
                        controller: "builderViewerCtrl",
                        import: () => import("./features/builder/viewer/builderViewerCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/builder/viewer/templates/builder-viewer.lazy.html",
                        trackerKey: "/builder/viewer",
                        reloadOnSearch: false,
                        resolve: { whenAuthenticated: PebbleRoutingValidation.whenAuthenticated, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/mapping/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.mapping_viewer",
                        controller: "mappingCtrl",
                        import: () => import("./features/mapping/mappingCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/mapping/mapping.lazy.html",
                        trackerKey: "/mapping/viewer",
                        reloadOnSearch: false,
                        resolve: { whenAuthenticated: PebbleRoutingValidation.whenAuthenticated, withFeatureSettings: featureSettingsResolver }
                    })
                );

            /**
             * Authenticated and Versioned Pages
             * ---------------------------------
             */
            $routeProvider.when(
                "/viewer/:timeslice/:assetId/?",
                PebbleAmdRouteFactory.create({
                    title: "page_titles.versioned_asset_view",
                    controller: "viewerCtrl",
                    import: () => import("./features/viewer/viewerCtrl"),
                    afterImport: () => i18nManager.load(),
                    templateUrl: "./scripts/features/viewer/templates/base.lazy.html",
                    trackerKey: "/view/asset/timeslice",
                    reloadOnSearch: false,
                    resolve: { whenAssetVersioned: PebbleRoutingValidation.whenAssetVersioned, withFeatureSettings: featureSettingsResolver }
                })
            );

            /**
             * Authenticated Internal User Pages
             * ---------------------------------
             */
            $routeProvider
                .when(
                    "/home/:type?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.home",
                        controller: "homeDashCtrl",
                        import: () => import("./features/homeDash/homeDashCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/homeDash/templates/base.lazy.html",
                        trackerKey: "/dashboard/home",
                        reloadOnSearch: false,
                        resolve: { whenInternalUser: PebbleRoutingValidation.whenInternalUser, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/learningCentre/:type?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.learning_centre",
                        controller: "learningCentreCtrl",
                        import: () => import("./features/learningCentre/learningCentreCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/learningCentre/templates/base.lazy.html",
                        trackerKey: "/learningCentre",
                        resolve: { whenInternalUser: PebbleRoutingValidation.whenInternalUser, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/moreinfo/:type?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.home",
                        controller: "plusHomeMoreInfoCtrl",
                        import: () => import("./features/home/plusHomeMoreInfoCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/home/templates/home_more_info.lazy.html",
                        trackerKey: "/moreInfo",
                        resolve: { whenInternalUser: PebbleRoutingValidation.whenInternalUser, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/store/:type?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.store",
                        controller: "storeCtrl",
                        import: () => import("./features/store/storeCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/store/templates/base.lazy.html",
                        trackerKey: "/store",
                        reloadOnSearch: false,
                        resolve: { whenInternalUser: PebbleRoutingValidation.whenInternalUser, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/account/settings/:view?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.account_settings",
                        controller: "accountSettingsCtrl",
                        import: () => import("./features/accountSettings/accountSettingsCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/accountSettings/template/account-settings.lazy.html",
                        trackerKey: "/account/settings",
                        reloadOnSearch: false,
                        resolve: { whenInternalUser: PebbleRoutingValidation.whenInternalUser, withFeatureSettings: featureSettingsResolver }
                    })
                );

            /**
             * Authenticated Internal User - Asset / Resource  Builders
             * ------------------------------------
             */
            $routeProvider
                .when(
                    "/activitylog/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.edit_activity_log",
                        mainType: "ActivityLog",
                        controller: "activityLogCtrl",
                        import: () => import("./features/activitylog/activityLogCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/activitylog/templates/activitylog.lazy.html",
                        trackerKey: "/edit/activitylog",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/blog/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.edit_blog",
                        mainType: "Blog",
                        controller: "blogCtrl",
                        import: () => import("./features/blog/blogCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/blog/templates/blog.lazy.html",
                        trackerKey: "/edit/blog",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/builder/template/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.template_builder",
                        mainType: "TemplateBuilder",
                        controller: "templateBuilderCtrl",
                        import: () => import("./features/builder/template/templateBuilderCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/builder/template/templates/template-builder.lazy.html",
                        trackerKey: "/builder/template",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/builder/workbook/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.workbook_builder",
                        mainType: "WorkbookBuilder",
                        controller: "workbookBuilderCtrl",
                        import: () => import("./features/builder/workbook/workbookBuilderCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/builder/workbook/templates/workbook-builder.lazy.html",
                        trackerKey: "/builder/workbook",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/collection/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.edit_collection",
                        mainType: "AssetCollectionWrapper",
                        controller: "collectionCtrl",
                        import: () => import("./features/collection/collectionCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/collection/templates/collection.lazy.html",
                        trackerKey: "/edit/collection",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/file/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.edit_file",
                        mainType: "File",
                        controller: "fileCtrl",
                        import: () => import("./features/file/fileCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/file/templates/file.lazy.html",
                        trackerKey: "/edit/file",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/foliopage/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.edit_page",
                        mainType: "WebfolioPage",
                        controller: "foliopageCtrl",
                        afterImport: () => i18nManager.load(),
                        import: () => import("./features/foliopage/foliopageCtrl"),
                        templateUrl: "./scripts/features/foliopage/templates/folioPage.lazy.html",
                        trackerKey: "/edit/page",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/post/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.edit_post",
                        mainType: "Post",
                        controller: "postCtrl",
                        import: () => import("./features/post/postCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/post/templates/post.lazy.html",
                        trackerKey: "/edit/post",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/template/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.edit_template",
                        mainType: "FormResponse",
                        controller: "templateCtrl",
                        import: () => import("./features/template/templateCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/template/templates/base.lazy.html",
                        trackerKey: "/edit/template",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/webfolio/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.edit_portfolio",
                        mainType: "Webfolio",
                        controller: "webfolioCtrl",
                        import: () => import("./features/webfolio/webfolioCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/webfolio/templates/webfolio.lazy.html",
                        trackerKey: "/edit/portfolio",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                )
                .when(
                    "/workbook/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.edit_workbook",
                        mainType: "WorkBookResponse",
                        controller: "workbookCtrl",
                        import: () => import("./features/workbook/workbookCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./scripts/features/workbook/templates/base.lazy.html",
                        trackerKey: "/edit/workbook",
                        reloadOnSearch: false,
                        resolve: { whenAssetBuilder: PebbleRoutingValidation.whenAssetBuilder, withFeatureSettings: featureSettingsResolver }
                    })
                );

            /**
             * Authenticated External User Pages
             * ------------
             */
            $routeProvider.when(
                "/dashboard/externals",
                PebbleAmdRouteFactory.create({
                    title: "page_titles.external_dashboard",
                    controller: "externalDashboardCtrl",
                    import: () => import("./features/homeDash/controllers/externalDashboard.controller"),
                    afterImport: () => i18nManager.load(),
                    templateUrl: "./scripts/features/homeDash/templates/custom/external-dashboard.lazy.html",
                    trackerKey: "/dashboard/externals",
                    controllerAs: "vm",
                    reloadOnSearch: false,
                    resolve: { whenExternalUser: PebbleRoutingValidation.whenExternalUser, withFeatureSettings: featureSettingsResolver }
                })
            );

            /**
             * Authenticated Resource Selector
             * ---------------------------------
             */
            $routeProvider.when(
                "/resourceselector/?",
                PebbleAmdRouteFactory.create({
                    title: "page_titles.resource_selector",
                    controller: "resourceSelectorCtrl",
                    import: () => import("./features/resourceSelector/resourceSelectorCtrl"),
                    afterImport: () => i18nManager.load(),
                    templateUrl: "./scripts/features/resourceSelector/templates/resource-selector.lazy.html",
                    trackerKey: "/resourceSelector/",
                    reloadOnSearch: false,
                    resolve: { whenInternalUser: PebbleRoutingValidation.whenInternalUser, withFeatureSettings: featureSettingsResolver }
                })
            );

            /**
             * Public Pages
             * ------------
             */
            $routeProvider
                .when(
                    "/hidden",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.hidden",
                        controller: "hiddenCtrl",
                        import: () => import("./controllers/hiddenCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./views/hidden.lazy.html",
                        trackerKey: "/allWebshares"
                    })
                )
                .when("/images/no_profile.png", {})
                .when(
                    "/login",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.login",
                        controller: "loginCtrl",
                        import: () => import("./controllers/loginCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./views/login.lazy.html",
                        trackerKey: "/login"
                    })
                )
                .when(
                    "/public/:assetId?",
                    PebbleAmdRouteFactory.create({
                        title: "page_titles.public",
                        controller: "publicCtrl",
                        import: () => import("./controllers/publicCtrl"),
                        afterImport: () => i18nManager.load(),
                        templateUrl: "./views/public.lazy.html",
                        trackerKey: "/view/asset/public",
                        reloadOnSearch: false,
                        resolve: { withFeatureSettings: featureSettingsResolver, withUser: userResolver }
                    })
                );
        }
    ]);

    //HTTP
    //=============================================================================================================
    app.config([
        "$httpProvider",
        "PebbleHttpCacheBusterProvider",
        function ($httpProvider, pebbleHttpCacheBusterProvider) {
            //Base url for shared components
            app.shared_component_base_url = window.config.getSharedComponentBaseUrl();

            pebbleHttpCacheBusterProvider.value = app.version;

            $httpProvider.interceptors.push("PebbleHttpInterceptor");
            $httpProvider.interceptors.push(RollbackInterceptorProvider.serviceName);
        }
    ]);

    app.config([
        "$provide",
        ($provide) => {
            $provide.decorator("$http", [
                "$delegate",
                "$q",
                ($delegate, $q) => {
                    return new AbortableHttp($delegate, $q);
                }
            ]);
        }
    ]);

    //Domain Resource loading
    //=============================================================================================================
    app.config([
        "$sceDelegateProvider",
        function ($sceDelegateProvider) {
            // Allow loading from our domain.  Notice the difference between * and **.
            var whiteListUrlShared = window.config.getSharedComponentBaseUrl() + "**";
            $sceDelegateProvider.resourceUrlWhitelist(["self", whiteListUrlShared]);
        }
    ]);

    //StatusToolbar!
    var appContainer = document.getElementById("app-container");
    app.config([
        "StatusToolbarProvider",
        function (StatusToolbarProvider) {
            StatusToolbarProvider.setContainer(appContainer);
        }
    ]);

    //Modal setup
    //=============================================================================================================
    app.config([
        "modalConfigProvider",
        function (modalConfig) {
            var spaMenuContainer = document.getElementById("spa-menu-container");
            modalConfig.ariaContainers.push(spaMenuContainer, appContainer);
        }
    ]);

    app.config([
        "AssetEndpointServiceProvider",
        function (AssetEndpointServiceProvider) {
            var endpoints = {
                doIHavePermission: `${window.config.getApiBaseUrl()}asset/DoIHavePermission`,
                getDirectStreamLink: `${window.config.getApiBaseUrl()}File/GetDirectStreamLink`,
                transmitImage: `${window.config.getApiBaseUrl()}File/TransmitImage`,
                feedback: `${window.config.getApiBaseUrl()}Feedback/{{assetId}}?anchorId={{anchorId}}&pageAssetId={{pageAssetId}}`,
                feedbackReply: `${window.config.getApiBaseUrl()}FeedbackReply/{{assetId}}/{{feedbackId}}`,
                feedbackReplyWithId: `${window.config.getApiBaseUrl()}FeedbackReply/{{assetId}}/{{feedbackId}}/{{replyId}}`,
                getLoggedInUserData: `${window.config.getApiBaseUrl()}SharedMenu/GetLoggedInUserData`,
                updateUserSettings: `${window.config.getApiBaseUrl()}SharedMenu/UpdateUserSettings`,
                getUserSettings: `${window.config.getApiBaseUrl()}SharedMenu/GetUserSettings`,
                getEditStatusForAssets: `${window.config.getApiBaseUrl()}AssetDto/GetEditStatusForAssets`,
                getBuilder: `${window.config.getApiBaseUrl()}AssetDto/GetBuilder`,
                getAssetAndCheckPermission: `${window.config.getApiBaseUrl()}AssetDto/GetAssetAndCheckPermission`,
                getPublicAssetReference: `${window.config.getApiBaseUrl()}Asset/GetPublicAssetReference`,
                saveOrUpdate: `${window.config.getApiBaseUrl()}AssetDto/SaveOrUpdate`,
                upload: `${window.config.getApiBaseUrl()}Upload`,
                saveUpload: `${window.config.getApiBaseUrl()}Upload/Save?returnRef={{returnRef}}`,
                getAnswerHistory: `${window.config.getApiBaseUrl()}Asset/getAnswerHistory?assetId={{assetId}}&elementId={{elementId}}&timeslice={{timeslice}}`,
                convertAssetIdsToBlogPostViewModels: `${window.config.getApiBaseUrl()}Blog/ConvertAssetIdsToBlogPostViewModels?assetId={{blogAssetId}}`,
                getMappingDetails: `${window.config.getApiBaseUrl()}AssetSidebar/GetMappingDetails?assetId={{assetId}}`,
                getStandardsOverviewData: `${window.config.getApiBaseUrl()}AssetSidebar/OverviewData?assetId={{assetId}}&pageId={{pageId}}`,
                removeMapping: `${window.config.getApiBaseUrl()}Workbook/DeleteStandardsResponse/{{assetId}}?elementId={{elementId}}`,
                getAssessmentOverview: `${window.config.getApiBaseUrl()}WorkBook/AssessmentOverview?assetId={{assetId}}&pageId={{pageId}}`,
                getStandardsPageOverviews: `${window.config.getApiBaseUrl()}Workbook/StandardsPageOverviews?assetId={{assetId}}&pageId={{pageId}}`,
                getStandardsElementsData: `${window.config.getApiBaseUrl()}WorkBook/StandardsElementsData?assetId={{assetId}}&responseId={{responseId}}`,
                getCreatorPermission: `${window.config.getApiBaseUrl()}StatusPermissions/AssetCreatorHasPermission?permission={{permission}}&assetId={{assetId}}`,
                getCurrentProgress: `${window.config.getApiBaseUrl()}Progress/CurrentProgress?assetId={{assetId}}`,
                isAsset: `${window.config.getApiBaseUrl()}Asset/IsAsset?assetId={{assetId}}&mainType={{mainType}}`,
                getAssetWithAvailableActions: `${window.config.getApiBaseUrl()}AssetDto/GetAssetWithAvailableActions?assetId={{assetId}}`,
                getWorkflowState: `${window.config.getApiBaseUrl()}Workflow/GetWorkflowState?assetId={{assetId}}&templateId={{templateId}}`,
                updateWorkflowState: `${window.config.getApiBaseUrl()}Workflow/UpdateWorkflowState?assetId={{assetId}}&templateId={{templateId}}&target={{target}}`,
                addWorkflowAdditionalInfo: `${window.config.getApiBaseUrl()}Workflow/AddWorkflowAdditionalInfo?assetId={{assetId}}`,
                removeWorkflowAdditionalInfo: `${window.config.getApiBaseUrl()}Workflow/RemoveWorkflowAdditionalInfo?assetId={{assetId}}`,

                getComment: `${window.config.getApiBaseUrl()}Comment/GetCommentThread?assetId={{assetId}}&commentId={{commentId}}`,
                getComments: `${window.config.getApiBaseUrl()}Comment/GetComments?assetId={{assetId}}&answerId={{answerId}}`,
                addNewOrUpdateComment: `${window.config.getApiBaseUrl()}Comment/AddNewOrUpdateComment`,
                deleteComment: `${window.config.getApiBaseUrl()}Comment/DeleteComment?assetId={{assetId}}&commentId={{commentId}}`,

                getElementDetails: `${window.config.getApiBaseUrl()}FormElement/GetElementDetails?assetId={{assetId}}&elementId={{elementId}}&timeslice={{timeslice}}`,
                addEvidenceMapFromResponse: `${window.config.getApiBaseUrl()}EvidenceMap/AddFromResponse`,
                removeEvidenceMapFromResponse: `${window.config.getApiBaseUrl()}EvidenceMap/RemoveFromResponse`,
                addTextOnlyEvidence: `${window.config.getApiBaseUrl()}TextEvidence/Add`,
                updateEvidenceJustificationFromResponse: `${window.config.getApiBaseUrl()}EvidenceMap/UpdateEvidenceJustificationFromResponse`,
                procureWorkbookResponsePage: `${window.config.getApiBaseUrl()}WorkBookResponse/{{assetId}}/{{timeslice}}/ProcurePage/{{pageId}}?create={{canCreate}}`,
                modifyWorkbookResponsePage: `${window.config.getApiBaseUrl()}WorkBookResponse/{{assetId}}/pages/{{pageId}}`,
                movePortfolioPage: `${window.config.getApiBaseUrl()}Portfolio/{{assetId}}/pages/{{pageId}}`,
                modifyPortfolioPage: `${window.config.getApiBaseUrl()}Portfolio/{{assetId}}/pages/{{pageId}}`,
                convertPortfolioPage: `${window.config.getApiBaseUrl()}Portfolio/{{assetId}}/convert/{{pageId}}`,
                getImageAltText: `${window.config.getApiBaseUrl()}AssetDto/GetAltText?assetId={{assetId}}`,

                createDataFilter: `${window.config.getApiBaseUrl()}AssetFilter/Create?assetId={{assetId}}`,
                updateDataFilter: `${window.config.getApiBaseUrl()}AssetFilter/Update?assetId={{assetId}}`,
                removeDataFilter: `${window.config.getApiBaseUrl()}AssetFilter/Delete?assetId={{assetId}}&filterId={{filterId}}`,
                getDataFilter: `${window.config.getApiBaseUrl()}AssetFilter/Get?assetId={{assetId}}`,

                consentToEmbeddedContent: `${window.config.getApiBaseUrl()}Consent/EmbeddedContent`,
                getFeaturesEnabled: `${window.config.getApiBaseUrl()}feature`,

                isLockedByAnother: `${window.config.getApiBaseUrl()}Locking/IsLockedByAnother?assetId={{assetId}}`,
                getBasicDetails: `${window.config.getApiBaseUrl()}AssetSidebar/GetBasicDetails?assetId={{assetId}}`,

                exportToPdf: `${window.config.getApiBaseUrl()}Asset/ExportToPdf?assetId={{assetId}}`
            };

            var extraParams = {
                routeParams: ["assetId"]
            };

            AssetEndpointServiceProvider.setExtraParameters(extraParams);
            AssetEndpointServiceProvider.setEndPoints(endpoints);
        }
    ]);

    app.config([
        "feedbackSettingsServiceProvider",
        function (feedbackSettingsServiceProvider) {
            feedbackSettingsServiceProvider.config.showModifiedTooltip = false;
            feedbackSettingsServiceProvider.config.showActions = false;
        }
    ]);

    app.config([
        "standardsOverviewServiceProvider",
        function (standardsOverviewServiceProvider) {
            const resolver = ($rootScope, multiLanguageService) => {
                return new StandardsOverviewService($rootScope, multiLanguageService);
            };

            resolver.$inject = ["$rootScope", "multiLanguageService"];
            standardsOverviewServiceProvider.setResolver(resolver);
        }
    ]);

    app.config([
        "formElementInfoSidebarServiceProvider",
        function (formElementInfoSidebarServiceProvider) {
            const resolver = ($rootScope) => {
                return new FormElementInfoSidebarService($rootScope);
            };

            resolver.$inject = ["$rootScope"];
            formElementInfoSidebarServiceProvider.setResolver(resolver);
        }
    ]);

    app.config([
        "$locationProvider",
        function ($locationProvider) {
            $locationProvider.hashPrefix("");
        }
    ]);

    app.config([
        "feedbackSidebarServiceProvider",
        function (feedbackSidebarServiceProvider) {
            const resolver = ($rootScope, feedbackFiltererCollection, multiLanguageService, $q) =>
                new FeedbackSidebarService($rootScope, feedbackFiltererCollection, multiLanguageService, $q);
            resolver.$inject = ["$rootScope", "feedbackFiltererCollection", "multiLanguageService", "$q"];
            feedbackSidebarServiceProvider.setResolver(resolver);
        }
    ]);

    app.config([
        "$anchorScrollProvider",
        function ($anchorScrollProvider) {
            $anchorScrollProvider.disableAutoScrolling();
        }
    ]);

    app.config([
        "migratorRunnerProvider",
        function (migratorRunnerProvider) {
            migratorRunnerProvider.registerMigrators(EvidenceMigrator.token);
        }
    ]);

    app.config([
        "assetFilteringConfigProvider",
        function (assetFilteringConfigProvider) {
            assetFilteringConfigProvider.config.disableWhenInFolders = [ASSET_CONSTANTS.FOLDERS.DELETED, ASSET_CONSTANTS.FOLDERS.NOT_IN_USERS];
        }
    ]);

    app.config([
        "multiLanguageServiceProvider",
        function (multiLanguageServiceProvider) {
            multiLanguageServiceProvider.dictionaries = dictionaries;
        }
    ]);

    app.run([
        "$http",
        "$rootScope",
        "$route",
        "$location",
        "$log",
        "$q",
        "$injector",
        "$interval",
        "$window",
        "cssLoader",
        "multiLanguageService",
        "baseUrlsFactory",
        "spaDisplayHelper",
        "UserStatusService",
        "User",
        "UserAPI",
        "ROUTE_REJECTION_REASONS",
        "PebbleHttpInterceptor",
        "UserAgentService",
        function (
            $http,
            $rootScope,
            $route,
            $location,
            $log,
            $q,
            $injector,
            $interval,
            $window,
            cssLoader,
            multiLanguageService,
            baseUrlsFactory,
            spaDisplayHelper,
            UserStatusService,
            User,
            UserAPI,
            ROUTE_REJECTION_REASONS,
            PebbleHttpInterceptor,
            UserAgentService
        ) {
            UserAgentService.init();
            app.apiUrl = window.config.getApiBaseUrl();
            app.flashUrl = app.apiUrl + "OAuth/OpenPebblePadPlus";
            app.atlasUrl = app.apiUrl + "OAuth/OpenAtlas";
            app.loginUrl = app.apiUrl + "SharedMenu/Forwarder?location=LoginService&action=JustOpenLocation";
            $rootScope.appName = "Plus";
            //a var that can be referenced from anywhere to specify the character limit for title text.
            $rootScope.titleLength = 255;
            $rootScope.loggedInUser = null; //Legacy variable...can't remove just yet!
            $rootScope.unlinkLogo = false; //Spamenu logo should be clickable by default.
            var focusResetPointer = document.getElementById("focus-reset-pointer");

            $rootScope.multiLanguageService = multiLanguageService;

            tracker.configure("https://<pebbleplus>/", {
                baseUrl: window.config.getMatomoBaseUrl(),
                siteId: window.config.getMatomoSiteId()
            });

            richTextConfig.licenseKey = window.config.getCKEditorLicenseKey();

            captchaConfig.siteKey = window.config.getGoogleCaptchaKey();

            //Spa Menu filters
            $rootScope.menuSegments = {
                menu: spaDisplayHelper.createSegment(true, ["IsExternal"]), //Menu visible based off user role.
                products: spaDisplayHelper.createSegment(true, ["IsExternal"]), //Products visible based off role.
                tools: spaDisplayHelper.createSegment(true)
            };

            $rootScope.feedbackOptions = {
                feedbackStatus: false
            };

            /* DO NOT REMOVE... DOESN'T MATTER HOW DODGY IT IS! - Ste2.0 */
            $window.interAssetPageChange = function (pageId) {
                $location.search("pageId", pageId);
                $rootScope.$apply();
            };
            /* DO NOT REMOVE... DOESN'T MATTER HOW DODGY IT IS!*/

            UserStatusService.cacheModal();

            $rootScope.$on("$routeChangeSuccess", onRouteChangeSuccess);
            $rootScope.$on("$routeChangeError", onRouteChangeError);

            //Test environment only
            $rootScope.$on("loginSuccess", function (event, data) {
                User.setDto(data.user);
                onUserDataUpdate(data.user);
            });

            $injector.invoke([
                "dataManagerService",
                function (dataManagerService) {
                    dataManagerService.cleanUpLocalStorage(); // run cleaning up local storage on app init
                }
            ]);

            function onRouteChangeSuccess(e, current, previous) {
                var userDto = User.getDto();
                if (userDto !== null && (!$rootScope.loggedInUser || $rootScope.loggedInUser.UserId !== userDto.UserId)) {
                    $rootScope.loggedInUser = userDto;
                    onUserDataUpdate(User.getDto());
                }

                if (current !== void 0) {
                    $rootScope.title = multiLanguageService.getString(current.title);
                    $rootScope.route = current.$$route.originalPath;
                    tracker.trackPageView("https://<pebbleplus>" + $route.current.trackerKey, "Plus");
                }

                focusResetPointer.focus();
            }

            function onRouteChangeError(e, current, previous, rejection) {
                if (!rejection) {
                    return;
                }

                switch (rejection.reason) {
                    case ROUTE_REJECTION_REASONS.AUTHENTICATION_FAILED:
                        PebbleHttpInterceptor.redirectToLogin();
                        break;
                    case ROUTE_REJECTION_REASONS.INVALID_TIMESLICE:
                        $location.path("viewer/" + rejection.assetId).replace();
                        break;
                    case ROUTE_REJECTION_REASONS.INTERNAL_USER_REQUIRED:
                        if (rejection.user && rejection.user.IsExternal) {
                            $location.url("/dashboard/externals").replace();
                        } else {
                            window.location.href = baseUrlsFactory.api_base_url + "SharedMenu/Forwarder?location=Portfolio&action=View&mainType=ExternalsPage";
                        }
                        break;
                    default:
                        if (rejection instanceof Error) {
                            $log.error(rejection);
                        }
                        $location.path("/");
                        break;
                }
            }

            //Save user data and update SpaMenu
            function onUserDataUpdate(userData) {
                if (userData !== null) {
                    $rootScope.userData = userData;

                    // KEEP SESSION ALIVE
                    $interval.cancel($rootScope.interval);
                    //Only when we have userdata do we start an keepAlive interval.
                    $rootScope.interval = $interval(keepUserLoggedIn, 600000);

                    if (userData.IsExternal) {
                        $rootScope.menuSegments.tools.filters = ["logOut"]; //Limit what tools an external user can see (HEAR Project).
                    }

                    $rootScope.$broadcast("menuUpdate", true, false, userData);

                    tracker.setConsent(userData.EnableTrackingAnalytics);

                    const customDimensions = [
                        { id: CustomDimension.Organisation, value: userData.Institution },
                        { id: CustomDimension.AppName, value: "Plus" }
                    ];

                    if (userData !== undefined && userData.UserStatus !== "" && EWC_STATUSES_CONSTANTS[userData.UserStatus] !== undefined) {
                        customDimensions.push({ id: CustomDimension.EWCStatus, value: EWC_STATUSES_CONSTANTS[userData.UserStatus] });
                    }

                    tracker.setCustomDimensions(customDimensions);
                }
            }

            //KEEP SESSION ALIVE
            function keepUserLoggedIn() {
                //This is used to temp store userdata, wipe it out each time to force services that use it to refresh their data.
                $rootScope.userData = undefined; //#future-refactor Removed the need for clearing and relying on it.
                UserAPI.keepUserLoggedIn().catch(() => $interval.cancel($rootScope.interval));
            }
        }
    ]);

    const i18nHelper = angular.injector(["i18n"]).get("i18nHelper");
    i18nHelper.setCacheBuster("v=" + app.version);

    // bootstrap app only after language file is fetched
    i18nHelper.getLanguageData().then(function (langData) {
        i18nManager.setDefaultLanguage("en-GB");
        i18nManager.setLanguage(langData.main.lang_attr);
        dictionaries = langData;
        angularAMD.bootstrap(app);
    });
});
