export const dialogAnimationKeyFrames = {
    contentIn: [{ opacity: 0 }, { opacity: 1 }],
    contentOut: [{ opacity: 1 }, { opacity: 0 }],
    fadeInDown: [
        { opacity: 0, transform: "translateY(-125px)" },
        { opacity: 1, transform: "translateY(-75px)" },
        { opacity: 1, transform: "translateY(-37px)" },
        { opacity: 1, transform: "translateY(0)" }
    ],
    fadeInUp: [
        { opacity: 0, transform: "translateY(75%)" },
        { opacity: 1, transform: "translateY(30%)" },
        { opacity: 1, transform: "translateY(15%)" },
        { opacity: 1, transform: "translateY(0)" }
    ],
    fadeOutDown: [
        { opacity: 1, transform: "translateY(0)" },
        { opacity: 0, transform: "translateY(125px)" }
    ],
    fadeOutUp: [
        { opacity: 1, transform: "translateY(0)" },
        { opacity: 0, transform: "translateY(-125px)" }
    ],
    sizeChange: (oldHeight: number, newHeight: number) => {
        return [{ height: `${oldHeight}px` }, { height: `${newHeight}px` }, { height: "auto" }];
    }
};
