import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { chevronIcon } from "@pjs/core-ui";
import template from "./tab-set.html";
import "../tabContent/tabContent.component";
import "../utilities/accessibilityKeyEventHelper.service";
import "../multiLanguageService/multiLanguageService";
import "../react2angular/icon";

class TabSet extends NgComponent {
    constructor($element, $timeout, $scope, accessibilityKeyEventHelper, multiLanguageService) {
        super();

        this._$element = $element;
        this._$timeout = $timeout;
        this._$scope = $scope;
        this._keyCodeMap = accessibilityKeyEventHelper.keyCodeMap;
        this._multiLanguageService = multiLanguageService;
        this._tabRowElement = this._$element[0].querySelector("[data-hook='tab-row']");
        this.tabs = [];
        this.icon = chevronIcon;
        this.addTabCount = 0;
        this.showScrollButtons = false;
        this.disableLeftScroll = false;
        this.disableRightScroll = false;

        this.buttonLabels = {
            left: this._multiLanguageService.getString("atlas_sidebar.scroll_left"),
            right: this._multiLanguageService.getString("atlas_sidebar.scroll_right")
        };

        this.observer = this._setupIntersectionObserver();

        $scope.$on("splitAreaSizes", () => {
            this._$scope.$applyAsync(() => {
                this._showScrollButtons();
            });
        });
    }

    $postLink() {
        if (typeof this.focusTabId === "string" && this._focusTabId !== "") {
            this._$timeout(() => {
                this._focusTab();
            });
        }

        this._$scope.$applyAsync(() => {
            this._showScrollButtons();
            this._setupObservers();
        });
    }

    $onChanges(changes) {
        if (typeof this.focusTabId === "string" && changes.focusTabId !== undefined && this._focusTabId !== "") {
            this._focusTab();
        }
    }

    $onDestroy() {
        this.observer.disconnect();
    }

    scroll(scrollRight) {
        const tabRow = this._tabRowElement;
        const buttons = tabRow.querySelectorAll("button");
        const tabRowRect = tabRow.getBoundingClientRect();

        for (const button of buttons) {
            const buttonRect = button.getBoundingClientRect();
            if (!scrollRight && buttonRect.left < tabRowRect.left && buttonRect.right >= tabRowRect.left) {
                const left = button === buttons[0] ? -tabRow.scrollLeft : Math.floor(buttonRect.left - tabRowRect.left);
                tabRow.scrollBy({ left: left });
                break;
            }
            if (scrollRight && buttonRect.left <= tabRowRect.right && buttonRect.right > tabRowRect.right) {
                const left = button === buttons[buttons.length - 1] ? tabRow.scrollWidth - (tabRow.scrollLeft + tabRow.clientWidth) : Math.ceil(buttonRect.right - tabRowRect.right);
                tabRow.scrollBy({ left: left });
                break;
            }
        }
    }

    getTabSet() {
        return this.tabs;
    }

    addTab(tabObject) {
        this.tabs.push(tabObject);
        this.addTabCount = 0;
        this._$scope.$applyAsync(() => {
            this.addTabCount++;
            if (this.addTabCount === 1) {
                this._showScrollButtons();
            }
        });
    }

    removeTab(tabObject) {
        const index = this.tabs.indexOf(tabObject);
        if (index !== -1) {
            this.tabs.splice(index, 1);
        }
    }

    clearTabSet() {
        this.tabs.length = 0;
    }

    setActiveTab(activeTab) {
        const index = this.tabs.indexOf(activeTab);
        if (index !== -1) {
            this.tabs.forEach((tab) => {
                tab.isActive = tab === activeTab;
            });
        }

        this.onTabChange({
            activeTab: activeTab
        });

        this._$timeout(() => {
            this._setupObservers();

            if (this.showScrollButtons === true && index !== -1) {
                // This is to get around a chromium bug relating to smooth scrolling 😔
                // https://bugs.chromium.org/p/chromium/issues/detail?id=1121151
                this._tabRowElement.style.scrollBehavior = "unset";

                this._$element[0].querySelector(`[data-hook="${activeTab.id}-tab"]`).scrollIntoView({
                    inline: "center",
                    block: "center"
                });

                this._tabRowElement.style.scrollBehavior = "smooth";
            }
        });
    }

    setFocusedTab(e) {
        if (e.keyCode === this._keyCodeMap.rightArrow || e.keyCode === this._keyCodeMap.leftArrow) {
            let selectedTabIndex = this.tabs.findIndex((tab) => `${tab.id}-tab` === e.target.id);
            let initialTabIndex = selectedTabIndex;
            let direction = e.keyCode === this._keyCodeMap.rightArrow ? 1 : -1;

            for (let i = 0; i < this.tabs.length; i++) {
                selectedTabIndex = selectedTabIndex + direction;
                if (selectedTabIndex >= this.tabs.length) {
                    selectedTabIndex = 0;
                }
                if (selectedTabIndex < 0) {
                    selectedTabIndex = this.tabs.length - 1;
                }
                if (initialTabIndex === selectedTabIndex) {
                    break;
                }

                const selectedTab = this.tabs[selectedTabIndex];
                if (!selectedTab.isDisabled) {
                    this._$element[0].querySelector(`#${selectedTab.id}-tab`).focus();
                    break;
                }
            }
        }
    }

    _focusTab() {
        this._$element[0].querySelector(`[data-hook='${this.focusTabId}-tab']`)?.focus();
    }

    _showScrollButtons() {
        // If the element width is a decimal funky things happen due to scrollWidth rounding up and clientWidth rounding down.
        // Offset added to normalise the values. 💩
        const roundingOffset = 1;
        this.showScrollButtons = this._tabRowElement.scrollWidth > this._tabRowElement.clientWidth + roundingOffset;
    }

    _setupIntersectionObserver() {
        return new IntersectionObserver(
            (payload) => {
                for (const entry of payload) {
                    const isIntersecting = entry.intersectionRatio === 1;
                    if (entry.target === this._leftEdgeButton && this.disableLeftScroll !== isIntersecting) {
                        this.disableLeftScroll = isIntersecting;
                        this._$scope.$applyAsync();
                    }
                    if (entry.target === this._rightEdgeButton && this.disableRightScroll !== isIntersecting) {
                        this.disableRightScroll = isIntersecting;
                        this._$scope.$applyAsync();
                    }
                }
            },
            {
                root: this._tabRowElement,
                threshold: 1
            }
        );
    }

    _setupObservers() {
        this.observer.disconnect();

        if (this.tabs.length > 0) {
            this._leftEdgeButton = this._$element[0].querySelector(`[data-hook='${this.tabs[0].id}-tab']`);
            this._rightEdgeButton = this._$element[0].querySelector(`[data-hook='${this.tabs[this.tabs.length - 1].id}-tab']`);

            this.observer.observe(this._leftEdgeButton);
            this.observer.observe(this._rightEdgeButton);
        }
    }
}

export const tabSet = {
    restrict: "E",
    transclude: true,
    template: template,
    controller: TabSet,
    bindings: {
        onTabChange: "&",
        focusTabId: "<?"
    }
};

TabSet.$inject = ["$element", "$timeout", "$scope", "accessibilityKeyEventHelper", "multiLanguageService"];
angularAMD.component("tabSet", tabSet);
