import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./scorecard-edit.html";
import "../../../../numberPicker/numberPicker";
import "../../../../utilities/debounce.service";
import "../../../../multiLanguageService/multiLanguageService";
import "../../../../spaMenu/assetInfoPanel/assetFeedback/helpers/SubmissionPermissionHelper";

class ScorecardEditComponent extends NgComponent {
    constructor(multiLanguageService, $scope, debounce, SubmissionPermissionHelper) {
        super();

        this.multiLanguageService = multiLanguageService;
        this._$scope = $scope;
        this._debounce = debounce;
        this._submissionPermissionHelper = SubmissionPermissionHelper;

        this.feedbackModel = { ...this.feedback };
    }

    $onInit() {
        this._$scope.$emit("feedbackInProgress", { id: this._$scope.$id, feedbackInProgress: true });

        if (this.data === undefined) {
            this.data = this._submissionPermissionHelper.getScorecardSettings();
        }

        this.numberPickerSettings = {
            decimalPlaces: 0,
            max: 100,
            min: 0,
            value: 0,
            eventId: "",
            ariaLabel: this.multiLanguageService.getString("sidebar.asset_feedback.labels.score"),
            hideClearBtn: true,
            onValueChange: null
        };

        this.scores = this.feedbackModel.Scores.map((score) => {
            const debouncer = this._debounce.fn(this.onValueChange, this, 300);
            const settings = [{ ...this.numberPickerSettings }];
            settings[0].value = score.Score;
            settings[0].onValueChange = debouncer.run.bind(debouncer);
            return { ...score, Settings: settings };
        });

        this._calculateTotal();
    }

    $onDestroy() {
        this._$scope.$emit("feedbackInProgress", { id: this._$scope.$id, feedbackInProgress: false });
    }

    onValueChange(value, scoreId) {
        const score = this.scores.find((x) => x.ScorecardTemplateComponentId === scoreId);
        score.Score = value;
        this._calculateTotal();
        this._updateFeedback();
    }

    onGradeChanged() {
        this._updateFeedback();
    }

    _calculateTotal() {
        this.feedbackModel.TotalScore = null;
        if (this.feedbackModel.Weighted === true) {
            if (this.scores.every((x) => x.Score !== "" && x.Score !== null)) {
                const calculatedScore = this.scores.reduce((total, score) => {
                    return total + (score.Weighting / 100) * score.Score;
                }, 0);
                this.feedbackModel.TotalScore = calculatedScore;
                this.totalScoreForView = this._formatScore(this.feedbackModel.TotalScore);
            }
        } else {
            const sumOfScores = this.scores.reduce((total, score) => {
                return total + Number(score.Score);
            }, 0);
            this.feedbackModel.TotalScore = sumOfScores;
            this.totalScoreForView = sumOfScores.toString();
        }

        if (this.feedbackModel.TotalScore === null) {
            this.totalScoreForView = "--";
        }
    }

    _formatScore(score) {
        return Number(score / 100).toLocaleString(undefined, { style: "percent" });
    }

    _updateFeedback() {
        if (this.onChange !== undefined) {
            this.onChange({
                Scores: this.scores,
                TotalScore: this.feedbackModel.TotalScore,
                Grade: this.feedbackModel.Grade
            });
        }
    }
}

var scorecardEditDefinition = {
    bindings: {
        feedback: "<",
        data: "<?",
        onChange: "<"
    },
    template: template,
    controller: ScorecardEditComponent
};

ScorecardEditComponent.$inject = ["multiLanguageService", "$scope", "debounce", "SubmissionPermissionHelper"];
angularAMD.component("scorecardEdit", scorecardEditDefinition);
export { scorecardEditDefinition as scorecardEdit };
