import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { ACTION_CONSTANTS } from "../../../constants/action.constants";
import "../../../feedback/feedbackUrlService/feedbackUrlService.service";
import "../../../utilities/iconHelper.service";
import "../../../multiLanguageService/multiLanguageService";
import template from "./feedback-template.html";

class feedbackTemplateComponent extends NgComponent {
    constructor(feedbackUrlService, $location, multiLanguageService, iconHelper) {
        super();

        this.ACTION_CONSTANTS = ACTION_CONSTANTS;
        this._feedbackUrlService = feedbackUrlService;
        this._$location = $location;
        this.multiLanguageService = multiLanguageService;
        this.iconHelper = iconHelper;

        this.ariaString = "";
    }

    $onChanges() {
        for (let i = 0; i < this.feedbackTemplates.length; i++) {
            this.feedbackTemplates[i].Url = this._feedbackUrlService.createFeedbackTemplateUrl(
                {
                    feedbackId: this.feedbackId,
                    assetId: this.feedbackTemplates[i].AssetId,
                    editable: this.mode !== this.ACTION_CONSTANTS.VIEW
                },
                this.mode === this.ACTION_CONSTANTS.ADD
            );

            if (this.mode === this.ACTION_CONSTANTS.ADD) {
                this._$location.url(this.feedbackTemplates[i].Url);
                this.onCancel();
            }

            this.ariaString = this.multiLanguageService.getString(`actions.a11y.${this.mode}`);
        }
    }

    removeTemplate(assetId) {
        for (let i = 0; i < this.feedbackTemplates.length; i++) {
            if (assetId === this.feedbackTemplates[i].AssetId) {
                this.feedbackTemplates.splice(i, 1);
                break;
            }
        }

        this.onEdit({
            templates: this.feedbackTemplates
        });
    }
}

export const feedbackTemplate = {
    bindings: {
        feedbackId: "<",
        feedbackTemplates: "<",
        mode: "<",
        onEdit: "&",
        onCancel: "&"
    },
    template: template,
    controller: feedbackTemplateComponent
};

feedbackTemplateComponent.$inject = ["feedbackUrlService", "$location", "multiLanguageService", "IconHelper"];
angularAMD.component("feedbackTemplate", feedbackTemplate);
