import { angularAMD } from "@pebblepad/amd";
import { HTML_INPUT } from "../constants/htmlInput.constants";
import "../utilities/urlService";

angularAMD.service("NodeAttributeModifier", NodeAttributeModifier);
NodeAttributeModifier.$inject = ["urlService"];

function NodeAttributeModifier(urlService) {
    //Public API
    //=============================================================================================================
    this.getAttributeDefaults = getAttributeDefaults;
    this.getNodeDefaults = getNodeDefaults;
    this.removeEmptyBackgroundColours = removeEmptyBackgroundColours;
    this.removeListStyles = removeListStyles;
    this.removeRelativeFontSizes = removeRelativeFontSizes;
    this.removePageAnchorLink = removePageAnchorLink;

    //Private variables
    //=============================================================================================================
    var prefixBlacklist = ["-webkit-", "-moz-", "-ms-", "mso-"];
    var prefixBlackListRegex = new RegExp(
        "(" +
            prefixBlacklist.reduce(function (prev, curr) {
                return prev + "|" + curr;
            }) +
            ")[a-z0-9-]+:\\s?[a-z0-9-\\(\\)#,!\\s]+;",
        "gi"
    );

    var stylesValueBlacklist = ["initial", "inherit"];
    var stylesValueBlacklistRegex = new RegExp(
        "[a-z0-9-]+:\\s?(" +
            stylesValueBlacklist.reduce(function (prev, curr) {
                return prev + "|" + curr;
            }) +
            ");",
        "gi"
    );

    //Private methods
    //=============================================================================================================
    function getAttributeDefaults() {
        return [sanitiseStyleAttribute, removeListStyles, removeRelativeFontSizes];
    }

    function getNodeDefaults() {
        return [removePageAnchorLink, removeHeadingOne];
    }

    function sanitiseStyleAttribute(node) {
        var style = node.getAttribute("style");
        if (style === null) {
            return;
        }

        node.setAttribute("style", style.replace(prefixBlackListRegex, "").replace(stylesValueBlacklistRegex, ""));

        var key = "";
        for (var i = 0, l = HTML_INPUT.STYLE_PROPERTY_BLACKLIST.length; i < l; i++) {
            key = HTML_INPUT.STYLE_PROPERTY_BLACKLIST[i];
            if (node.style[key]) {
                node.style[key] = "";
            }
        }
    }

    function removeEmptyBackgroundColours(node) {
        if (node.style.backgroundColor === "transparent") {
            node.style.backgroundColor = "";
        }
    }

    function removeRelativeFontSizes(node) {
        var fontSize = node.style.fontSize;
        if (/%|em/.test(fontSize)) {
            node.style.fontSize = "";
        }
    }

    function removeListStyles(node) {
        if (/^(li|ul|ol)$/i.test(node.nodeName)) {
            node.removeAttribute("style");
        }
    }

    function removeHeadingOne(node) {
        return node.nodeName === "H1" ? replaceNodeType(node, document.createElement("h2")) : node;
    }

    function removePageAnchorLink(node) {
        if (node.nodeName !== "A") {
            return node;
        }

        var href = node.getAttribute("href");
        return /^#/.test(href) && !urlService.isSupportedRoute(href) ? replaceNodeType(node, document.createElement("span")) : node;
    }

    function replaceNodeType(node, newNode) {
        while (node.firstChild) {
            // tslint:disable-next-line:no-unsafe-dom-insert-calls
            newNode.appendChild(node.firstChild);
        }
        // tslint:disable-next-line:no-unsafe-dom-insert-calls
        node.parentNode.replaceChild(newNode, node);
        return newNode;
    }
}
