import { filter, finalise, map, Observable } from "@pjs/observables";
import { Primitive } from "@pjs/utilities";
import { ItemSelectorAtPath } from "../patcher/types/ItemSelectorAtPath";
import { PatchPath } from "../patcher/types/PatchPath";
import { IOperationResult } from "../patcher/interfaces/IOperationResult";
import { PatchOperation } from "../../enums/PatchOperation";
import { ModelProxy } from "./types/ModelProxy";
import { IProxyOperations } from "./interfaces/IProxyOperations";
import { IProxyController } from "./interfaces/IProxyController";
import { DocumentPatchOperations } from "./types/DocumentPatchOperations";

export class ProxyController<T extends Record<string, any> | Array<any>> implements IProxyController<T> {
    public readonly proxy: ModelProxy<T>;

    private readonly _operations: IProxyOperations;
    private readonly _changes: Observable<DocumentPatchOperations>;

    private readonly _nodeSubscriptionCache: Map<string, Observable<any>> = new Map();
    private readonly _patchSubscriptionCache: Map<string, Observable<any>> = new Map();

    private readonly _callableTraps: Record<string, (...args: any) => any> = {
        insertAfter: this._insertAfter.bind(this),
        insertBefore: this._insertBefore.bind(this),
        remove: this._remove.bind(this),
        select: this._select.bind(this)
    };

    private readonly _propertyTraps: Record<string, any> = {
        items: this._items.bind(this),
        properties: this._properties.bind(this)
    };

    constructor(operations: IProxyOperations, changes: Observable<DocumentPatchOperations>) {
        this._operations = operations;
        this._changes = changes;
        this.proxy = this._createProxy([]);
    }

    private _handler(property: string, path: PatchPath): any {
        if (property === "subscribe" || property === "pipe") {
            return (...args: [any]) => this._getObservableForAllChangesAtPath(path)[property](...args);
        }

        if (this._propertyTraps[property] !== undefined) {
            return this._propertyTraps[property](path);
        }

        if (this._callableTraps[property] !== undefined) {
            return this._callableTraps[property].bind(this, path);
        }

        if (this._operations[property as keyof IProxyOperations] !== undefined) {
            return this._operations[property as keyof IProxyOperations].bind(this, path);
        }

        return this._createProxy([...path, property]);
    }

    private _createProxy(path: PatchPath): any {
        return new Proxy(path, {
            get: (_, property) => this._handler(property as string, path)
        });
    }

    private _items(path: PatchPath): Observable<any> {
        return this._getObservableForSpecificChangesAtPath(path);
    }

    private _select(path: PatchPath, selector: ItemSelectorAtPath<any>): void {
        return this._createProxy([...path, selector]);
    }

    private _properties(path: PatchPath): Observable<any> {
        return this._getObservableForSpecificChangesAtPath(path);
    }

    private _insertBefore(path: PatchPath, selection: Record<string, Primitive>, newValue: any): IOperationResult {
        return this._operations.insertBefore([...path, selection], newValue);
    }

    private _insertAfter(path: PatchPath, selection: Record<string, Primitive>, newValue: any): IOperationResult {
        return this._operations.insertAfter([...path, selection], newValue);
    }

    private _remove(path: PatchPath, selection: Record<string, Primitive>): IOperationResult {
        return this._operations.remove([...path, selection]);
    }

    private _pathItemsAreEqual(pathItem: PatchPath[0], changePathItem: PatchPath[0]): boolean {
        const isPathItemString = typeof pathItem === "string";
        const isChangePathItemString = typeof changePathItem === "string";

        if (isPathItemString && isChangePathItemString) {
            return pathItem === changePathItem;
        }

        if (!isPathItemString && !isChangePathItemString) {
            return Object.entries(pathItem as Record<string, Primitive>).every(([key, prop]) => prop === (changePathItem as Record<string, Primitive>)[key]);
        }

        return false;
    }

    private _getObservableForAllChangesAtPath(path: PatchPath): Observable<any> {
        const pathString = JSON.stringify(path);
        const cachedObservable = this._nodeSubscriptionCache.get(pathString);

        if (cachedObservable !== undefined) {
            return cachedObservable;
        }

        const observable = this._changes.pipe(
            filter((change) => {
                return path.every((pathItem, index) => this._pathItemsAreEqual(pathItem, change.path[index]));
            }),
            map(() => this._operations.get(path)),
            finalise(() => this._nodeSubscriptionCache.delete(pathString))
        );

        this._nodeSubscriptionCache.set(pathString, observable);
        return observable;
    }

    private _getObservableForSpecificChangesAtPath(path: PatchPath): Observable<DocumentPatchOperations> {
        const pathString = JSON.stringify(path);
        const cachedObservable = this._patchSubscriptionCache.get(pathString);

        if (cachedObservable !== undefined) {
            return cachedObservable;
        }
        const observable = this._changes.pipe(
            filter((change) => this._doesPatchMatchPath(change, path)),
            finalise(() => this._patchSubscriptionCache.delete(pathString))
        );

        this._patchSubscriptionCache.set(pathString, observable);
        return observable;
    }

    private _doesPatchMatchPath(patch: DocumentPatchOperations, path: PatchPath): boolean {
        const patchPathLength =
            patch.op === PatchOperation.InsertBefore || patch.op === PatchOperation.InsertAfter || patch.op === PatchOperation.Remove
                ? patch.path.length - 1
                : patch.path.length;
        return patchPathLength === path.length && path.every((pathItem, index) => this._pathItemsAreEqual(pathItem, path[index]));
    }
}
