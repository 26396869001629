import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./sidebar-elements-view-section.html";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../../../constants/mapToStandards.constants";
import "../../../../mapToStandards/mapToStandardsWrapper/standardsRatingSlider/standardsRatingSlider.component";
import "../../../../mapToStandards/mapToStandardsWrapper/standardsRatingInfo/standardsRatingInfo.component";
import "../../../../multiLanguageService/multiLanguageService";
import "../../../../userStatusPermissionsService/userStatusPermissions.service";
import "../../../../utilities/scrollToElement.service";

class SidebarElementsViewSection extends NgComponent {
    constructor($location, multiLanguageService, standardsOverviewService, userStatusPermissions, scrollToElementService) {
        super();

        this.MAP_TO_STANDARDS_CONSTANTS = MAP_TO_STANDARDS_CONSTANTS;
        this.$location = $location;

        this.standardsOverviewService = standardsOverviewService;
        this.multiLanguageService = multiLanguageService;
        this.userStatusPermissions = userStatusPermissions;
        this._scrollToElementService = scrollToElementService;

        this.aggregateText = this.multiLanguageService.getString("labels.viewer.standards_overview.aggregate_text");
        this.thumbDimensions = {
            thumbHeight: MAP_TO_STANDARDS_CONSTANTS.RATING_SLIDER.DEFAULT_VALUES.THUMB_HEIGHT,
            thumbWidth: MAP_TO_STANDARDS_CONSTANTS.RATING_SLIDER.DEFAULT_VALUES.THUMB_WIDTH
        };
    }

    $onInit() {
        this.userStatusPermissions
            .canSelfAssess(this.assetId)
            .then((response) => {
                this.canShowRating = response;
            })
            .catch(() => {
                this.canShowRating = false;
            });
    }

    jumpToElement(pageId, elementId) {
        this._scrollToElementService.goToElementWithPageId(`Element${elementId}`, pageId);
        this.$location.search("pageId", pageId);
        this.standardsOverviewService.toggleSidebar();
    }
}

export const sidebarElementsViewSection = {
    bindings: {
        standards: "<",
        pageId: "@",
        assetId: "<"
    },
    template: template,
    controller: SidebarElementsViewSection
};

SidebarElementsViewSection.$inject = ["$location", "multiLanguageService", "standardsOverviewService", "userStatusPermissions", "scrollToElementService"];
angularAMD.component("sidebarElementsViewSection", sidebarElementsViewSection);
