import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { tracker } from "@pjs/analytics";
import { ButtonClass, listIcon, gridIcon, AriaMenuStatus } from "@pjs/core-ui";
import { noop } from "@pjs/utilities";
import { SELECTOR } from "../../constants/selector.constants";
import { withDigest } from "../../react2angular/utilities/WithDigest.function";
import "../../spaMenu/selector/services/spaSelectorService";
import "../../react2angular/buttonComponent";
import "../../react2angular/input";
import "../../react2angular/scopedSearch";
import "../../fileUpload/directives/fileUpload";
import "../../itemSelector/itemSelector.component";
import "../../react2angular/StyledLoadingSpinner";
import "../../stickyElementCoordinator/stickyItem.directive";
import template from "./templates/linker-selector.html";
import { itemSelectorFilters } from "../../itemSelector/itemSelectorFilters";

class LinkerSelectorController extends NgComponent {
    constructor($scope, $timeout, multiLanguageService, spaSelectorService) {
        super();

        this._$scope = $scope;
        this._$timeout = $timeout;
        this.multiLanguage = multiLanguageService;
        this._selectorApi = spaSelectorService;
        this._dataRequest = null;

        this.controls = {
            cancel: {
                action: this.onCancel,
                style: ButtonClass.Outlined,
                text: this.multiLanguage.getString("buttons.cancel")
            },
            search: {
                autoFocus: true,
                filterModel: { activeItemIndex: 0, type: AriaMenuStatus.Closed, isOpen: false },
                searchTerm: this.searchTerm,
                filterOptions: [
                    {
                        value: multiLanguageService.getString(itemSelectorFilters.all.label),
                        filter: itemSelectorFilters.all,
                        fullLabel: multiLanguageService.getString(itemSelectorFilters.all.fullLabel)
                    },
                    {
                        value: multiLanguageService.getString(itemSelectorFilters.tag.label),
                        filter: itemSelectorFilters.tag,
                        fullLabel: multiLanguageService.getString(itemSelectorFilters.tag.fullLabel)
                    },
                    {
                        value: multiLanguageService.getString(itemSelectorFilters.title.label),
                        filter: itemSelectorFilters.title,
                        fullLabel: multiLanguageService.getString(itemSelectorFilters.title.fullLabel)
                    }
                ],
                change: withDigest((searchTerm) => (this.controls.search.searchTerm = searchTerm), $scope),
                onFilterChange: withDigest((filterModel) => (this.controls.search.filterModel = filterModel), $scope),
                onSearch: this._runSearch.bind(this),
                scopedSearchLabel: multiLanguageService.getString("search.a11y.select"),
                searchButtonLabel: multiLanguageService.getString("search.a11y.search_button"),
                searchInputLabel: multiLanguageService.getString("search.a11y.search_input"),
                clearSearchLabel: multiLanguageService.getString("search.a11y.clear_search")
            }
        };

        this.loadingMessage = this.multiLanguage.getString("pebble_editor.plugins.linker.modal.a11y_loading");
        this.goBackLabel = this.multiLanguage.getString("pebble_editor.plugins.linker.modal.back_to_link");
        this.selectorData = null;
        this.disableExtraInfo = true;
        this.selectorMode = "Single";
        this.views = [
            { id: "list", icon: listIcon, ariaLabel: multiLanguageService.getString("accessibility.spa_menu.selector.listView") },
            { id: "gallery", icon: gridIcon, ariaLabel: multiLanguageService.getString("accessibility.spa_menu.selector.galleryView") }
        ];
        this.uploader = { selectorId: "link-modal-selector" };
        this.fileUploadEnabled = false;
        this.enableFileUpload = this._enableFileUpload.bind(this);
        this.noop = noop;
        this._$scope.$on("updateItemsInSelector", (_, fileUploadResult) => this._onFileUpload(fileUploadResult));
    }

    $onInit() {
        this.load();
    }

    $onDestroy() {
        if (this._dataRequest !== null) {
            this._dataRequest.abort();
        }
    }

    goBack() {
        if (!this.fileUploadEnabled) {
            this.onBack();
            return;
        }

        this.fileUploadEnabled = false;
        this.goBackLabel = this.multiLanguage.getString("pebble_editor.plugins.linker.modal.back_to_link");
    }

    _enableFileUpload() {
        this.fileUploadEnabled = true;
        this.goBackLabel = this.multiLanguage.getString("pebble_editor.plugins.linker.modal.back_to_selector");
    }

    _onFileUpload(fileUploadResult) {
        this.fileUploadEnabled = false;
        this.goBackLabel = this.multiLanguage.getString("pebble_editor.plugins.linker.modal.back_to_link");

        if (fileUploadResult.Items.length === 1) {
            tracker.trackEvent("CKE5 Linker modal", "Uploaded file", `Single`);
            this.onSelect(fileUploadResult.Items[0]);
            return;
        }

        tracker.trackEvent("CKE5 Linker modal", "Uploaded file", `Multiple`);
        this._updateData(fileUploadResult.Items);
    }

    load() {
        this._dataRequest = this._selectorApi
            .getDefaultItemsForSelector(SELECTOR.SELECT_TYPE.ASSET_AND_RESOURCE, [], [], this.excludedAssets, [], [], ["ByMe", "WithMe"])
            .then((data) => {
                this.selectorData = data;
                if (this.searchTerm) {
                    this._runSearch(this.searchTerm, this.controls.search.filterOptions[0]);
                }
            })
            .catch(() => {
                this.selectorData = { Description: this.multiLanguage.getString("labels.selector.asset_and_resource", { count: 0 }), Items: [] };
            })
            .finally(() => {
                this.loadingMessage = this.multiLanguage.getString("pebble_editor.plugins.linker.modal.a11y_loaded");
            });
    }

    _updateData(newItems) {
        this.selectorData.Items = newItems.concat(this.selectorData.Items);
        this.selectorData.Description = this.multiLanguage.getString("labels.selector.asset_and_resource", { count: this.selectorData.Items.length });
    }

    _runSearch(searchTerm, searchScope) {
        const trimmedSearchTerm = searchTerm.trim();
        this._dataRequest.then(() => {
            if (trimmedSearchTerm === "") {
                this._$scope.$broadcast("filterItemSelector");
                return;
            }
            this._$timeout(() => this._$scope.$broadcast("filterItemSelector", { searchTerm: trimmedSearchTerm, filter: searchScope.filter }));
        });
    }
}

LinkerSelectorController.$inject = ["$scope", "$timeout", "multiLanguageService", "spaSelectorService"];

const linkerSelectorDefinition = {
    bindings: {
        excludedAssets: "<",
        onBack: "<",
        onCancel: "<",
        onSelect: "<",
        searchTerm: "<"
    },
    template: template,
    controller: LinkerSelectorController
};

angularAMD.component("linkerSelector", linkerSelectorDefinition);
