import { Keys } from "../../enums/Keys";
import { findFirstInteractiveElement } from "../find-first-interactive-element/FindFirstInteractiveElement.function";
import { InteractiveElementsList } from "../interactive-elements-list/InteractiveElementsList";
import { IFocusTrapper } from "./interfaces/IFocusTrapper";

export class FocusTrapper implements IFocusTrapper {
    private static readonly _problemElementsSelector: string =
        "iframe:not([aria-hidden='true']), [type=number]:not([aria-hidden='true']), [type=range]:not([aria-hidden='true']), audio:not([aria-hidden='true']), q:not([aria-hidden='true'])";

    private readonly _containers: Array<HTMLElement> = [];
    private readonly _onFocusBound: (event: FocusEvent) => void = this._onFocus.bind(this);
    private readonly _onKeyDownBound: (event: KeyboardEvent) => void = this._onKeyDown.bind(this);
    private readonly _hostProblemElementsCache: Map<Element, Set<Element>> = new Map();

    public trap(container: HTMLElement): () => void {
        if (this._containers.length === 0) {
            this._addListeners();
        }

        this._containers.push(container);
        this._hideProblemElements();
        return () => this._removeTrap(container);
    }

    private _removeTrap(container: HTMLElement): void {
        const index = this._containers.indexOf(container);
        if (index === -1) {
            return;
        }

        const wasActiveTrap = container === this._containers[this._containers.length - 1];
        this._containers.splice(index, 1);

        if (!container.isConnected) {
            this._hostProblemElementsCache.delete(container);
        }

        if (this._containers.length > 0 && wasActiveTrap) {
            this._showCurrentTrapsProblemElements();
            return;
        }

        if (this._containers.length === 0) {
            this._removeListeners();
            this._showAllProblemElementsCached();
            this._hostProblemElementsCache.clear();
        }
    }

    private _showCurrentTrapsProblemElements(): void {
        const container = this._containers[this._containers.length - 1];
        const problemElements = this._hostProblemElementsCache.get(container);
        if (problemElements === undefined) {
            return;
        }
        for (const element of problemElements) {
            element.removeAttribute("aria-hidden");
        }
    }

    private _showAllProblemElementsCached(): void {
        for (const elements of this._hostProblemElementsCache.values()) {
            for (const element of elements) {
                element.removeAttribute("aria-hidden");
            }
        }
    }

    private _addListeners(): void {
        document.addEventListener("focus", this._onFocusBound, true);
        // eslint-disable-next-line @pebblepad/no-global-keydown-listeners
        document.addEventListener("keydown", this._onKeyDownBound);
    }

    private _removeListeners(): void {
        document.removeEventListener("focus", this._onFocusBound, true);
        document.removeEventListener("keydown", this._onKeyDownBound);
    }

    private _onFocus(event: FocusEvent): void {
        const container = this._containers[this._containers.length - 1];
        if (container.contains(event.target as HTMLElement)) {
            return;
        }
        const nextElement = findFirstInteractiveElement(container);
        if (nextElement !== null) {
            nextElement.focus();
        }
    }

    private _onKeyDown(event: KeyboardEvent): void {
        const container = this._containers[this._containers.length - 1];

        if (!container.contains(event.target as HTMLElement)) {
            event.preventDefault();
            const nextElement = findFirstInteractiveElement(container);
            if (nextElement !== null) {
                nextElement.focus();
            }
            return;
        }

        if (event.key !== Keys.Tab) {
            return;
        }

        const interactiveElements = new InteractiveElementsList(container);

        if (!event.shiftKey && event.target === interactiveElements.last()) {
            event.preventDefault();
            const firstElement = interactiveElements.first();
            if (firstElement !== null) {
                firstElement.focus();
            }
            return;
        }

        if (event.shiftKey && (event.target === container || event.target === interactiveElements.first())) {
            event.preventDefault();
            const lastElement = interactiveElements.last();
            if (lastElement !== null) {
                lastElement.focus();
            }
            return;
        }
    }

    private _hideProblemElements(): void {
        // See this ADR for why this function is needed
        // https://dev.azure.com/PebblePad/PebblePad.Core/_git/PebblePad.ADRs?path=/decisions/modals/20240219_%5Bmodals%5D_resolving-dialog-content-leaks.md&_a=preview

        const lastActiveContainer = this._containers[this._containers.length - 2];
        const activeContainer = this._containers[this._containers.length - 1];
        for (const element of document.querySelectorAll(FocusTrapper._problemElementsSelector)) {
            if (activeContainer.contains(element)) {
                continue;
            }
            const hostElement = lastActiveContainer?.contains(element) === true ? lastActiveContainer : document.body;
            element.setAttribute("aria-hidden", "true");
            const hostElements = this._hostProblemElementsCache.get(hostElement) ?? new Set();
            hostElements.add(element);
            this._hostProblemElementsCache.set(hostElement, hostElements);
        }
    }
}
