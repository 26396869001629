import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { AbortablePromiseSet } from "../abortablePromises/AbortablePromiseSet";
import { FEEDBACK_ERRORS } from "../constants/feedbackErrors.constants";
import "../feedback/feedbackDataService/feedbackDataService.service";
import "../feedbackList/feedbackList.component";
import "../messageAnnouncer/messageAnnouncer.directive";
import "../loadingSpinner/loadingSpinner";
import "../multiLanguageService/multiLanguageService";
import template from "./feedback-list-wrapper.html";

class FeedbackListWrapperComponent extends NgComponent {
    constructor(feedbackDataService, multiLanguageService) {
        super();

        this.loadingFeedback = true;
        this.errorMessage = "";
        this.loadedMessage = "";

        this._feedbackDataService = feedbackDataService;
        this.multiLanguageService = multiLanguageService;
        this._abortablePromiseSet = new AbortablePromiseSet();
    }

    $onInit() {
        this.loadedMessage = this.multiLanguageService.getString("sidebar.asset_feedback.aria.feedback_loading");

        const createOrUpdateObservableRequest = this._feedbackDataService
            .createOrUpdateObservable(this.assetId, this.anchorId)
            .catch((error) => {
                if (error.data !== undefined) {
                    if (error.data.Error !== undefined) {
                        this._handleError(error.data.Error);
                    } else {
                        this._handleError(error.data);
                    }
                } else {
                    this._handleError(error);
                }
            })
            .finally(() => {
                this.loadingFeedback = false;
            });

        this._abortablePromiseSet.add(createOrUpdateObservableRequest);
    }

    $onDestroy() {
        this._abortablePromiseSet.abortAll();
    }

    _handleError(error) {
        switch (error) {
            case FEEDBACK_ERRORS.NO_PERMISSION_ERROR: {
                this.errorMessage = this.multiLanguageService.getString("sidebar.asset_feedback.messages.no_permission");
                break;
            }
            default: {
                this.errorMessage = this.multiLanguageService.getString("sidebar.asset_feedback.messages.cant_load_feedback");
            }
        }

        this.loadedMessage = this.errorMessage;
    }
}

const feedbackListWrapperDefinition = {
    bindings: {
        assetId: "<",
        anchorId: "<?",
        isBlockFeedbackListView: "<?"
    },
    template: template,
    controller: FeedbackListWrapperComponent
};

FeedbackListWrapperComponent.$inject = ["feedbackDataService", "multiLanguageService"];
angularAMD.component("feedbackListWrapper", feedbackListWrapperDefinition);
export { feedbackListWrapperDefinition as feedbackListWrapper };
