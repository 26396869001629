import { ITrackedModelDocument, TrackedModelDocument } from "@pjs/storage";
import { BehaviorSubject, filter, Observable } from "@pjs/observables";
import { IAccountSettingsStore } from "../interfaces/IAccountSettingsStore";
import { IAccountSettingsModel } from "../interfaces/IAccountSettingsModel";
import { AccountSettingsException } from "../errors/AccountSettingsException";
import { Errors } from "../enums/Errors";

export class AccountSettingsStore implements IAccountSettingsStore {
    private _documentReady: BehaviorSubject<ITrackedModelDocument<IAccountSettingsModel> | null>;

    constructor() {
        this._documentReady = new BehaviorSubject<ITrackedModelDocument<IAccountSettingsModel> | null>(null);
    }

    public set(model: IAccountSettingsModel): void {
        this._destroyDocument();
        this._documentReady.next(new TrackedModelDocument(model));
    }

    public get(): ITrackedModelDocument<IAccountSettingsModel> {
        const document = this._documentReady.getValue();
        if (document === null) {
            throw new AccountSettingsException(Errors.DocumentNotFoundException, "Must call 'Set' before 'Get'");
        }

        return document;
    }

    public observeSettings(): Observable<ITrackedModelDocument<IAccountSettingsModel>> {
        return this._documentReady.asObservable().pipe(filter((document): document is ITrackedModelDocument<IAccountSettingsModel> => document !== null));
    }

    public clear(): void {
        this._destroyDocument();
        this._documentReady.complete();
        this._documentReady = new BehaviorSubject<ITrackedModelDocument<IAccountSettingsModel> | null>(null);
    }

    private _destroyDocument(): void {
        const document = this._documentReady.getValue();
        if (document === null) {
            return;
        }

        document.destroy();
    }
}
