import { Observable, Subject } from "@pjs/observables";
import { ModelProxy } from "../proxy/types/ModelProxy";
import { IPatcher } from "../patcher/interfaces/IPatcher";
import { Patcher } from "../patcher/Patcher";
import { DocumentPatchOperations } from "../proxy/types/DocumentPatchOperations";
import { IProxyController } from "../proxy/interfaces/IProxyController";
import { ProxyController } from "../proxy/Proxy";
import { IProxyOperations } from "../proxy/interfaces/IProxyOperations";
import { PatchPath } from "../patcher/types/PatchPath";
import { BasePatcherModel } from "../patcher/types/BasePatcherModel";
import { IOperationResult } from "../patcher/interfaces/IOperationResult";
import { traverseObject } from "../traverse-object/TraverseObject.function";
import { PatchOperation } from "../../enums/PatchOperation";
import { IModelDocument } from "./interfaces/IModelDocument";

export class ModelDocument<T extends BasePatcherModel> implements IModelDocument<T> {
    public readonly changeStream: Observable<DocumentPatchOperations>;
    public readonly model: ModelProxy<T>;

    private readonly _proxyController: IProxyController<T>;
    private readonly _changesSubject: Subject<DocumentPatchOperations>;
    private readonly _patcher: IPatcher<T>;

    constructor(model: T) {
        this._patcher = new Patcher(model);
        this._changesSubject = new Subject<DocumentPatchOperations>();
        this.changeStream = this._changesSubject.asObservable();

        this._proxyController = new ProxyController<T>(this._getProxyOperations(), this.changeStream);
        this.model = this._proxyController.proxy;
    }

    public destroy(): void {
        this._changesSubject.complete();
    }

    protected _patch(patch: DocumentPatchOperations): IOperationResult {
        const patchResult = this._patcher.patch(patch);

        if (patchResult) {
            this._changesSubject.next(patch);

            return this._createOperationReceipt(patch.id);
        }

        return this._createErroredOperationReceipt(patch);
    }

    private _getProxyOperations(): IProxyOperations {
        return {
            add: <TModel>(path: PatchPath, value: TModel) => this._patch({ id: Symbol(), op: PatchOperation.Add, path: path, value: value }),
            get: this._get.bind(this),
            insertAfter: <TModel>(path: PatchPath, value: TModel) => this._patch({ id: Symbol(), op: PatchOperation.InsertAfter, path: path, value: value }),
            insertBefore: <TModel>(path: PatchPath, value: TModel) => this._patch({ id: Symbol(), op: PatchOperation.InsertBefore, path: path, value: value }),
            remove: (path: PatchPath) => this._patch({ id: Symbol(), op: PatchOperation.Remove, path: path }),
            update: <TModel extends Record<any, any>>(path: PatchPath, value: TModel) =>
                this._patch({ id: Symbol(), op: PatchOperation.Update, path: path, value: value })
        };
    }

    private _get<TModel>(path: PatchPath): TModel | null {
        const modelAtPath = traverseObject(path, this._patcher.getModel());
        return modelAtPath !== null ? modelAtPath.node : modelAtPath;
    }

    private _generateErrorMessage(path: PatchPath, operation: string): string {
        return `Error - attempting ${operation} operation on path: ${JSON.stringify(path)}`;
    }

    private _createErroredOperationReceipt(patch: DocumentPatchOperations): IOperationResult {
        return { error: this._generateErrorMessage(patch.path, patch.op), patchId: patch.id };
    }

    private _createOperationReceipt(patchId: symbol): IOperationResult {
        return { error: null, patchId: patchId };
    }
}
