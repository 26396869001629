import { NgComponent } from "@pebblepad/angular/ngComponent";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../../constants/mapToStandards.constants";
import { angularAMD } from "@pebblepad/amd";
import template from "./standards-rating-slider.html";
import "../../../builder/dataManager/helperService";
import "../../../react2angular/rangeSlider";

class StandardsRatingSlider extends NgComponent {
    constructor($scope, helperService, multiLanguageService) {
        super();

        this.$scope = $scope;
        this.helperService = helperService;
        this.multiLanguageService = multiLanguageService;
        this.defaultRatingValue = MAP_TO_STANDARDS_CONSTANTS.RATING_SLIDER.DEFAULT_VALUES.RATING_VALUE;
        this.defaultProgressColour = MAP_TO_STANDARDS_CONSTANTS.RATING_SLIDER.DEFAULT_VALUES.PROGRESS_COLOUR;
        this.ratingValue = this._setRating(this.selectedStandard.Rating);
        this.ticks = this._setTicks(this.standard.Ticks);
        this._setProgressColour();
        this.onChange = this._updateRatingValue.bind(this);
    }

    $onChanges(changes) {
        if (changes.selectedStandard !== undefined) {
            this.ratingValue = this._setRating(changes.selectedStandard.currentValue.Rating);
        }

        if (changes.standard !== undefined) {
            this.ticks = this._setTicks(changes.standard.currentValue.Ticks);
        }
    }

    _updateRatingValue(newValue) {
        this.updateRatingValues(this.standard.uniqueId, newValue);
        this.ratingValue = newValue;
        this.$scope.$applyAsync();
    }

    _setRating(rating) {
        return rating !== undefined && rating !== null ? rating : this.defaultRatingValue;
    }

    _setTicks(ticks) {
        return ticks !== undefined && ticks !== null ? ticks : this._generateTicks();
    }

    _setProgressColour() {
        if (this.progressColour === undefined || this.progressColour === null) {
            this.progressColour = this.defaultProgressColour;
        }
    }

    _generateTicks() {
        if (this.standard.RatingLabels === null || this.standard.RatingLabels === undefined) {
            return [];
        }

        return this.standard.RatingLabels.map((label) => {
            return {
                id: this.helperService.guid(),
                placement: label.Position,
                tooltip: label.Label.toUpperCase(),
                label: label.ShortLabel.toUpperCase()
            };
        });
    }
}

export const standardsRatingSlider = {
    template: template,
    controller: StandardsRatingSlider,
    bindings: {
        selectedStandard: "<",
        standard: "<",
        disabled: "<",
        updateRatingValues: "<",
        progressColour: "<?",
        max: "<",
        min: "<",
        thumbDimensions: "<"
    }
};

StandardsRatingSlider.$inject = ["$scope", "helperService", "multiLanguageService"];
angularAMD.component("standardsRatingSlider", standardsRatingSlider);
