import { DocumentPatchOperations, PatchOperation } from "@pjs/storage";
import { Immutable, isPlainObject } from "@pjs/utilities";
import { ISettingSaveItem } from "../interfaces/settingItems/ISettingSaveItem";
import { BackendSettings } from "../enums/BackendSettings";
import { SettingPatchPaths } from "../enums/SettingPatchPaths";

const settingPatchMap: { [key in SettingPatchPaths]: BackendSettings } = {
    [SettingPatchPaths.EmbedConsent]: BackendSettings.EmbedConsent,
    [SettingPatchPaths.LanguageChoice]: BackendSettings.LanguageChoice,
    [SettingPatchPaths.PageSaving]: BackendSettings.PageSaving
};

export const patchToRequestBodyMapper = (patches: Immutable<Array<DocumentPatchOperations>>): ReadonlyArray<ISettingSaveItem> => {
    const patchMap = new Map<BackendSettings, string>();

    for (const patch of patches) {
        if (patch.op !== PatchOperation.Update) {
            continue;
        }

        const pathKey = patch.path.join(".");
        if (!(pathKey in settingPatchMap)) {
            continue;
        }

        const value = patch.value.value;
        if (value === undefined || isPlainObject(value)) {
            continue;
        }

        const name = settingPatchMap[pathKey as SettingPatchPaths];
        patchMap.set(name, (value as string | boolean).toString());
    }

    return Array.from(patchMap, ([name, setting]) => ({
        name,
        value: setting
    }));
};
