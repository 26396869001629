import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../../constants/mapToStandards.constants";
import { ASSET_CONSTANTS } from "../../../constants/asset.constants";
import template from "./templates/sidebar-mapping-wrapper.html";
import "../../../formComponents/StandardsElement/standardsInformation.service";
import "../../../messageAnnouncer/messageAnnouncer.directive";
import "../../../multiLanguageService/multiLanguageService";
import "../../../user/user.service";
import "../../../userStatusPermissionsService/userStatusPermissions.service";
import "./sidebarMapping.service";
import "./sidebarMapping";
import "./capabilityMapping/capabilityMapping.component";

class SidebarMappingWrapperComponent extends NgComponent {
    constructor($routeParams, $location, sidebarMappingService, standardsInformation, urlService, multiLanguageService, User, userStatusPermissions) {
        super();

        this.$location = $location;
        this._userStatusPermissions = userStatusPermissions;
        this.sidebarMappingService = sidebarMappingService;
        this.standardsInformation = standardsInformation;
        this.multiLanguageService = multiLanguageService;
        this.urlService = urlService;
        this.announcerMessage = "";
        this.submissionId = $routeParams.submissionId;
        this.sidebarMessage = "";
        this.standards = null;
        this.submissionInfo = null;
        this.loaded = false;
        this.allowMappingBtn = false;
        this.mappingBtn = "";
        this.mmlEnabled = User.getDto().EnableMapMyLearning;
        this.mappingAllowed = true;
    }

    $onInit() {
        if (!this.userCanViewMappings) {
            this.sidebarMessage = this.sidebarMappingService.returnSidebarMessage(this.standards, this.userCanViewMappings, false);
            this.announcerMessage = this.sidebarMessage;
            this.loaded = true;
            return;
        }

        this.announcerMessage = this.multiLanguageService.getString("asset_summary.mapping.loading");
        this.sidebarMappingService
            .getMappingInfo(this.assetId, this.submissionId)
            .then((response) => {
                if (this.mmlEnabled) {
                    if (response.status === ASSET_CONSTANTS.PERMISSIONS.NOPERMISSION) {
                        this.standards = [];
                        this.sidebarMessage = this.sidebarMappingService.returnSidebarMessage(this.standards, false, false);
                        this.mappingAllowed = false;
                        return false;
                    }

                    if (response.status === ASSET_CONSTANTS.ASSET_OPTIONS.IS_MAPPABLE) {
                        this.standards = [];
                        this.sidebarMessage = this.sidebarMappingService.returnSidebarMessage(this.standards, this.userCanViewMappings, true);
                        this.mappingAllowed = false;
                        return false;
                    }

                    this.standards = response.standards;
                    return true;
                }

                this.submissionInfo = response.submissions;
                this.standards = response.standards;
                return this._userStatusPermissions.canMap(this.assetId);
            })
            .then((userCanMap) => {
                const mappingApproved = this.standards[0] !== undefined ? this.standards[0].Status === MAP_TO_STANDARDS_CONSTANTS.STATUS.APPROVED : false;
                this.allowMappingBtn = !mappingApproved && userCanMap;

                this.mappingBtn =
                    this.standards.length > 0 ? this.multiLanguageService.getString("asset_summary.mapping.edit_mapping") : this.multiLanguageService.getString("asset_summary.mapping.create_mapping");
            })
            .catch(() => {
                this.sidebarMessage = this.sidebarMappingService.returnSidebarMessage(this.standards, this.userCanViewMappings, false);
            })
            .finally(() => {
                if (this.sidebarMessage === "") {
                    this.sidebarMessage = this.sidebarMappingService.returnSidebarMessage(this.standards, this.userCanViewMappings, false);
                }

                this.announcerMessage =
                    this.standards !== null && this.standards.length > 0
                        ? this.multiLanguageService.getString("asset_summary.mapping.a11y.total", { count: this.standards.length })
                        : this.sidebarMessage;

                this.loaded = true;
            });
    }

    openStandardsSidePanel() {
        this.standardsInformation.setStandardsData([], "", [], this.assetId, "", "", false, false);
        this.standardsInformation.setNavigationalUrl(this.assetId, this.$location.url());
        const mappingUrl = this.urlService.configureMappingUrl(this.assetId);
        this.$location.url(mappingUrl);
    }
}

export const sidebarMappingWrapper = {
    bindings: {
        assetId: "@",
        userCanViewMappings: "<"
    },
    template: template,
    controller: SidebarMappingWrapperComponent
};

SidebarMappingWrapperComponent.$inject = ["$routeParams", "$location", "sidebarMappingService", "standardsInformation", "urlService", "multiLanguageService", "User", "userStatusPermissions"];
angularAMD.component("sidebarMappingWrapper", sidebarMappingWrapper);
