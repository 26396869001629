import { DocumentPatchOperations } from "../proxy/types/DocumentPatchOperations";
import { IOperationResult } from "../patcher/interfaces/IOperationResult";
import { ModelDocument } from "../model-document/ModelDocument";
import { BasePatcherModel } from "../patcher/types/BasePatcherModel";

export class PatchableModelDocument<T extends BasePatcherModel> extends ModelDocument<T> {
    public patch(patch: DocumentPatchOperations): IOperationResult {
        return this._patch(patch);
    }
}
