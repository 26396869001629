import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { AriaMenuStatus, warningNotice } from "@pjs/core-ui";
import "../../../../feedbackCommentsEditor/feedbackCommentsEditor";
import "../../../../multiLanguageService/multiLanguageService";
import "../../../../react2angular/htmlNotice";
import "../../../../react2angular/approvalSelect";
import template from "./approval-edit.html";

class ApprovalEditComponent extends NgComponent {
    constructor(multiLanguageService, $scope) {
        super();

        this.multiLanguageService = multiLanguageService;
        this.selectedOption = null;
        this.initialSelectModel = {
            activeItemIndex: 0,
            isOpen: false,
            type: AriaMenuStatus.Closed
        };
        this.labelId = "approval-edit-status-header";
        this.selectId = `approval-select-${$scope.$id}`;
        this.noticeType = warningNotice;
        const labelType = multiLanguageService.getString("accessibility.notice.type.approval_status");
        this.noticeLabel = multiLanguageService.getString("accessibility.notice.labels.warning", { type: labelType });
        this._$scope = $scope;

        this.richTextEditorClass = "rich-text-comment-box";
        this.richTextEditorDatahook = "edit-approval-body";
        this.richTextAutoFocus = false;

        this.onApprovalOptionChanged = this._approvalOptionChanged.bind(this);
        this.onApprovalTextChange = this._approvalTextChanged.bind(this);
        this.editorLabel = this.multiLanguageService.getString("labels.approval_edit.a11y.editor_label");
        this.editorPlaceholder = "";
    }

    $postLink() {
        this._$scope.$applyAsync(() => {
            const selectButton = document.getElementById(this.selectId);
            if (selectButton !== null) {
                selectButton.focus();
            }
        });
        this.onChange({ status: this.feedback.Status });
    }

    $onChanges(changes) {
        if (changes.approvalOptions !== undefined && this.approvalOptions !== null) {
            const selectedOptionIndex = this.approvalOptions.findIndex((option) => option.approvalStatus === this.feedback.Status);
            if (selectedOptionIndex > -1) {
                this.selectedOption = this.approvalOptions[selectedOptionIndex];
                this.initialSelectModel = {
                    activeItemIndex: selectedOptionIndex,
                    isOpen: false,
                    type: AriaMenuStatus.Closed
                };
            }
        }
    }

    _approvalOptionChanged(approvalOption) {
        this.onChange({ status: approvalOption.approvalStatus });
        this._$scope.$applyAsync();
    }

    _approvalTextChanged(text) {
        this.onChange({ text: text });
    }
}

var approvalEditDefinition = {
    bindings: {
        feedback: "<",
        approvalOptions: "<",
        onChange: "<",
        feedbackStatements: "<"
    },
    template: template,
    controller: ApprovalEditComponent
};

ApprovalEditComponent.$inject = ["multiLanguageService", "$scope"];
angularAMD.component("approvalEdit", approvalEditDefinition);
export { approvalEditDefinition as approvalEdit };
