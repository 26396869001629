import { angularAMD } from "@pebblepad/amd";
import { NgComponent } from "@pebblepad/angular/ngComponent";

class HeadingComponent extends NgComponent {
    constructor() {
        super();
    }

    $onChanges() {
        this.headingMarkup = `<h${this.level} class="${this.className ?? ""}">${this.content}</h${this.level}>`;
    }
}

export const headingDefinition = {
    bindings: {
        level: "<",
        content: "<",
        className: "<"
    },
    template: `<div ng-if="$ctrl.content !== undefined" compile-html="$ctrl.headingMarkup"></div>`,
    controller: HeadingComponent
};

HeadingComponent.$inject = [];
angularAMD.component("heading", headingDefinition);
