import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";

export class DetachedToolbarRefController extends NgComponent {
    constructor() {
        super();

        this.toolbarRef = { current: null };
    }

    setToolbarElement(value) {
        this.toolbarRef.current = value;
    }
}

export function detachedToolbarRefController() {
    return {
        restrict: "A",
        scope: false,
        controller: DetachedToolbarRefController
    };
}

angularAMD.directive("detachedToolbarRefContext", detachedToolbarRefController);
