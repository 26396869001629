import { httpService, IResponseError, WebRequestFactory, fromPascalToCamel } from "@pjs/http";
import { apiConfig } from "../../../ApiConfig";
import { IAccountSettingsModel } from "../interfaces/IAccountSettingsModel";
import { BackendGetAccountSettingsResponse } from "../types/BackendGetAccountSettingsResponse";
import { accountSettingsModelMapper } from "../mappers/AccountSettingsModelMapper";

export const getAccountSettings = WebRequestFactory.createGet<IAccountSettingsModel>({
    errorMapper: (errorResponse: Response) => Promise.resolve<IResponseError>({ code: errorResponse.status.toString(), headers: errorResponse.headers }),
    http: httpService,
    responseMapper: (response: Response) =>
        fromPascalToCamel<BackendGetAccountSettingsResponse, IAccountSettingsModel>((data) => accountSettingsModelMapper(data))(response),
    url: () => apiConfig.accountSettings()
});
