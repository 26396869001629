import { RefObject } from "react";
import { focusFirstElementInDialog } from "../functions/FocusFirstElementInDialog";
import { isSmallScreenDevice } from "./IsSmallScreenDevice.function";

export const handleInitialDialogFocus = (
    dialog: RefObject<HTMLDialogElement>,
    container: RefObject<HTMLElement>,
    stepContainer: RefObject<HTMLElement>,
    closeButton: RefObject<HTMLButtonElement>,
    headingFocusId: string
): void => {
    if (dialog.current === null) {
        return;
    }

    const currentFocusedElement = document.activeElement;
    const wasFocused = currentFocusedElement !== null && dialog.current.contains(currentFocusedElement);

    dialog.current.showModal();

    if (wasFocused) {
        (currentFocusedElement as HTMLElement).focus();
        return;
    }

    const headingEl = document.getElementById(headingFocusId);

    if (headingEl === null) {
        return;
    }

    if (isSmallScreenDevice()) {
        headingEl.focus();
        return;
    }

    focusFirstElementInDialog(dialog, container, stepContainer, [closeButton.current, headingEl]);
};
