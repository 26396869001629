import { Observable } from "@pjs/observables";
import { Immutable } from "@pjs/utilities";
import { ModelDocument } from "../model-document/ModelDocument";
import { ModelProxy } from "../proxy/types/ModelProxy";
import { ReadonlyModelProxy } from "../proxy/types/ReadonlyModelProxy";
import { DocumentPatchOperations } from "../proxy/types/DocumentPatchOperations";
import { PatchableModelDocument } from "../patchable-model-document/PatchableModelDocument";
import { BasePatcherModel } from "../patcher/types/BasePatcherModel";
import { ITrackedModelDocument } from "./interfaces/ITrackedModelDocument";

export class TrackedModelDocument<T extends BasePatcherModel> implements ITrackedModelDocument<T> {
    public readonly latestModel: ModelProxy<T>;
    public readonly model: ReadonlyModelProxy<T>;
    public readonly changeStream: Observable<Immutable<DocumentPatchOperations>>;

    private readonly _latestModelDocument: ModelDocument<T>;
    private readonly _modelDocument: PatchableModelDocument<T>;
    private readonly _changes: Array<DocumentPatchOperations>;

    constructor(model: T) {
        this._latestModelDocument = new ModelDocument(structuredClone(model));
        this.latestModel = this._latestModelDocument.model;
        this.changeStream = this._latestModelDocument.changeStream;

        this._modelDocument = new PatchableModelDocument(model);
        this.model = this._modelDocument.model as unknown as ReadonlyModelProxy<T>;

        this._changes = [];
        this._subscribeToLatestModelChanges();
    }

    public applyChanges(patchId?: symbol): void {
        const targetPatchIndex = patchId !== undefined ? this._changes.findIndex((patch) => patch.id === patchId) : this._changes.length - 1;

        if (targetPatchIndex === -1) {
            return;
        }

        for (let i = 0; i <= targetPatchIndex; i++) {
            this._modelDocument.patch(this._changes[i]);
        }

        this._changes.splice(0, targetPatchIndex + 1);
    }

    public destroy(): void {
        this._modelDocument.destroy();
        this._latestModelDocument.destroy();
    }

    public hasChanges(): boolean {
        return this._changes.length > 0;
    }

    public getChanges(): Immutable<Array<DocumentPatchOperations>> {
        return this._changes;
    }

    private _subscribeToLatestModelChanges(): void {
        this._latestModelDocument.changeStream.subscribe((patchOperation) => this._changes.push(patchOperation));
    }
}
