import { fromCamelToPascal, httpService, IResponseError, WebRequestFactory } from "@pjs/http";
import { Observable } from "@pjs/observables";
import { apiConfig } from "../../../ApiConfig";
import { BackendPatchAccountSettingsBody } from "../types/BackendPatchAccountSettingsBody";
import { ISettingSaveItem } from "../interfaces/settingItems/ISettingSaveItem";

const patchAccountSettingsObservable = WebRequestFactory.createPatch<boolean, BackendPatchAccountSettingsBody>({
    errorMapper: (errorResponse: Response) => Promise.resolve<IResponseError>({ code: errorResponse.status.toString(), headers: errorResponse.headers }),
    http: httpService,
    responseMapper: () => Promise.resolve(true),
    url: () => apiConfig.accountSettings()
});

export const patchAccountSettings = (body: ReadonlyArray<ISettingSaveItem>): Observable<boolean> => {
    return patchAccountSettingsObservable(fromCamelToPascal(body));
};
