const unsafeCharMap: Record<string, string> = {
    /* eslint-disable @typescript-eslint/naming-convention */
    '"': "&quot;",
    "&": "&amp;",
    "'": "&#39;",
    "<": "&lt;",
    ">": "&gt;"
    /* eslint-enable @typescript-eslint/naming-convention */
};

const replacer = (char: string): string => unsafeCharMap[char];

export function convertToHtmlSafeChars(value: string): string {
    return value.replace(/[<>"'&]/g, replacer);
}
