import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import "../assetFilteringConfig.provider";
import "../../multiLanguageService/multiLanguageService";
import "../../spaMenu/assetFilteringService/assetFiltering.service";
import "../../datePicker/datePicker";
import "../../datePicker/datePickerHelper";
import "../../utilities/pebbleDate";
import "../../react2angular/checkbox";
import "../../autoFocus/autoFocus.directive";
import { FILTER_CRITERIA_TYPES_CONSTANTS } from "../../constants/filterCriteriaTypes.constants";
import template from "./asset-filtering-content.html";

class AssetFilteringContent extends NgComponent {
    constructor($scope, $filter, multiLanguageService, datePickerHelper, assetFilteringService, assetFilteringConfig) {
        super();

        this._$filter = $filter;
        this._$scope = $scope;
        this.multiLanguageService = multiLanguageService;
        this.datePickerHelper = datePickerHelper;
        this._assetFilteringService = assetFilteringService;
        this.showSaveFilter = assetFilteringConfig.enablePersistentFilters;
        this.before = null;
        this.after = null;
        this.minDate = null;
        this.resetBtnAria = this.multiLanguageService.getString("filter_evidence.reset_filter");
        this.applyBtnText = this.multiLanguageService.getString("buttons.apply_filter");
        this.applyBtnAria = "";
        this.saveFilter = false;
        this._filterId = "";
        this.disableApplyBtn = false;
        this.showResetBtn = false;
        this.maxDate = new Date();
    }

    $onInit() {
        this._assetFilteringService.loadExistingFilter(this.assetId).then((filter) => {
            if (filter === null) {
                return;
            }

            this._filterId = filter.Id;
            this.saveFilter = filter.IsPersistent;
            this.showResetBtn = true;

            const afterData = filter.Criteria.find((criteria) => criteria.Key === FILTER_CRITERIA_TYPES_CONSTANTS.START_DATE);
            const beforeData = filter.Criteria.find((criteria) => criteria.Key === FILTER_CRITERIA_TYPES_CONSTANTS.END_DATE);

            this.before = beforeData !== undefined ? beforeData.Value : null;
            this.after = afterData !== undefined ? afterData.Value : null;
        });

        this.toggleSaveFilter = (checked) => {
            this.saveFilter = checked;
            this._$scope.$applyAsync();
        };

        this.updateApplyBtnAria();
    }

    fromDateChange(date) {
        this.after = date;
        this.minDate = this.datePickerHelper.dateAdd(this.after, "day", 1);
        this.updateApplyBtnAria();
    }

    toDateChange(date) {
        this.before = date;
        this.updateApplyBtnAria();
    }

    resetFilter() {
        this.before = null;
        this.after = null;
        if (this.saveFilter) {
            this.toggleSaveFilter(false);
            this.saveFilter = false;
        }

        this._assetFilteringService
            .removeFilter(this.assetId, this._filterId)
            .then(() => {
                this.showResetBtn = false;
            })
            .catch(angular.noop);
    }

    updateApplyBtnAria() {
        if (this.before !== null && this.after !== null) {
            this.applyBtnAria = this.multiLanguageService.getString("filter_evidence.apply_filter_hint_range", {
                dateBefore: this._$filter("pebbleDate")(this.before, "WithDay"),
                dateAfter: this._$filter("pebbleDate")(this.after, "WithDay")
            });
        } else if (this.after === null && this.before !== null) {
            this.applyBtnAria = this.multiLanguageService.getString("filter_evidence.apply_filter_hint_before", { dateBefore: this._$filter("pebbleDate")(this.before, "WithDay") });
        } else if (this.before === null && this.after !== null) {
            this.applyBtnAria = this.multiLanguageService.getString("filter_evidence.apply_filter_hint_after", { dateAfter: this._$filter("pebbleDate")(this.after, "WithDay") });
        } else if (this.before === null && this.after === null) {
            this.applyBtnAria = this.multiLanguageService.getString("filter_evidence.apply_filter_hint_default");
        }
    }

    applyFilter() {
        this.disableApplyBtn = true;
        this.applyBtnText = this.multiLanguageService.getString("buttons.applying_filter");

        const filterData = {
            before: this.before,
            after: this.after,
            filterId: this._filterId
        };

        this._assetFilteringService
            .applyFilter(this.assetId, filterData, this.saveFilter)
            .then(() => {
                if (this.onApply !== undefined) {
                    this.onApply();
                }

                this.showResetBtn = true;
            })
            .catch(angular.noop)
            .finally(() => {
                this.disableApplyBtn = false;
                this.applyBtnText = this.multiLanguageService.getString("buttons.apply_filter");
            });
    }
}

export const assetFilteringContentDefinition = {
    bindings: {
        assetId: "<",
        onApply: "&?"
    },
    template: template,
    controller: AssetFilteringContent
};

assetFilteringContentDefinition.$inject = ["$scope", "$filter", "multiLanguageService", "datePickerHelper", "assetFilteringService", "assetFilteringConfig"];
angularAMD.component("assetFilteringContent", assetFilteringContentDefinition);
