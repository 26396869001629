import { NgComponent } from "@pebblepad/angular/ngComponent";
import { assetEvidenceIcon, textEvidenceIcon, warningNotice } from "@pjs/core-ui";
import { angularAMD } from "@pebblepad/amd";
import template from "./add-evidence.html";
import { UnsavedChangesPreventRedirectResolver } from "../preventRedirect/resolvers/unsavedChangesPreventRedirectResolver";
import "../react2angular/htmlNotice";
import "../autoFocus/autoFocus.directive";
import "../multiLanguageService/multiLanguageService";
import "../utilities/baseUrlsFactory";
import "../utilities/subscriptionServiceProvider";
import "../assetSelector/assetSelector.service";
import "../modal/services/modal";
import "../preventRedirect/preventRedirect";
import "./evidenceJustification/evidenceJustification.component";
import "../formComponents/formElementInfo/formElementInfoService.service";
import "./textOnlyEvidence/textOnlyEvidence.component";
import "../capabilityCriteriaService/capabilityCriteriaService";
import { MAP_TO_STANDARDS_CONSTANTS } from "../constants/mapToStandards.constants";

class AddEvidenceComponent extends NgComponent {
    constructor($rootScope, $scope, $q, multiLanguageService, subscriptionServiceProvider, modal, baseUrlsFactory, assetSelector, preventRedirect, formElementInfoService, capabilityCriteriaService) {
        super();

        this._$rootScope = $rootScope;
        this._$q = $q;
        this._$scope = $scope;
        this.multiLanguageService = multiLanguageService;
        this._subscriptionServiceProvider = subscriptionServiceProvider;
        this._preventRedirectResolver = new UnsavedChangesPreventRedirectResolver(UnsavedChangesPreventRedirectResolver.defaultMessages(multiLanguageService, $rootScope.appName.toLowerCase()));
        this._baseUrlsFactory = baseUrlsFactory;
        this._assetSelector = assetSelector;
        this._preventRedirect = preventRedirect;
        this._formElementInfoService = formElementInfoService;
        this.assetEvidenceIcon = assetEvidenceIcon.toString();
        this.textEvidenceIcon = textEvidenceIcon.toString();
        this.mappingsList = [];
        this._mappingsIndexedById = new Map();
        this._subscriptionId = "addEvidenceInProgress";
        this._changeTrackingId = `${this.assetId}-${this.anchorId}`;
        this._modal = modal;
        this.evidenceJustificationMode = MAP_TO_STANDARDS_CONSTANTS.EVIDENCE_MODE.ONE_JUSTIFICATION_FOR_ALL_ITEMS;
        this._justificationForAllEvidence = "";
        this.isSaving = false;
        this.warningNotice = warningNotice;
        const labelType = multiLanguageService.getString("accessibility.notice.type.evidence");
        this.warningLabel = multiLanguageService.getString("accessibility.notice.labels.warning", { type: labelType });
        this.errorMsg = "";
        this.assetSelectorOpen = false;
        this.textOnlyEvidenceOpen = false;
        this._selectAssetsPromise = null;
        this.setUnsavedChanges = this._setUnsavedChanges.bind(this);
        this.onTextOnlyEvidenceSave = this._onTextOnlyEvidenceSave.bind(this);
        this.evidenceCriteriaData = capabilityCriteriaService.getRenderData(this.evidenceCount, this.evidenceMinimum);
    }

    $onInit() {
        this._preventRedirect.addCustomResolver(this._preventRedirectResolver);
        this._subscriptionServiceProvider.subscribe("spaMenu", this._subscriptionId, () => this._onSpaMenuAction());
    }

    $onDestroy() {
        if (this._selectAssetsPromise !== null) {
            this._selectAssetsPromise.abort();
        }
        this._cleanupBindings();
    }

    $onChanges(changes) {
        if (changes.isActivePanel !== undefined && !changes.isActivePanel.isFirstChange()) {
            if (changes.isActivePanel.currentValue) {
                this.assetSelectorOpen = false;
            }
        }
    }

    openAssetSelector() {
        this.errorMsg = "";
        this.assetSelectorOpen = true;

        this._selectAssetsPromise = this._assetSelector
            .selectAssets({
                requiresWritePermission: true,
                excludeChildrenOf: [this.topLevelAssetId],
                excludeIds: [this.assetId, this.topLevelAssetId, ...this.evidenceAssetIds],
                includeUpload: true,
                currentlySelectedIds: [...this._mappingsIndexedById.keys()]
            })
            .then((data) => {
                this.evidenceJustificationMode =
                    data.length > 1 && this._mappingsIndexedById.size > 1
                        ? MAP_TO_STANDARDS_CONSTANTS.EVIDENCE_MODE.JUSTIFICATION_PER_ITEM
                        : MAP_TO_STANDARDS_CONSTANTS.EVIDENCE_MODE.ONE_JUSTIFICATION_FOR_ALL_ITEMS;
                this._updateMappingJustificationModels(data);
                this.assetSelectorOpen = false;
                this._setUnsavedChanges(data.length > 0);
            });
    }

    openTextOnlyEvidence() {
        this.textOnlyEvidenceOpen = true;
    }

    closeEvidence() {
        if (this._preventRedirectResolver.redirectionIsBlocked()) {
            this._showConfirmationModal();
        } else {
            this._resetEvidence();
        }
    }

    closeSidebar() {
        if (this._preventRedirectResolver.redirectionIsBlocked()) {
            this._showConfirmationModal(this.closeInfo.closeAction);
        } else {
            this.closeInfo.closeAction();
        }
    }

    updateJustification(justification, evidenceId = "") {
        if (evidenceId === "") {
            this._justificationForAllEvidence = justification;
        } else {
            const mappingModel = this._mappingsIndexedById.get(evidenceId);
            mappingModel.Justification = justification;
        }
    }

    updateJustificationMode(mode) {
        this.evidenceJustificationMode = mode;
    }

    saveEvidence() {
        this.isSaving = true;
        this.errorMsg = "";

        let evidence = this.mappingsList.map((item) => {
            const evidenceItem = { Id: item.EvidenceId, Justification: "" };

            if (this.evidenceJustificationMode === MAP_TO_STANDARDS_CONSTANTS.EVIDENCE_MODE.ONE_JUSTIFICATION_FOR_ALL_ITEMS) {
                evidenceItem.Justification = this._justificationForAllEvidence;
            } else {
                const mappingModel = this._mappingsIndexedById.get(item.EvidenceId);
                evidenceItem.Justification = mappingModel !== undefined ? mappingModel.Justification : "";
            }

            return evidenceItem;
        });

        this._formElementInfoService
            .addEvidence(this.assetId, this.anchorId, evidence)
            .then(() => {
                this._preventRedirectResolver.cleanUp();
                this.closeSidebar();
            })
            .catch(() => {
                this.errorMsg = this.multiLanguageService.getString("labels.add_evidence_sidebar.save_error");
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    _updateMappingJustificationModels(selectedAssets) {
        this.mappingsList = [];
        if (selectedAssets.length === 0) {
            this._mappingsIndexedById.clear();
            return;
        }

        for (const asset of selectedAssets) {
            let mappingModel = this._mappingsIndexedById.get(asset.Id);
            if (mappingModel === undefined) {
                mappingModel = {
                    Title: asset.Title,
                    Justification: "",
                    EvidenceId: asset.Id,
                    CapabilityId: this.anchorId
                };
            }

            this.mappingsList.push(mappingModel);
            this._mappingsIndexedById.set(asset.Id, mappingModel);
        }
    }

    _setUnsavedChanges(changes) {
        if (changes) {
            this._preventRedirectResolver.addTrackedId(this._changeTrackingId);
        } else {
            this._preventRedirectResolver.removeTrackedId(this._changeTrackingId);
        }
    }

    _onTextOnlyEvidenceSave() {
        this._preventRedirectResolver.cleanUp();
        this.closeSidebar();
    }

    _onSpaMenuAction() {
        if (this.isActivePanel && this._preventRedirectResolver.redirectionIsBlocked()) {
            this._showConfirmationModal(this.closeInfo.closeAction);
            return this._$q.reject("Preventing close due to user choice");
        }
        return this._$q.resolve();
    }

    _cleanupBindings() {
        this._subscriptionServiceProvider.unsubscribe("spaMenu", this._subscriptionId);
        this._preventRedirectResolver.cleanUp();
        this._preventRedirect.removeCustomResolver(this._preventRedirectResolver);
    }

    _showConfirmationModal(closeCallback = null) {
        if (!this._preventRedirect.doesExist) {
            const modalContent = this._preventRedirectResolver.getLocationChangeMessage();

            this._modal.launch(`${this._baseUrlsFactory.shared_component_base_url}preventRedirect/templates/warning-modal-alt.lazy.html`, {
                title: modalContent.title,
                message: modalContent.message,
                continueBtn: modalContent.continueBtn,
                cancelRedirectBtn: modalContent.cancelRedirectBtn,
                continue: () => {
                    this._resetEvidence();
                    if (closeCallback !== null) {
                        closeCallback();
                    }
                }
            });
        }
    }

    _resetEvidence() {
        this._preventRedirectResolver.cleanUp();
        this._justificationForAllEvidence = "";
        this.textOnlyEvidenceOpen = false;
        this._mappingsIndexedById.clear();
        this.mappingsList = [];
    }
}

export const addEvidenceComponentDefinition = {
    bindings: {
        anchorId: "<",
        assetId: "<",
        closeInfo: "<",
        evidenceAssetIds: "<",
        isActivePanel: "<",
        topLevelAssetId: "<",
        evidenceCount: "<",
        evidenceMinimum: "<"
    },
    template: template,
    controller: AddEvidenceComponent
};

AddEvidenceComponent.$inject = [
    "$rootScope",
    "$scope",
    "$q",
    "multiLanguageService",
    "subscriptionServiceProvider",
    "modal",
    "baseUrlsFactory",
    "assetSelector",
    "preventRedirect",
    "formElementInfoService",
    "capabilityCriteriaService"
];

angularAMD.component("addEvidence", addEvidenceComponentDefinition);
