import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./templates/standards-selection-sidebar.html";
import "../../../multiLanguageService/multiLanguageService";
import "./standardsSelectionSidebarAccordion.component";

class StandardsSelectionSidebar extends NgComponent {
    constructor(multiLanguageService) {
        super();

        this._multiLanguageService = multiLanguageService;
        this.showLoading = true;
        this.errorMessage = null;
    }

    $onInit() {
        this.right_panel_holder_el = angular.element(document.getElementsByClassName("right-panel-holder"));
        this.right_panel_holder_el.addClass("right-panel-holder__width-larger");

        if (this.standardsData !== undefined && this.standardsData.length > 0) {
            this.onStandardsLoaded();
        }
    }

    $onChanges(changes) {
        if (changes.standardsData !== undefined && !changes.standardsData.isFirstChange()) {
            this.onStandardsLoaded();
        }
    }

    onStandardsLoaded() {
        this.showLoading = false;
        if (this.standardsData.length === 0) {
            this.errorMessage = this._multiLanguageService.getString("sidebar.standards_selection.no_standards");
        }
    }

    toggleStandard(pageFormId, pageId, formElementId, value, assetRefId) {
        this.onSelect({
            data: [
                {
                    PageFormId: pageFormId,
                    PageId: pageId,
                    ElementId: formElementId,
                    FormElementId: formElementId,
                    AssetRefId: assetRefId
                }
            ],
            value: value
        });
    }
}

export const standardsSelectionSidebar = {
    bindings: {
        standardsData: "<",
        onSelect: "&",
        ariaLabelHook: "<"
    },
    template: template,
    controller: StandardsSelectionSidebar
};

StandardsSelectionSidebar.$inject = ["multiLanguageService"];
angularAMD.component("standardsSelectionSidebar", standardsSelectionSidebar);
