import { isPlainObject } from "@pjs/utilities";
import { traverseObject } from "../traverse-object/TraverseObject.function";
import { AllPatchOperations } from "../proxy/types/AllPatchOperations";
import { PatchOperation } from "../../enums/PatchOperation";
import { IPatcher } from "./interfaces/IPatcher";
import { PatchPath } from "./types/PatchPath";
import { BasePatcherModel } from "./types/BasePatcherModel";

export class Patcher<TModel extends BasePatcherModel> implements IPatcher<TModel> {
    private _model: TModel;

    constructor(model: TModel) {
        this._model = model;
    }

    public getModel(): TModel {
        return this._model;
    }

    public patch(patch: AllPatchOperations): boolean {
        switch (patch.op) {
            case PatchOperation.Add:
                return this._add(patch.path, patch.value);
            case PatchOperation.Remove:
                return this._remove(patch.path);
            case PatchOperation.Update:
                return this._update(patch.path, patch.value);
            case PatchOperation.InsertBefore:
                return this._insertBefore(patch.path, patch.value);
            case PatchOperation.InsertAfter:
                return this._insertAfter(patch.path, patch.value);
            default:
                throw new Error(`This operation ${(patch as any).op} shall not pass! 🧙`);
        }
    }

    private _add(path: PatchPath, value: any): boolean {
        const result = traverseObject(path, this._model);

        if (result === null || !Array.isArray(result.node)) {
            return false;
        }

        const parsedPath = result.parsedPath;
        const clonedResult = this._cloneAlongPath(parsedPath);
        clonedResult.push(value);

        return true;
    }

    private _update(path: PatchPath, value: any): boolean {
        const result = traverseObject(path, this._model);

        if (result === null || !isPlainObject(result.node)) {
            return false;
        }

        if (result.parsedPath.length === 0) {
            this._model = { ...this._model, ...value };
            return true;
        }

        const lastItem = result.parsedPath.pop();
        const clonedResult = this._cloneAlongPath(result.parsedPath);
        clonedResult[lastItem as keyof typeof clonedResult] = { ...clonedResult[lastItem as keyof typeof clonedResult], ...value };

        return true;
    }

    private _remove(path: PatchPath): boolean {
        const result = traverseObject(path, this._model);

        if (result === null || !Array.isArray(result.parent)) {
            return false;
        }

        const parsedPath = result.parsedPath;
        const lastItem = parsedPath.pop();

        const clonedResult = this._cloneAlongPath(parsedPath);
        clonedResult.splice(lastItem, 1);

        return true;
    }

    private _insertBefore(path: PatchPath, value: any): boolean {
        const result = traverseObject(path, this._model);

        if (result === null || !Array.isArray(result.parent)) {
            return false;
        }

        const parsedPath = result.parsedPath;
        const lastItem = parsedPath.pop();

        const clonedResult = this._cloneAlongPath(parsedPath);
        clonedResult.splice(lastItem, 0, value);

        return true;
    }

    private _insertAfter(path: PatchPath, value: any): boolean {
        const result = traverseObject(path, this._model);

        if (result === null || !Array.isArray(result.parent)) {
            return false;
        }

        const parsedPath = result.parsedPath;
        const lastItem = parsedPath.pop() as number;

        const clonedResult = this._cloneAlongPath(parsedPath);
        clonedResult.splice(lastItem + 1, 0, value);

        return true;
    }

    private _cloneAlongPath(parsedPath: Array<string | number>): Array<any> | Record<string, any> {
        let node: any = Array.isArray(this._model) ? this._model.slice() : { ...this._model };
        this._model = node;

        for (const pathItem of parsedPath) {
            const parent = node;
            const value = parent[pathItem];

            parent[pathItem] = Array.isArray(value) ? value.slice() : { ...value };
            node = node[pathItem];
        }

        return node;
    }
}
