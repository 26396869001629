import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./approval-status.html";

class ApprovalStatus extends NgComponent {
    constructor(standardsOverviewService) {
        super();

        this.status = standardsOverviewService.getStatusInfo(this.item.Status, this.item.Recalled, this.item.Released);
    }
}

export const approvalStatusDefinition = {
    bindings: {
        item: "<"
    },
    template: template,
    controller: ApprovalStatus
};

ApprovalStatus.$inject = ["standardsOverviewService"];
angularAMD.component("approvalStatus", approvalStatusDefinition);
