import { PebbleSecurityService } from "./pebbleSecurity.service";
import "@pebblepad/angular-sanitize";

var pebbleSecurityModule = angular.module("pebbleSecurity", ["ng", "ngSanitize"]);
pebbleSecurityModule.service("PebbleSecurityService", PebbleSecurityService);

//Secure decorators for angular services/directives/filters
pebbleSecurityModule.config([
    "$provide",
    function ($provide) {
        $provide.decorator("$sce", [
            "$delegate",
            "PebbleSecurityService",
            function sceDecorator($delegate, pebbleSecurityService) {
                var trustAsHtmlOrig = $delegate.trustAsHtml;

                $delegate.trustAsHtml = function (input) {
                    var sanitisedInput = pebbleSecurityService.sanitise(input);
                    return trustAsHtmlOrig(sanitisedInput);
                };

                return $delegate;
            }
        ]);
    }
]);
