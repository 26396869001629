import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./jump-to-button.html";
import "../../utilities/scrollToElement.service";
import "../../multiLanguageService/multiLanguageService";

class JumpToButtonComponent extends NgComponent {
    constructor(multiLanguageService, scrollToElementService) {
        super();

        this._scrollToElementService = scrollToElementService;
        this.jumpToText = multiLanguageService.getString("labels.canvas.feedback_jump_to");
        this.jumpToDataHook = this.feedback.AnchorId !== null ? `jump-to-${this.feedback.AnchorId}` : "";
    }

    jumpToBlock() {
        const targetPageId = this.feedback.LeftOn.PageId;
        const targetAssetId = this.feedback.AssetId;

        if (targetPageId !== null && targetPageId !== this.currentPageId) {
            this._scrollToElementService.goToElementWithPageId(`Element${this.feedback.AnchorId}`, targetPageId);
            this.updateQueryStringParam("pageId", targetPageId);
            return;
        } else if (this.feedback.LeftOn.LinkedAsset && targetAssetId !== this.assetId) {
            this._scrollToElementService.goToElementWithAssetId(`Element${this.feedback.AnchorId}`, targetAssetId);
            this.updateRoute(targetAssetId);
            return;
        }
        this._scrollToElementService.goToElement(`Element${this.feedback.AnchorId}`);
    }
}

export const jumpToButtonComponentDefinition = {
    bindings: {
        feedback: "<",
        updateRoute: "<",
        updateQueryStringParam: "<",
        assetId: "<",
        currentPageId: "<"
    },
    template: template,
    controller: JumpToButtonComponent
};

JumpToButtonComponent.$inject = ["multiLanguageService", "scrollToElementService"];
angularAMD.component("jumpToButton", jumpToButtonComponentDefinition);
