import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { ASSESSMENT_CONSTANTS } from "../../../constants/assessment.constants";
import "../../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import "../../../utilities/urlService";
import template from "./feedback-left-on.html";

class FeedbackLeftOn extends NgComponent {
    constructor(feedbackHelper, urlService) {
        super();

        this.ASSESSMENT_CONSTANTS = ASSESSMENT_CONSTANTS;
        this._feedbackHelper = feedbackHelper;
        this.urlService = urlService;
    }

    $onChanges(changes) {
        if (changes.data !== undefined) {
            this.buildLeftOnDetails();
        }
    }

    buildLeftOnDetails() {
        this.leftOnLink = "";
        this.leftOnText = "";

        if (this.data.PageTitle !== null && this.currentAssetId !== this.data.AssetId) {
            this.leftOnText = this._feedbackHelper.getLeftOnString(this.data.PageTitle);

            if (this.data.MainAssetId !== undefined && this.data.MainAssetId !== null) {
                this.leftOnLink = this._generateLeftOnLink();
            }
        }

        if (this.data.WorkspaceName !== null || this.data.AssignmentName !== null) {
            this.leftOnOrigin = this._feedbackHelper.getLeftOnOriginString(this.data.WorkspaceName, this.data.AssignmentName);
        }
    }

    _generateLeftOnLink() {
        const applicableId = this.data.LinkedAsset ? this.data.AssetId : this.data.MainAssetId;
        let url = this._feedbackHelper.getLeftOnRelativeLink(applicableId, this.data.PageId, this.data.ForwardingTimeslice);
        return this._requiresMappingParameters(this.feedbackType) ? this.urlService.addParameters(url, [{ key: "mapping", value: true }]) : url;
    }

    _requiresMappingParameters(feedbackType) {
        return feedbackType === this.ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVALCOLLECTION;
    }
}

export const feedbackLeftOnDefinition = {
    bindings: {
        data: "<",
        currentAssetId: "<",
        feedbackType: "@"
    },
    template: template,
    controller: FeedbackLeftOn
};

FeedbackLeftOn.$inject = ["feedbackHelper", "urlService"];
angularAMD.component("feedbackLeftOn", feedbackLeftOnDefinition);
