import { FunctionComponent, KeyboardEvent, useRef } from "react";
import { Input } from "../input/Input.component";
import { IconButton } from "../icon-button/IconButton.component";
import { IconButtonClass } from "../icon-button/enums/IconButtonClass";
import { crossIcon } from "../icon/icons/Cross.icon";
import { searchIcon } from "../icon/icons/Search.icon";
import { Keys } from "../../enums/Keys";
import { ISearchProps } from "./interfaces/ISearchProps";
import "./styles/search.css";

export const Search: FunctionComponent<ISearchProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const onKeyDown = (event: KeyboardEvent): void => {
        if (event.key === Keys.Enter) {
            props.onSearch(props.searchString);
            event.preventDefault();
        }
    };

    const onClearSearch = (): void => {
        props.onChange("");
        props.onSearch("");
        if (inputRef.current !== null) {
            inputRef.current.focus();
        }
    };

    return (
        <div className={`${props.className !== undefined ? props.className : ""} cui-search`} data-hook="cui-search">
            <div className="cui-search__text-field-container">
                <Input
                    ref={inputRef}
                    type="search"
                    value={props.searchString}
                    onKeyDown={onKeyDown}
                    onChange={(event) => props.onChange(event.target.value)}
                    placeholder={props.placeholder}
                    aria-label={props.searchInputLabel}
                    autoFocus={props.autoFocus}
                    disabled={props.disabled}
                    data-hook={props.dataHooks?.input ?? "cui-search-input"}
                />
                {props.searchString.length > 0 && (
                    <IconButton
                        className={IconButtonClass.Outlined}
                        source={crossIcon}
                        onClick={onClearSearch}
                        ariaLabel={props.clearSearchLabel}
                        disabled={props.disabled}
                        dataHook={props.dataHooks?.clearButton ?? "cui-clear-button"}
                    />
                )}
            </div>
            <IconButton
                className={IconButtonClass.Outlined}
                source={searchIcon}
                onClick={() => props.onSearch(props.searchString)}
                ariaLabel={props.searchButtonLabel}
                disabled={props.disabled}
                dataHook={props.dataHooks?.searchButton ?? "cui-search-button"}
            />
        </div>
    );
};
