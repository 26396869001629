import { isPlainObject } from "@pjs/utilities";
import { PatchPath } from "../patcher/types/PatchPath";
import { ITraversalResult } from "./interfaces/ITraversalResult";

/**
 * The `traverseObject` function enables the consumer to use our PatchPath to retrieve a value from a object or array.
 * The traverser does not support
 * - traversing multi-dimensional Arrays
 * - objects which have optional properties
 * The ITraversalResult.nodeIndex will be -1 when the parent is not an array.
 */
export function traverseObject(patchPath: PatchPath, object: Array<Record<string, any>> | Record<string, any>): ITraversalResult | null {
    if (patchPath.length === 0) {
        return { node: object, nodeIndex: -1, parent: null, parsedPath: [] };
    }

    const parsedPath: Array<string | number> = [];
    let parent: any = null;
    let node: any = object;
    let nodeIndex = -1;

    for (const pathOption of patchPath) {
        parent = node;
        nodeIndex = -1;
        node = undefined;

        if (typeof pathOption === "string" && isPlainObject(parent)) {
            node = parent[pathOption];
            parsedPath.push(pathOption);
        } else if (Array.isArray(parent)) {
            if (isPlainObject(pathOption)) {
                const matcherEntries = Object.entries(pathOption);

                nodeIndex = parent.findIndex((item) => {
                    if (item === null || item === undefined) {
                        return false;
                    }

                    return matcherEntries.every(([pathKey, pathValue]) => item[pathKey] === pathValue);
                });
            } else {
                nodeIndex = parent.indexOf(pathOption);
            }

            parsedPath.push(nodeIndex);
            node = parent[nodeIndex];
        }

        if (node === undefined) {
            return null;
        }
    }

    return { node: node, nodeIndex: nodeIndex, parent: parent, parsedPath: parsedPath };
}
