import { BackendSettings } from "../enums/BackendSettings";
import { ISettings } from "../interfaces/ISettings";
import { ISettingResponseItem } from "../interfaces/settingItems/ISettingResponseItem";
import { IAccountSettingsModel } from "../interfaces/IAccountSettingsModel";
import { defaultBoolSetting } from "./functions/DefaultBoolSetting.function";
import { defaultListSetting } from "./functions/DefaultListSetting.function";
import { fromListOptions } from "./functions/FromListOptions.function";
import { fromStringBool } from "./functions/FromStringBool.function";

const responseMapper = (response: ReadonlyArray<ISettingResponseItem>): ISettings => {
    const settingsMap = new Map<string, ISettingResponseItem>();

    for (const setting of response) {
        settingsMap.set(setting.name, setting);
    }

    const pageSaving = settingsMap.get(BackendSettings.PageSaving);
    const embedConsent = settingsMap.get(BackendSettings.EmbedConsent);
    const languageChoice = settingsMap.get(BackendSettings.LanguageChoice);

    return {
        assessment: {
            enabled: pageSaving !== undefined,
            pageSaving: pageSaving !== undefined ? fromStringBool(pageSaving) : defaultBoolSetting(false)
        },
        language: {
            enabled: true,
            languageChoice: languageChoice !== undefined ? fromListOptions(languageChoice) : defaultListSetting("en-GB")
        },
        privacy: {
            enabled: true,
            embedConsent: embedConsent !== undefined ? fromStringBool(embedConsent) : defaultBoolSetting(false)
        }
    };
};

export const accountSettingsModelMapper = (response: ReadonlyArray<ISettingResponseItem>): IAccountSettingsModel => {
    return {
        settings: responseMapper(response),
        state: {
            error: false,
            isDirty: false,
            saving: false
        }
    };
};
