import { angularAMD } from "@pebblepad/amd";
import "../../multiLanguageService/multiLanguageService";
import "../../builder/tables/services/tableDtoHelper.service";
import "../wordCountInfoBar/wordCountHelper.service";
import "../../utilities/helpers";
import { FEEDBACK_BLOCK_TYPES } from "../../constants/feedbackBlockTypes.const";

angularAMD.factory("formComponentsHelper", [
    "multiLanguageService",
    "$rootScope",
    "tableDtoHelper",
    "wordCountHelper",
    "helpers",
    function (multiLanguageService, $rootScope, tableDtoHelper, wordCountHelper, helpers) {
        var formComponentsHelper = {
            hasInvalidDigitalSignature: false,
            wordCountPreventsSave: false
        };

        formComponentsHelper.canElementSupportFeedback = function (element) {
            return !element.Permissions.AssessorField && !(element.ElementType === "SingleLineTextBox" && element.ElementPurpose === "Title") && FEEDBACK_BLOCK_TYPES.includes(element.ElementType);
        };

        formComponentsHelper._isPublicElement = function (obj) {
            return !obj.element.Permissions.Private && !obj.element.Permissions.Confidential && !obj.element.Permissions.AssessorField;
        };

        formComponentsHelper._displayPrivate = function (obj) {
            return obj.element.Permissions.Private && obj.user_is_an_owner;
        };

        formComponentsHelper._displayConfidential = function (obj) {
            return obj.element.Permissions.Confidential && (obj.assessor_viewing || obj.user_is_an_owner);
        };

        formComponentsHelper._displayAssessorField = function (obj) {
            return obj.element.Permissions.AssessorField;
        };

        formComponentsHelper.shouldElementDisplayAtAll = function (obj) {
            var hasPermission = this._isPublicElement(obj) || this._displayPrivate(obj) || this._displayConfidential(obj) || this._displayAssessorField(obj);
            if (!hasPermission) {
                return false;
            }

            if (this._displayAssessorField(obj) && obj.assessor_viewing) {
                return true;
            }

            return obj.view_mode ? this.shouldElementDisplayViewMode(obj) : this.shouldElementDisplayEditMode(obj);
        };

        formComponentsHelper.shouldElementDisplayEditMode = function (obj) {
            return !obj.view_mode;
        };

        formComponentsHelper.shouldElementDisplayViewMode = function (obj) {
            // if api field then its always view mode
            if (obj.element.Permissions.ApiField) {
                return true;
            }

            if (obj.element.Permissions.AssessorField && obj.assessor_viewing) {
                return true;
            }

            // check you are in view mode and it is not an assessor viewing an assessor field
            if (obj.view_mode) {
                // if you are always allowed to see it even when empty then return true
                if (obj.element.ShowElementInViewModeWhenEmpty) {
                    return true;
                } else {
                    // check if this element has an answer, if so return true, else return false
                    switch (obj.element.ElementType) {
                        /***********************************************************************************************/
                        /***********************************************************************************************/
                        /******************************    USER EDITABLE FIELDS    *************************************/
                        /***********************************************************************************************/
                        /***********************************************************************************************/
                        case "SingleLineTextBox":
                        case "MultiLineTextBox":
                            return formComponentsHelper.hasTextFieldBeenFilledIn(obj.element) || formComponentsHelper.checkForEvidence(obj.element);
                        case "DatePickerInput":
                            return formComponentsHelper.hasDateFieldBeenFilledIn(obj.element) || formComponentsHelper.checkForEvidence(obj.element);
                        case "OptionsList-RadioButtons":
                        case "OptionsList-DropDown":
                        case "CapabilityOptionList-RadioButtons":
                            return formComponentsHelper.hasOptionsListBeenFilledIn(obj.element) || formComponentsHelper.checkForEvidence(obj.element);
                        case "CapabilityBinaryAnswerInput":
                        case "BinaryAnswerInput":
                        case "RangeInput-Likert":
                        case "RangeInput-Standard":
                        case "CapabilityRangeInput-Standard":
                            return formComponentsHelper.hasUserSelectionBeenSelected(obj.element) || formComponentsHelper.checkForEvidence(obj.element);
                        case "OptionsList-Checkboxes":
                            return formComponentsHelper.hasAnyCheckboxBeenChecked(obj.element) || formComponentsHelper.checkForEvidence(obj.element);
                        case "CapabilityEvidenceOnly":
                            return formComponentsHelper.checkForEvidence(obj.element);
                        case "FormUserMediaPicker":
                            return formComponentsHelper.hasUserSelectedMedia(obj.element);
                        case "GenericTable":
                            return tableDtoHelper.isNotEmpty(obj.element);
                        case "UserDetails":
                            return formComponentsHelper.hasUserDetailsBeenSelected(obj.element);
                        case "FormSignatureElement":
                            return formComponentsHelper.hasItBeenSigned(obj.element);
                        /***********************************************************************************************/
                        /***********************************************************************************************/
                        /**************************************    ROWS    *********************************************/
                        /***********************************************************************************************/
                        /***********************************************************************************************/
                        case "FormElementCells":
                            var showRow = false;
                            // if any of the sub elements are going to show then show the row
                            for (var a = 0; a < obj.element.Contents.length; a++) {
                                var subElement = obj.element.Contents[a];

                                var permissions = {
                                    element: subElement,
                                    view_mode: obj.view_mode,
                                    assessor_viewing: obj.assessor_viewing,
                                    user_is_an_owner: obj.user_is_an_owner
                                };

                                if (formComponentsHelper.shouldElementDisplayViewMode(permissions) && formComponentsHelper.hasElementBeenFilledIn(subElement)) {
                                    showRow = true;
                                }
                            }
                            return showRow;
                        /***********************************************************************************************/
                        /***********************************************************************************************/
                        /**************************************    HINTS    ********************************************/
                        /***********************************************************************************************/
                        /***********************************************************************************************/
                        case "HorizontalRule":
                            return obj.element.DisplayInViewMode; // show depending on this var
                        case "FormStaticTextElement-TextSection":
                        case "FormStaticTextElement-CenteredMedia":
                        case "FormStaticTextElement-MediaAndTextSection":
                            var mediaIdShown = true;
                            var hintShown = true;
                            var allowedToShow = false;

                            if (obj.element.StaticMediaId === "") {
                                mediaIdShown = false;
                            }
                            if (obj.element.StaticHint === "") {
                                hintShown = false;
                            }

                            if (obj.element.ShowElementHintInViewMode === true && (mediaIdShown === true || hintShown === true)) {
                                allowedToShow = true;
                            }

                            return allowedToShow; // show depending on this var
                        case "FormContentEmbedElement":
                            return obj.element.ShowElementHintInViewMode; // show depending on this var
                        default:
                            return true;
                    }
                }
            }
            return false;
        };

        formComponentsHelper.checkForEvidence = function (element) {
            if (element.Evidence.EvidenceAllowed) {
                if (element.Evidence.Evidence[0]) {
                    // tslint:disable-next-line:triple-equals
                    if ((element.Evidence.Evidence[0] && element.Evidence.Evidence[0].Justification != "") || (element.Evidence.Evidence[0] && element.Evidence.Evidence[0].EvidenceItems.length > 0)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        };

        formComponentsHelper.checkIfMandatoryForCompletionValid = function (element) {
            if (element) {
                switch (element.ElementType) {
                    case "FormElementCells":
                        return formComponentsHelper.performRowMandatoryCheck(element);
                        break;
                    case "MultiLineTextBox":
                        return formComponentsHelper.performMultilineMandatoryCheck(element);
                        break;
                    case "CapabilityBinaryAnswerInput":
                    case "BinaryAnswerInput":
                    case "DatePickerInput":
                    case "FormUserMediaPicker":
                    case "RangeInput-Likert":
                    case "RangeInput-Standard":
                    case "CapabilityRangeInput-Standard":
                    case "CapabilityOptionList-RadioButtons":
                    case "OptionsList-RadioButtons":
                    case "OptionsList-DropDown":
                    case "OptionsList-Checkboxes":
                    case "SingleLineTextBox":
                    case "UserDetails":
                    case "CapabilityEvidenceOnly":
                        return formComponentsHelper.performBasicNotEmptyMandatoryCheck(element);
                        break;
                    case "GenericTable":
                        return formComponentsHelper.performTableMandatoryCheck(element);
                        break;
                    case "FormContentEmbedElement":
                    case "FormBranchingContainer":
                    case "StandardsSelection":
                    case "StandardsElement":
                    case "HorizontalRule":
                    case "FormStaticTextElement-TextSection":
                    case "FormStaticTextElement-CenteredMedia":
                    case "FormStaticTextElement-MediaAndTextSection":
                    case "FormSignatureElement":
                    default:
                        // these elements mandatory is not possible
                        return { valid: true, type: "checkNotPossible" };
                        break;
                }
            }

            return { valid: true, type: "NotAnElement" };
        };

        formComponentsHelper.performBasicNotEmptyMandatoryCheck = function (element) {
            // if mandatory is turned off immediately exit with a positive result
            if (element.MandatoryForProgress === false) {
                return { valid: true, type: "checkNotEnabled" };
            }
            // if mandatory is enabled do a basic check to see if element is filled in
            return formComponentsHelper.hasElementBeenFilledIn(element) ? { valid: true, type: "checkEnabledButAllValid" } : { valid: false, type: "emptyField" };
        };

        formComponentsHelper.performMultilineMandatoryCheck = function (element) {
            // if mandatory is turned off immediately exit with a positive result
            if (element.MandatoryForProgress === false) {
                return { valid: true, type: "checkNotEnabled" };
            }

            // if enabled check to see word count conditions are met
            if (element.WordCountEnabled === true) {
                // get word count
                var wordCount = wordCountHelper.getWordCount(element.AnswerText);
                if (wordCountHelper.wordCountIsInvalid(wordCount, element.MinWords, element.MaxWords)) {
                    return { valid: false, type: "wordCountFailed" };
                }
            }

            // check to see if element is filled in
            return formComponentsHelper.hasElementBeenFilledIn(element) ? { valid: true, type: "checkEnabledButAllValid" } : { valid: false, type: "emptyField" };
        };

        formComponentsHelper.performTableMandatoryCheck = function (element) {
            if (element.MandatoryForProgress === false) {
                return { valid: true, type: "checkNotEnabled" };
            }

            return { valid: tableDtoHelper.isNotEmpty(element), type: "emptyField" };
        };

        formComponentsHelper.performRowMandatoryCheck = function (element) {
            // check if mandatory is enabled on any children
            var anyChildMandatory = false;
            for (var a = 0; a < element.Contents.length; a++) {
                if (element.Contents[a].MandatoryForProgress === true) {
                    anyChildMandatory = true;
                }
            }

            // if mandatory is turned off immediately exit with a positive result
            if (anyChildMandatory === false) {
                return { valid: true, type: "checkNotEnabled" };
            }

            // go through the children to see if any of them are invalid.
            var childMandatory = null;
            for (var b = 0; b < element.Contents.length; b++) {
                childMandatory = formComponentsHelper.checkIfMandatoryForCompletionValid(element.Contents[b]);
                if (childMandatory && childMandatory.valid === false) {
                    // if any child turns out to be false then immediately return it as it means the whole row is invalid
                    return childMandatory;
                }
            }

            // if the code gets to this point then all children are valid so return positive
            return { valid: true, type: "checkEnabledButAllValid" };
        };

        formComponentsHelper.hasElementBeenFilledIn = function (element) {
            // THIS FUNCTION SHOULD ONLY HAVE USER EDITABLE ELEMENTS IN IT
            if (element) {
                switch (element.ElementType) {
                    case "SingleLineTextBox":
                    case "MultiLineTextBox":
                        return formComponentsHelper.hasTextFieldBeenFilledIn(element);
                        break;
                    case "DatePickerInput":
                        return formComponentsHelper.hasDateFieldBeenFilledIn(element);
                        break;
                    case "OptionsList-RadioButtons":
                    case "OptionsList-DropDown":
                    case "CapabilityOptionList-RadioButtons":
                        return formComponentsHelper.hasOptionsListBeenFilledIn(element);
                        break;
                    case "CapabilityBinaryAnswerInput":
                    case "BinaryAnswerInput":
                    case "RangeInput-Likert":
                    case "RangeInput-Standard":
                    case "CapabilityRangeInput-Standard":
                        return formComponentsHelper.hasUserSelectionBeenSelected(element);
                        break;
                    case "OptionsList-Checkboxes":
                        return formComponentsHelper.hasAnyCheckboxBeenChecked(element);
                        break;
                    case "CapabilityEvidenceOnly":
                        return formComponentsHelper.checkForEvidence(element);
                        break;
                    case "FormUserMediaPicker":
                        return formComponentsHelper.hasUserSelectedMedia(element);
                        break;
                    case "GenericTable":
                        return tableDtoHelper.isNotEmpty(element);
                        break;
                    case "UserDetails":
                        return formComponentsHelper.hasUserDetailsBeenSelected(element);
                        break;
                    case "FormSignatureElement":
                        return formComponentsHelper.hasItBeenSigned(element);
                        break;
                    case "FormElementCells":
                        return formComponentsHelper.haveRowsChildrenBeenFilledIn(element);
                        break;
                }
            }

            return true; // if not in the switch statement then it should not be a user editable field
        };

        formComponentsHelper.hasTextFieldBeenFilledIn = function (element) {
            // if the user has written in the answer text return true
            // tslint:disable-next-line:triple-equals
            return element.AnswerText !== undefined && element.AnswerText !== null && !helpers.isEmpty(element.AnswerText);
        };

        formComponentsHelper.hasDateFieldBeenFilledIn = function (element) {
            return (element.StartDateTime !== undefined && element.StartDateTime !== null) || (element.EndDateTime !== undefined && element.EndDateTime !== null);
        };

        formComponentsHelper.hasOptionsListBeenFilledIn = function (element) {
            // if the user has selected a radio button or dropdown then return true
            // tslint:disable-next-line:triple-equals
            return element.UsersAnswer !== undefined && element.UsersAnswer !== null && element.UsersAnswer != "";
        };

        formComponentsHelper.hasUserSelectionBeenSelected = function (element) {
            // if the user has selected a radio button or dropdown then return true
            // tslint:disable-next-line:triple-equals
            return element.UserSelection !== undefined && element.UserSelection !== null && element.UserSelection != "";
        };

        formComponentsHelper.hasAnyCheckboxBeenChecked = function (element) {
            // if the user has select any of the checkbox then straight away return true
            if (element.Options !== null && element.Options !== undefined) {
                for (var i = 0; i < element.Options.length; i++) {
                    // tslint:disable-next-line:triple-equals
                    if (element.Options[i].Checked === "true" || element.Options[i].Checked == true) {
                        return true;
                    }
                }
            }
            return false;
        };

        formComponentsHelper.hasUserSelectedMedia = function (element) {
            return !!element.UserSelectedMediaId; // !! to stop it returning just the actual media id and turn it into a bool
        };

        formComponentsHelper.hasUserDetailsBeenSelected = function (element) {
            return element.Users && element.Users.length > 0;
        };

        formComponentsHelper.hasItBeenSigned = function (element) {
            return !!element.Signature; // !! to stop it returning just the actual media id and turn it into a bool
        };

        formComponentsHelper.haveRowsChildrenBeenFilledIn = function (element) {
            var childrenValid = true;
            for (var a = 0; a < element.Contents.length; a++) {
                // tslint:disable-next-line:triple-equals
                if (formComponentsHelper.hasElementBeenFilledIn(element.Contents[a]) == false) {
                    childrenValid = false;
                }
            }
            return childrenValid;
        };

        formComponentsHelper.shouldElementDisplayGreyedOutAssessorMode = function (obj) {
            if (!obj.assessor_viewing && obj.element.Permissions.AssessorField) {
                return true;
            }

            return false;
        };

        formComponentsHelper.countWords = function (htmlText) {
            var text = helpers.htmlToPlain(htmlText);

            // Count all space/tab/enter
            var s = text ? text.split(/ |\.\S/) : 0;
            return s ? s.length : "";
        };

        formComponentsHelper.formValidation = function (form, workbook_mode, page_title) {
            var _isValid_ = true,
                _errorMessage_ = "";
            for (var i = 0; i < form.Elements.length; i++) {
                var element = form.Elements[i];
                switch (element.ElementType) {
                    case "SingleLineTextBox":
                        if (element.ElementPurpose === multiLanguageService.getString("labels.selector.title")) {
                            if (workbook_mode === true) {
                                if (
                                    element.AnswerText === undefined ||
                                    element.AnswerText === null ||
                                    element.AnswerText === "" ||
                                    element.AnswerText === multiLanguageService.getString("buttons.block_provider.no_title")
                                ) {
                                    element.AnswerText = page_title;
                                }
                            } else {
                                if (element.AnswerText !== undefined && element.AnswerText !== null && element.AnswerText !== "") {
                                    element.isValid = true;
                                } else {
                                    element.isValid = false;
                                    if (_isValid_) {
                                        _errorMessage_ = multiLanguageService.getString("labels.canvas.no_title");
                                    }
                                    _isValid_ = false;
                                }
                            }
                        }
                        break;
                    case "MultiLineTextBox":
                        if (element.AnswerText && element.MaxWords > 0 && formComponentsHelper.wordCountPreventsSave === true) {
                            // get word count
                            var wordCount = this.countWords(element.AnswerText);
                            // compare wordcount to MaxWords setting
                            if (wordCount > element.MaxWords) {
                                element.isValid = false;
                                if (_isValid_) {
                                    _errorMessage_ = multiLanguageService.getString("labels.canvas.too_many_words") + element.MaxWords.toString();
                                }
                                _isValid_ = false;
                            } else {
                                element.isValid = true;
                            }
                        }
                        break;
                    case "FormStaticTextElement-TextSection":
                        break;
                    case "HorizontalRule":
                        break;
                    case "OptionsList-Checkboxes":
                        break;
                    case "OptionsList-RadioButtons":
                        break;
                    case "DatePickerInput":
                        // tslint:disable-next-line:triple-equals
                        if (element.AddEndDate == true && element.StartDateTime != null && element.EndDateTime == null && element.Ongoing == false) {
                            element.isValid = false;
                            if (_isValid_) {
                                _errorMessage_ = multiLanguageService.getString("labels.canvas.no_end_date");
                            }
                            _isValid_ = false;
                            // tslint:disable-next-line:triple-equals
                        } else if (element.AddEndDate == true && element.StartDateTime == null && element.EndDateTime != null && element.Ongoing == false) {
                            element.isValid = false;
                            if (_isValid_) {
                                _errorMessage_ = multiLanguageService.getString("labels.canvas.no_start_date");
                            }
                            _isValid_ = false;
                        } else {
                            element.isValid = true;
                        }
                        break;
                    case "OptionsList-DropDown":
                        break;
                    case "UserDetails":
                        break;
                    case "FormStaticTextElement-MediaAndTextSection":
                        break;
                    case "FormStaticTextElement-CenteredMedia":
                        break;
                    case "FormUserMediaPicker":
                        break;
                    case "CapabilityRangeInput-Standard":
                    case "CapabilityBinaryAnswerInput":
                    case "CapabilityOptionList-RadioButtons":
                        if (element.currentlyJustifying) {
                            element.isValid = false;
                            if (_isValid_) {
                                _errorMessage_ = multiLanguageService.getString("capability_justification.unable_to_save_due_to_justification");
                            }
                            _isValid_ = false;
                        } else {
                            element.isValid = true;
                        }
                        break;
                    case "FormElementCells":
                        break;
                    case "FormSignatureElement":
                        // tslint:disable-next-line:triple-equals
                        const justBeenSigned = $rootScope.justBeenSigned == false;
                        if (justBeenSigned && element.Signature && (element.SignatureBase64 || element.Signature.SignatureId || element.Signature.SignatureBase64) && !form.is_saved) {
                            element.EditedSinceSigned = true;
                            if (element.BlankWhenEdited) {
                                $rootScope.$broadcast("removeDigitalSignature" + element.Id);
                            } else {
                                $rootScope.$broadcast("warningDigitalSignature" + element.Id);
                            }
                        }
                        break;
                    default:
                        break;
                }

                if (!_isValid_) {
                    break;
                }
            }
            return { form: form, valid: _isValid_, message: _errorMessage_ };
        };

        formComponentsHelper.updateDigitalSignaturesState = function (elementDto, viewMode) {
            if (elementDto.Signature && (elementDto.Signature.SignatureId || elementDto.SignatureBase64)) {
                if (!$rootScope.signatureWarnings) {
                    //#future-refactor Digital Signatures in general.
                    $rootScope.signatureWarnings = {
                        warning: 0,
                        blank: 0,
                        disableresign: 0
                    };
                }

                if (!viewMode) {
                    if (elementDto.BlankWhenEdited === true) {
                        //Don't ask me...
                        $rootScope.signatureWarnings.blank++;

                        if (elementDto.DisableResigning) {
                            $rootScope.signatureWarnings.disableresign++;
                        }
                    } else if (elementDto.EditedSinceSigned !== true) {
                        $rootScope.signatureWarnings.warning++;
                    }
                }
            }
        };

        formComponentsHelper.validateComponent = function (componentScope) {
            if (componentScope.hasOwnProperty("checkIfValid")) {
                return componentScope.checkIfValid();
                //FIXME: Keep hacky old API in working order. Replace once all components have been upgraded
            } else if (componentScope.element.required) {
                return componentScope.element.required.valid ? true : componentScope.element.required.errorMessage;
            }
        };

        formComponentsHelper.validateActiveElements = function (componentScopes, resetState) {
            var componentScope = null,
                validationResponse = "",
                errorMessage = "";

            if (resetState) {
                this.hasInvalidDigitalSignature = false; //Reset state before checking validation
            }

            //Run through all componentScope and call checkIfValid to get components to check validation and display any issues.
            for (var i = 0, l = componentScopes.length; i < l; i++) {
                componentScope = componentScopes[i];

                if (componentScope.element.ElementType === "FormSignatureElement") {
                    //Funky state updates for Digital Signatures.
                    // tslint:disable-next-line:triple-equals
                    if ($rootScope.verifyBeforeSaving == true) {
                        $rootScope.verifyBeforeSaving = false;

                        validationResponse = this.validateComponent(componentScope);
                        if (validationResponse !== true && errorMessage === "") {
                            errorMessage = validationResponse;
                        }
                        $rootScope.verifyBeforeSaving = true;
                    }

                    this.updateDigitalSignaturesState(componentScope.element, componentScope.view_mode);

                    if (componentScope.disableDefaultInvalidModal && !this.hasInvalidDigitalSignature) {
                        this.hasInvalidDigitalSignature = componentScope.disableDefaultInvalidModal;
                    }
                } else {
                    validationResponse = this.validateComponent(componentScope);
                    if (validationResponse !== true && errorMessage === "") {
                        errorMessage = validationResponse;
                    }
                }
            }

            return {
                allValid: errorMessage === "",
                message: errorMessage
            };
        };

        /**
         * @param {Object} elementDto
         * @param {Boolean} hasWritePermission
         * @param {Boolean} isAssessor
         * @param {Boolean} viewMode
         * @param {string} formResponseId
         * @param {string} observableId
         * @param {string|null} pageId
         * @param {string} templateId
         * @param {string} workbookId
         * @param {Boolean} inFeedbackTemplateMode
         * @param {string} timeslice
         * @returns {Object}
         */
        formComponentsHelper.createElementInfoObject = function (
            elementDto,
            hasWritePermission,
            isAssessor,
            viewMode,
            formResponseId,
            observableId,
            pageId,
            templateId,
            workbookId,
            inFeedbackTemplateMode,
            timeslice,
            anchorInfo
        ) {
            return {
                element: elementDto || null,
                view_mode: viewMode,
                user_is_an_owner: hasWritePermission,
                assessor_viewing: isAssessor,
                formResponseId: formResponseId,
                observableId: observableId,
                pageId: pageId,
                workbookId: workbookId,
                templateId: templateId,
                inFeedbackTemplateMode: inFeedbackTemplateMode,
                timeslice: timeslice || null,
                anchorInfo: anchorInfo !== undefined ? anchorInfo : null
            };
        };

        formComponentsHelper.setCustomDtoValues = function (dto) {
            dto.isValid = dto.isValid !== undefined ? dto.isValid : true;

            dto.required = {
                valid: dto.isValid,
                type: ""
            };

            dto.mandatoryField = {
                valid: !dto.MandatoryForProgress,
                type: "",
                displayError: false
            };

            return dto;
        };

        return formComponentsHelper;
    }
]);
