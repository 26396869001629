import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { ACTION_CONSTANTS } from "../../../constants/action.constants";
import "../../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import "./scorecardView/scorecardView.component";
import "./scorecardEdit/scorecardEdit.component";
import template from "./scorecard.html";

class ScorecardComponent extends NgComponent {
    constructor(feedbackHelper) {
        super();

        this.ACTION_CONSTANTS = ACTION_CONSTANTS;
        this._feedbackHelper = feedbackHelper;

        this._saveData = null;
        this.onUpdateFeedback = this._updateFeedback.bind(this);
    }

    $onChanges(changes) {
        if (changes.mode !== undefined && changes.mode.currentValue === ACTION_CONSTANTS.EDIT) {
            this._saveData = {
                Scores: [...this.feedback.Scores],
                TotalScore: this.feedback.TotalScore,
                Grade: this.feedback.Grade
            };
        }
    }
    _updateFeedback(changes) {
        Object.assign(this._saveData, changes);
        this._validation = this._feedbackHelper.getScorecardValidation(this._saveData);

        if (this.onEdit !== undefined) {
            this.onEdit({
                validation: this._validation,
                changes: { Scores: this._saveData.Scores, TotalScore: this._saveData.TotalScore, Grade: this._saveData.Grade }
            });
        }
    }
}

var scorecardDefinition = {
    bindings: {
        feedback: "<",
        onEdit: "&?",
        mode: "<"
    },
    template: template,
    controller: ScorecardComponent
};

ScorecardComponent.$inject = ["feedbackHelper"];
angularAMD.component("scorecard", scorecardDefinition);
export { scorecardDefinition as scorecard };
