import { ISettingListItem } from "../../interfaces/settingItems/ISettingListItem";
import { ISettingResponseItem } from "../../interfaces/settingItems/ISettingResponseItem";

export const fromListOptions = (setting: ISettingResponseItem): ISettingListItem => {
    return {
        options: setting.options ?? [],
        type: "List",
        value: setting.value
    };
};
