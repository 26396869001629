import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import "../../../../react2angular/StaticLabel";
import "../../../../multiLanguageService/multiLanguageService";
import template from "./scorecard-view.html";

class ScorecardViewComponent extends NgComponent {
    constructor(multiLanguageService) {
        super();

        this.multiLanguageService = multiLanguageService;
        this.totalScoreForView = "--";
        this.labelDataHook = "scorecard-type-label";
    }

    $onInit() {
        if (this.feedback.TotalScore !== null) {
            this.totalScoreForView = this.feedback.Weighted === true ? this._formatScore(this.feedback.TotalScore) : this.feedback.TotalScore;
        }
    }

    _formatScore(score) {
        return Number(score / 100).toLocaleString(undefined, { style: "percent" });
    }
}

var scorecardViewDefinition = {
    bindings: {
        feedback: "<"
    },
    template: template,
    controller: ScorecardViewComponent
};

ScorecardViewComponent.$inject = ["multiLanguageService"];
angularAMD.component("scorecardView", scorecardViewDefinition);
export { scorecardViewDefinition as scorecardView };
