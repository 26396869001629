import { NgComponent } from "@pebblepad/angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import Mark from "mark.js/dist/mark.min";
import { ACTION_CONSTANTS } from "../constants/action.constants";
import { ASSESSMENT_CONSTANTS } from "../constants/assessment.constants";
import { FEEDBACK_ERRORS } from "../constants/feedbackErrors.constants";
import "../utilities/scrollToItem.directive";
import "../utilities/highlightItem.directive";
import "../utilities/pebbleDate";
import "../feedback/feedbackWrapper/feedbackWrapper.component";
import "../feedback/feedbackGroupService/feedbackGroupService.service";
import "../feedback/feedbackDataService/feedbackDataService.service";
import "../multiLanguageService/multiLanguageService";
import "../feedbackSearch/feedbackSearch.component";
import "../loadingAnnouncer/loadingAnnouncer.directive";
import "../loadingSpinner/loadingSpinner";
import "../messageAnnouncer/messageAnnouncer.directive";
import "../feedback/feedbackFilterer/feedbackFiltererCollection.service";
import "../feedback/feedbackSearchService/feedbackSearchService.service";
import "../stickyElementCoordinator/stickyItem.directive";
import template from "./feedback-list.html";

class FeedbackListComponent extends NgComponent {
    constructor($filter, $timeout, $window, multiLanguageService, FeedbackGroupService, feedbackDataService, feedbackFiltererCollection, feedbackSearchService) {
        super();

        this._$filter = $filter;
        this._$timeout = $timeout;
        this._$window = $window;
        this._feedbackDataService = feedbackDataService;
        this._feedbackGroupService = FeedbackGroupService;
        this._feedbackSearchService = feedbackSearchService;
        this._feedbackFiltererCollection = feedbackFiltererCollection;
        this.multiLanguageService = multiLanguageService;

        this.loadingFeedback = false;
        this.feedbackExists = false;
        this.showSearchHelp = false;
        this.searchDisabled = false;
        this.errorMessage = "";
        this.loadedMessage = "";
        this.feedbackGroups = [];
        this.sortAscending = false;
        this.scrollToOptions = null;
        this.loadSize = 0;
        this.filteredFeedback = [];
        this.filterForElement = false;
        this.filterForElementText = "";

        this._defaultPageSize = 20;
        this._searchTimeout = null;
        this._observable = undefined;

        this._feedbackListMark = new Mark("feedback-wrapper [data-markable]");
        this._feedbackWrapperMark = null;
        this._searchText = "";
        this._focusAnchorId = null;

        this._filterer = this._feedbackFiltererCollection.get(this.assetId);
        this._unsubscribe = this._filterer.subscribe(this._filterFeedback.bind(this));
        this._feedbackObserver = this.onUpdateList.bind(this);
    }

    $onInit() {
        this._loadFeedback();
        this._setFilterForElementText();
        this.scrollToOptions = {
            block: "end",
            behavior: "smooth"
        };
    }

    $onDestroy() {
        this._$timeout.cancel(this._searchTimeout);
        this._unsubscribe();
        this._feedbackDataService.unsubscribe(this.assetId, this._feedbackObserver);
        this._feedbackFiltererCollection.remove(this.assetId);
    }

    loadMoreItems() {
        let previousLoadSize = this.loadSize;
        this.loadSize = previousLoadSize + this._defaultPageSize;
        this.buildFeedbackGroups();

        const feedbackLength = this.filteredFeedback.length;
        let loadedLength = this.loadSize;
        if (loadedLength >= feedbackLength) {
            loadedLength = feedbackLength;
        }

        this.loadedMessage = this.multiLanguageService.getString("sidebar.asset_feedback.aria.feedback_loaded_out_of", {
            loadedAmount: loadedLength,
            count: feedbackLength
        });

        this._$window.requestAnimationFrame(() => {
            document.getElementById(`focus-${this.filteredFeedback[previousLoadSize].Id}`).focus();
        });

        this._markText(this._feedbackListMark);
    }

    onSearch(searchText) {
        this._searchText = searchText;
        this._filterFeedback();
    }

    onUpdateList(changes) {
        this._feedbackDataService.updateMostRecentFlagsForBlockFeedback(this.assetId);

        switch (changes.action) {
            case ACTION_CONSTANTS.ADD: {
                const filteredItems = this._filterer.filter(changes.items);
                if (filteredItems.length > 0) {
                    for (const feedbackItem of filteredItems) {
                        this._feedbackGroupService.addFeedbackItem(feedbackItem, this.feedbackGroups, this.sortAscending);
                    }
                    this.filteredFeedback.push(...filteredItems);
                    this.loadSize += filteredItems.length;
                }
                this.feedbackExists = true;

                if (filteredItems.length === 1) {
                    this._$timeout(() => {
                        this.highlightFeedbackId = `id-${filteredItems[0].Id}`;
                        this.scrollToFeedbackId = `id-${filteredItems[0].Id}`;
                    });
                }

                if (this.feedbackGroups.length < 1) {
                    this._handleError(FEEDBACK_ERRORS.NO_SEARCH_FEEDBACK_ERROR);
                }
                break;
            }
            case ACTION_CONSTANTS.DELETE: {
                if (this._filterer.filter([changes.item]).length > 0) {
                    this._feedbackGroupService.deleteFeedbackItem(changes.item, this.feedbackGroups);
                    const feedbackItemIndex = this.filteredFeedback.findIndex((feedbackItem) => feedbackItem.Id === changes.item.Id);
                    if (feedbackItemIndex !== -1) {
                        this.filteredFeedback.splice(feedbackItemIndex, 1);
                    }
                    this.loadSize--;

                    if (this._observable.listArray.length > 0 && changes.item.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD) {
                        this._filterFeedback();
                    }
                }

                if (this._observable.listArray.length < 1) {
                    this.feedbackExists = false;
                    this._handleError(FEEDBACK_ERRORS.NO_FEEDBACK_ERROR);
                } else if (this.feedbackGroups.length < 1) {
                    this.feedbackExists = true;
                    this._handleError(FEEDBACK_ERRORS.NO_SEARCH_FEEDBACK_ERROR);
                }
                break;
            }
            case ACTION_CONSTANTS.EDIT: {
                this._feedbackGroupService.replaceFeedbackItem(this.feedbackGroups, changes.oldItem, changes.newItem);

                const feedbackItemIndex = this.filteredFeedback.findIndex((feedbackItem) => feedbackItem.Id === changes.newItem.Id);
                if (feedbackItemIndex !== -1) {
                    this.filteredFeedback[feedbackItemIndex] = changes.newItem;
                }

                this._$timeout(() => {
                    this.highlightFeedbackId = `id-${changes.newItem.Id}`;

                    if (changes.newItem.FeedbackTemplates.length !== 0) {
                        this.scrollToOptions.behavior = "smooth";

                        this.scrollToFeedbackId = `id-${changes.newItem.Id}`;
                    }
                });

                if ((changes.newItem.Pending === undefined || changes.newItem.Pending === false) && this._searchText !== "") {
                    this._feedbackWrapperMark = new Mark(`#id-${changes.newItem.Id} [data-markable]`);
                    this._markText(this._feedbackWrapperMark);
                }
                break;
            }
        }
    }

    onRepliesChange(id) {
        this._feedbackWrapperMark = new Mark(`#id-${id} [data-markable]`);
        this._markText(this._feedbackWrapperMark);
    }

    buildFeedbackGroups() {
        this.feedbackGroups = this._feedbackGroupService.buildFeedbackGroups(this.filteredFeedback, this.sortAscending, this.loadSize);
    }

    getAriaLabel(feedbackItem, feedbackIndex, groupSize) {
        const ariaProps = {
            number: feedbackIndex + 1,
            total: groupSize,
            posterName: feedbackItem.Poster.Name,
            workspace: feedbackItem.OriginName,
            assignment: feedbackItem.SubOriginName,
            assetTitle: feedbackItem.AssetTitle,
            date: this._$filter("pebbleDate")(feedbackItem.Posted),
            time: this._$filter("pebbleDate")(feedbackItem.Posted, "timeOnly"),
            count: feedbackItem.Replies.length
        };

        return this.multiLanguageService.getString("sidebar.asset_feedback.aria.feedback_section_aria", ariaProps);
    }

    getLoadingLabel(feedbackItem) {
        const heldString = this.multiLanguageService.getString("sidebar.asset_feedback.held");
        const releasedString = this.multiLanguageService.getString("sidebar.asset_feedback.released");
        const action = feedbackItem.Released ? releasedString : heldString;

        return this.multiLanguageService.getString("sidebar.asset_feedback.aria.action_complete", { action: action });
    }

    updateScrollOptions() {
        this.scrollToFeedbackId = "";
        this.scrollToOptions.behavior = "smooth";
    }

    sort(ascending) {
        this.sortAscending = ascending;
        this.buildFeedbackGroups();

        if (this.filteredFeedback.length > this.loadSize) {
            this._markText(this._feedbackListMark);
        }
    }

    toggleSearch(disabled) {
        this.searchDisabled = disabled;
    }

    getGroupValueAria(groupValue) {
        return this.multiLanguageService.getString("sidebar.asset_feedback.aria.feedback_grouping", {
            group: this.getParsedGroupValue(groupValue)
        });
    }

    getParsedGroupValue(groupValue) {
        return this._$filter("pebbleDate")(new Date(groupValue), "monthAndYear");
    }

    _filterFeedback() {
        if (this._observable === undefined) {
            this._observable = this._feedbackDataService.getObservable(this.assetId);
            this._feedbackDataService.subscribe(this.assetId, this._feedbackObserver);
        }

        this._resetInitialLoadState();
        this.loadedMessage = "";

        this._searchTimeout = this._$timeout(() => {
            let feedback = [...this._observable.listArray];
            feedback = this._filterer.filter(feedback);
            if (this._searchText !== "") {
                feedback = this._feedbackSearchService.searchFeedback(this._searchText, feedback);
            }
            this.filteredFeedback = feedback;
            this.buildFeedbackGroups();

            this._setFilterForElementText();
            this._setFeedbackState(this.filteredFeedback.length);
            this._setInitialLoadState();
            this._markText(this._feedbackListMark);

            this._focusFeedback();
        });
    }

    _focusFeedback() {
        const activeFilter = this._filterer.getActiveFilter();
        let newFocusAnchorId = null;

        if (activeFilter.forElement !== undefined && activeFilter.forElement.anchorId !== undefined) {
            newFocusAnchorId = activeFilter.forElement.anchorId;
        } else {
            this._focusAnchorId = null;
            return;
        }

        if (this._focusAnchorId !== newFocusAnchorId) {
            const filteredFeedback = this.filteredFeedback[0];

            if (filteredFeedback !== undefined) {
                requestAnimationFrame(() => {
                    const feedbackElement = document.getElementById(`focus-${filteredFeedback.Id}`);
                    if (feedbackElement) {
                        feedbackElement.focus();
                    }
                });
            }

            this._focusAnchorId = newFocusAnchorId;
        }
    }

    _loadFeedback() {
        this.feedbackExists = false;
        this._resetInitialLoadState();

        this._observable = this._feedbackDataService.getObservable(this.assetId);

        if (this._observable !== undefined) {
            this._feedbackDataService.subscribe(this.assetId, this._feedbackObserver);
            this.sortAscending = false;

            this._setupFeedbackList();
        } else {
            this._handleError();
            this._setInitialLoadState();
        }
    }

    _handleError(error) {
        switch (error) {
            case FEEDBACK_ERRORS.NO_SEARCH_FEEDBACK_ERROR: {
                this.errorMessage = this.multiLanguageService.getString("search_bar.no_search_results.message");
                this.showSearchHelp = true;
                break;
            }
            case FEEDBACK_ERRORS.NO_FEEDBACK_ERROR: {
                if (this.anchorId !== undefined) {
                    this.errorMessage = this.multiLanguageService.getString("sidebar.asset_feedback.messages.no_feedback_block");
                } else {
                    this.errorMessage = this.multiLanguageService.getString("sidebar.asset_feedback.messages.no_feedback");
                }
                this.showSearchHelp = false;
                break;
            }
            default: {
                this.errorMessage = this.multiLanguageService.getString("sidebar.asset_feedback.messages.cant_load_feedback");
            }
        }

        this.loadedMessage = this.errorMessage;
    }

    _setupFeedbackList() {
        this.filteredFeedback = this._filterer.filter([...this._observable.listArray]);
        this.buildFeedbackGroups();
        this._setFeedbackState(this.filteredFeedback.length);
        this._setInitialLoadState();
    }

    _setFeedbackState(feedbackLength) {
        if (this._observable.listArray.length < 1) {
            this._handleError(FEEDBACK_ERRORS.NO_FEEDBACK_ERROR);
        } else if (this.feedbackGroups.length < 1) {
            this.feedbackExists = true;
            this._handleError(FEEDBACK_ERRORS.NO_SEARCH_FEEDBACK_ERROR);
        } else {
            if (feedbackLength > this.loadSize) {
                this.loadedMessage = this.multiLanguageService.getString("sidebar.asset_feedback.aria.feedback_loaded_partial", {
                    count: feedbackLength,
                    loadedAmount: this.loadSize
                });
            } else {
                this.loadedMessage = this.multiLanguageService.getString("sidebar.asset_feedback.aria.feedback_loaded", {
                    count: feedbackLength
                });
            }

            this.feedbackExists = true;
        }
    }

    _setInitialLoadState() {
        this.loadingFeedback = false;
    }

    _resetInitialLoadState() {
        this._feedbackListMark.unmark();
        this._feedbackWrapperMark = null;
        this.loadSize = this._defaultPageSize;
        this.loadingFeedback = true;
        this.showSearchHelp = false;
    }

    _setFilterForElementText() {
        const activeFilter = this._filterer.getActiveFilter();
        this.filterForElement = activeFilter.forElement !== undefined;
        this.filterForElementText = this.filterForElement ? activeFilter.forElement.label : "";
    }

    _markText(markElement) {
        if (markElement !== null && this._searchText !== "") {
            this._$window.requestAnimationFrame(() => {
                markElement.mark(this._searchText, {
                    acrossElements: true,
                    ignoreJoiners: true,
                    separateWordSearch: false
                });
            });
        }
    }
}

const feedbackListDefinition = {
    bindings: {
        assetId: "<",
        anchorId: "<?",
        isBlockFeedbackListView: "<?"
    },
    template: template,
    controller: FeedbackListComponent
};

FeedbackListComponent.$inject = ["$filter", "$timeout", "$window", "multiLanguageService", "FeedbackGroupService", "feedbackDataService", "feedbackFiltererCollection", "feedbackSearchService"];
angularAMD.component("feedbackList", feedbackListDefinition);
export { feedbackListDefinition as feedbackList };
