import { concatMap, filter, Observable, startWith, switchMap } from "@pjs/observables";
import { ITrackedModelDocument } from "@pjs/storage";
import { Immutable } from "@pjs/utilities";
import { getAccountSettings } from "./functions/GetAccountSettings.function";
import { accountSettingsStore } from "./stores/AccountSettingsStore.const";
import { IAccountSettingsModel } from "./interfaces/IAccountSettingsModel";
import { ISettings } from "./interfaces/ISettings";
import { IState } from "./interfaces/IState";

export class AccountSettings {
    public static getSettings(): Observable<ISettings> {
        try {
            const document = accountSettingsStore.get();
            return AccountSettings._setUpSettingsObservable(document).pipe(filter((settings): settings is ISettings => settings !== null));
        } catch {
            return getAccountSettings().pipe(
                concatMap((response) => AccountSettings._setUpStore(response)),
                switchMap((document) => AccountSettings._setUpSettingsObservable(document)),
                filter((settings): settings is ISettings => settings !== null)
            );
        }
    }

    public static getState(): Observable<IState> {
        return accountSettingsStore.observeSettings().pipe(switchMap((document) => document.latestModel.state.pipe()));
    }

    public static clear(): void {
        accountSettingsStore.clear();
    }

    private static _setUpStore(data: IAccountSettingsModel): Observable<ITrackedModelDocument<IAccountSettingsModel>> {
        accountSettingsStore.set(data);
        return accountSettingsStore.observeSettings();
    }

    private static _setUpSettingsObservable(document: ITrackedModelDocument<IAccountSettingsModel>): Observable<Immutable<ISettings> | null> {
        return document.latestModel.settings.pipe(startWith(document.model.settings.get()));
    }
}
